.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 0;
  margin: 0;
  background-color: #ffffff;
}

@media (min-width: 768px) {
  .container {
    max-width: 350px;
    left: 50%;
    transform: translateX(-50%);
  }
}

/* Center content properly */
.centerContent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between; /* Ensures even spacing */
  text-align: center;
  position: relative;
  padding: 5vh 5vw; /* Adds spacing */
}

@media (min-width: 400px), (max-width: 768px){
  .centerContent {
    padding: 0px;
  }
}

/* Title - Fixed so it stays visible above the image */
.title {
  /* color: var(--Dark-blue, #10123F); */
  font-family: "GT Walsheim Pro";
  font-size: 30px;
  font-style: normal;
  /* font-weight: 400; */
  line-height: 34px;
  top: 80px;
  width: 323px;
  height: 136px;
  z-index: 3; /* Keeps title on top */
  margin-top: 8vh; /* Ensures spacing from the image */
  margin-bottom: 2vh; /* Added margin-bottom */
}

.title span span {
  color: #5D5CFE !important;
  font-weight: bold !important;
}

/* Image container - Full width, no bottom space */
.imageContainer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  right: 80px;
  bottom: -10px;
  min-width: 80%;
}

@media (min-width: 400px), (max-width: 768px){
  .imageContainer {
    margin-left: 0;
    margin-right: 0;
    
  }
}


/* Image - No bottom gap, full width */
.image {
  width: 170%;
}

@media (min-width: 600px) {
  .image {
    margin-top: 20px;
  }
}

/* Button - Stays correctly positioned */
.whiteButton {
  font-size: 16px;
  font-family: "GT Walsheim Pro";
  color: #4350E6;
  background-color: #fff;
  border: none;
  padding: 12px 24px;
  border-radius: 24px;
  cursor: pointer;
  width: 80%;
  max-width: 260px;
  text-transform: capitalize;
  position: absolute;
  bottom: 8vh; /* Adjusted to fit screen */
  left: 50%;
  transform: translateX(-50%);
}

/* Adjustments for larger screens */
@media (min-width: 768px) {
  .title {
    font-size: clamp(0.6rem, 1.5vw, 2rem);
    margin-bottom: 3vh; /* Increased margin-bottom */
  }
}

@media (max-width: 1023px) {
  .title {
    margin-bottom: 4vh; /* Added media query for smaller viewports */
  }
}

@media (min-width: 1024px) {
  .whiteButton {
    font-size: 18px;
    width: 280px;
    bottom: 10vh;
  }
}