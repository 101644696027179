.pageContainer {
  width: 100%; /* Fixed width for the design */
  min-height: 130vh; /* Fixed height for the design */
  background: var(--Purple-gradient, linear-gradient(99deg, #4450E5 22.9%, #616CEE 77.43%));
  display: flex; /* Enable Flexbox */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto; /* Horizontally center within the viewport */
  position: relative; /* Allow absolute elements like popups to position relative to this */
  padding-top: 80px;
}

.topSection {
  text-align: center;
  color: #fff;
  margin-top: -50px;
}

.stepNumber {
  font-family: "GT Walsheim Pro";
  font-style: normal;
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 20px;
  color: #fff;
}

.pageTitle {
  color: #FFF;
  text-align: center;
  font-family: "GT Walsheim Pro";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px; /* 138.889% */
}
  
.title {
  color: #484848;
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
  margin-bottom: 16px;
}

.whiteCard {
  width: 350px;
  height: 600px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

@media (max-width: 380px) { 
  .whiteCard {
      width: 280px;
  }
}

.optionContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
}

.bottomSpace {
  margin-bottom: 6vh;
}

/* ✅ Clickable Choices */
.optionRow {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensures spacing */
  padding: 10px;
  width: 100%;
  min-height: 66px;
  max-height: 82px;
  margin: 0 auto 12px auto;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #505050;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

/* ✅ Choice Styling */
.choice {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #f8f8f8;
  border-radius: 10px;
  border: 1px solid #DBDBDB;
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
  top: 20px;
  color: #505050;
  text-align: left;
  flex-grow: 1; /* Takes up remaining space */
  padding: 10px 12px;
  height: 66px;
  min-width: 250px;
  width: calc(100% - 40px); /* Leaves space for icon */
}

@media (max-width: 400px) {
  .choice {
      min-width: 200px;
      min-height: 90px;
      max-height: 100px;
      margin-top: 5px;
      /* min-height: 90px; */
      /* max-height: 100px; */
  }
}

.choiceSelected {
  border: 2px solid var(--red-orange-gradient, #e12541);
  background: #f8f8f8;
  font-weight: 600;
  color: #303030;
}

/* ✅ Info Text (4th Option - Not Clickable) */
.infoText {
  color: #494949;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  padding: 10px;
  text-align: left;
  width: 100%;
  border-radius: 8px;
  margin-top: 8vh;
}

/* ✅ Continue Button is Always Active */
.continueButton {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  background: #4350E6;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  margin-top: 40px;
}

.icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

@media (max-width: 380px) { 
  .icon {
      right: 15px;
  }
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  margin-left: 12px;
}