.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup {
    width: 350px;
    height: 464px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #fff;
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    text-align: center;
  }
  
  .icon {
    width: 50px;
    height: 50px;
    margin-bottom: 20px;
  }
  
  .title {
    color: #484848;
    text-align: center;
    font-family: "GT Walsheim Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px; /* 143.75% */
    width: 269px;
    margin-top: -20px;
  }
  
  .description {
    color: #505050;
    text-align: center;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px; /* 146.154% */
    width: 281px;
  }

  .image {
    width: 138px;
    height: 136px;
    flex-shrink: 0;
  }
  
  .button {
    width: 320px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 20px;
    background: #4350E6;
    color: #FFF;
    text-align: center;
    font-family: "GT Walsheim Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px; /* 156.25% */
    margin-top: 20px;
  }
  
  .button:hover {
    background: #3030c4;
  }