.chatContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  padding-top: 60px; /* Space for header */
  align-items: center;
}

.homePageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 390px; /* Keeps all items centered and within a fixed width */
  margin: 0 auto; /* Centers the container */
  padding: 16px !important; /* Adds padding to the left and right */
  box-sizing: border-box; /* Ensures padding does not increase the width */
}

.extraSpace {
  margin-top: 40px;
}

.extraSpace2 {
  margin-top: 25px;
}

#crisp-chat-box {
  height: 60vh; /* Prevent full-page takeover */
}

.header {
  background-color: #4450E5; /* Example header background color */
  color: white;
  padding: 15px;
  text-align: center;
}

.chatContainer {
  flex-grow: 1; /* Allows the chat to expand while keeping header and footer fixed */
  overflow: hidden;
}

.bottomNavbar {
  background-color: #F8F8F8; /* Example navbar background */
  padding: 10px;
  text-align: center;
}

@media (max-width: 768px) {
  .crisp-client {
    position: fixed !important;
    bottom: 60px !important; /* Adjust this based on the navbar height */
    right: 0;
    left: 0;
    width: 100%;
    height: 60vh !important; /* Adjust this to make space for the header */
    max-height: 500px; /* Set a max height */
  }
}

.bottomSpace {
  margin-bottom: 8vh;
}

.buttonContainer {
  margin-top: 25vh;
}

.button1 {
  top: 40vh;
  max-width: 350px;
  min-width: 280px;
  height: 40px;
  background: #4350E6;
  color: #fff;
  border-radius: 20px;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  border: none;
  margin-bottom: 10px;
  cursor: pointer;
}

.button2 {
  top: 30vh;
  max-width: 350px;
  min-width: 280px;
  height: 40px;
  background: transparent;
  color: #4350e6;
  border: 2px solid #4350e6;
  border-radius: 20px;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
}

.subTitle {
  top: 20vh;
  width: 319px;
  height: 39px;
  flex-shrink: 0;
  color: #505050;
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px; /* 146.154% */
}