.carouselSmallCard {
  padding: 10px;
  width: 133px;
  height: 92px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  flex-shrink: 0;
}

/* Ensure text and icon always align at the bottom */
.rowLayout {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 15px; */ /* Push to the bottom */
}

/* Title styling */
.cardTitle {
  font-family: "GT Walsheim Pro", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: #333;
  margin-bottom: 8px;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Extra text */
.extraText {
  font-family: "GT Walsheim Pro", sans-serif;
  font-size: 11px;
  font-weight: 400;
  line-height: 15px;
  color: #303030;
  flex-grow: 1; /* Ensures equal spacing */
}

/* Icon container, ensuring consistent placement */
.cardIconWrapper {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}