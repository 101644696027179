.iconTextElement {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align text next to the icon */
  width: 100%; /* Full width for consistency */
  max-width: 400px;
  margin-bottom: 10px; /* Adjust spacing between elements */
}

.icon {
  width: 30px; /* Consistent size for better visibility */
  height: 21px; 
  margin-right: 8px; /* Space between icon and text */
  flex-shrink: 0;
}

.text {
  font-size: 16px;
  color: #10123F;
  text-align: left;
  line-height: 1.4; /* Line height adjustment for readability */
}
