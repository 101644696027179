.homePageContainerFAQ {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 390px; /* Keeps all items centered and within a fixed width */
  margin: 0 auto; /* Centers the container */
  padding: 16px; /* Adds padding to the left and right */
  box-sizing: border-box; /* Ensures padding does not increase the width */
}


.bannerCardWrapper {
    display: flex;
    justify-content: center;
    width: 100%; /* Let the card fill the entire width */
    margin-bottom: 20px; /* Optional: add space below the card */
  }

  .carouselContainer {
    display: flex;
    gap: 10px;
    overflow-x: auto;
    padding: 10px 0;
    left: 0;
    scroll-snap-type: x mandatory;
    max-width: 390px; /* Restrict width to maintain visibility */
    margin: 0 auto; /* Centers the carousel container */
    box-sizing: border-box;
  }
  
  .carouselContainer::-webkit-scrollbar {
    display: none; /* Hides the scrollbar for a cleaner look */
  }
  
  .carouselCard {
    flex: 0 0 calc((390px / 2.5) - 10px); /* Adjust width to show 2.5 cards */
    scroll-snap-align: start; /* Aligns each card to the start */
  }
  
  .libraryAbortionMenuContainer {
    display: flex;
    flex-direction: column; /* Keeps items stacked vertically */
    align-items: center; /* Ensures all child elements are centered */
    width: 100%;
    max-width: 390px; /* Optional: Keeps items at a specific width for all screen sizes */
    margin: 0 auto;
    padding: 5px;
    box-sizing: border-box;
    margin-top: 17px;
  }
  
  .contentWrapper {
    width: 100%;
    max-width: 390px; /* Keeps the maximum width consistent for all items */
    margin: 0 auto;
    padding: 0 16px; /* Adjust the padding to control the left/right margins */
    box-sizing: border-box; /* Ensures padding does not increase the width */
  }  
  
  .carouselContainer {
    display: flex;
    gap: 10px;
    overflow-x: auto;
    padding: 10px 0;
    scroll-snap-type: x mandatory;
    max-width: 390px; /* Restrict width to maintain 2.5 cards visibility */
    margin: 0 0;
  }
  
  @media (min-width: 768px) {
    .carouselContainer {
      max-width: 390px; /* Keep the carousel constrained to 390px width for desktop too */
    }
  }
  
  .carouselContainer {
    display: flex;
    gap: 10px;
    overflow-x: auto;
    padding: 10px 0;
    scroll-snap-type: x mandatory;
    max-width: 390px; /* Restrict width to maintain visibility */
    margin: 0 auto; /* Centers the carousel container */
    box-sizing: border-box;
  }
  
  .carouselContainer::-webkit-scrollbar {
    display: none; /* Hides the scrollbar for a cleaner look */
  }
  
  .carouselCard {
    flex: 0 0 calc((390px / 2.5) - 10px); /* Adjust width to show 2.5 cards */
    scroll-snap-align: start; /* Aligns each card to the start */
  }
  
    
  
  
  .exploraContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    max-width: 390px;
  }
  
  .sectionTitleExplora {
    color: #333;
    font-family: "GT Walsheim Pro";
    font-size: 30px;
    font-weight: 400;
    line-height: 24px;
  }
  
  .scrollInfo {
    color: #666;
    font-family: "GT Walsheim Pro";
    font-size: 14px;
    font-weight: 400;
  }
  
  .headerContainer {
    margin-bottom: 20px; /* Adjust this value to increase/decrease the gap */
  }
  
  .bannerCardWrapper {
    display: flex;
    justify-content: center;
    width: 100%; /* Let the card fill the entire width */
    margin-bottom: 20px; /* Optional: add space below the card */
  }

  .extraTitle {
    max-width: 300px;
    min-width: 280px;
    height: 34px;
    flex-shrink: 0;
    color: #989898;
    text-align: center;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 140% */
    margin: 10px;
  }


  .sourceText {
    width: 300px;
    height: 34px;
    flex-shrink: 0;
    color: #989898;
    text-align: center;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 140% */
    margin-top: 14px;
    margin-bottom: 17px;
  }

  .smallLabel {
    width: 63.411px;
    height: 16px;
    flex-shrink: 0;
    color: #FFF;
    text-align: center;
    font-family: "GT Walsheim Pro";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 169.231% */
  }

.extraTitletwo {
  width: 312px;
  height: 36px;
  top: 10px;
  margin-top: 10px;
  flex-shrink: 0;
  color: #484848;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 122.222% */
}

.leftAlignWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Ensures all child elements are left-aligned */
  width: 100%; /* Allows the wrapper to take up the full width of its container */
  max-width: 390px; /* Optional: Keeps the maximum width consistent for items */
  margin: 0 auto; /* Center the wrapper itself if needed */
  padding: 0; /* Adjusts the left/right padding */
  box-sizing: border-box; /* Ensures padding doesn't affect width calculations */
}

/* RealStories.module.css */
.realStoriesContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);  /* 2 cards per row */
  gap: 16px;  /* Spacing between cards */
  padding: 16px;
  justify-items: center; /* Centers the cards */
}

/* Adjust this class if needed for small screens */
@media (max-width: 600px) {
  .realStoriesContainer {
    grid-template-columns: 1fr; /* One card per row on smaller screens */
  }
}


.moreSpace {
 margin-top: 10px;
 margin-bottom: 10px;
}


.accordionWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 16px; */
}

.accordionWrapper .MuiAccordion-root:first-of-type {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.accordionWrapper .MuiAccordion-root:last-of-type {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.MuiAccordionSummary-root {
  padding: 0 !important; /* Remove MUI default padding */
}

.MuiAccordionSummary-content {
  margin: 0 !important; /* Override margin */
}

.MuiAccordionSummary-expandIconWrapper {
  margin-left: auto; /* Align arrow to the right */
  transform: rotate(0deg); /* Ensure correct rotation for closed state */
}

.MuiAccordionSummary-expandIconWrapper.Mui-expanded {
  transform: rotate(180deg); /* Rotate arrow when expanded */
}

.MuiAccordionDetails-root {
  padding: 13px 16px; /* Match the specified padding */
  background-color: #FFF !important; /* Fix unwanted grey background */
}

.accordionWrapper {
  padding-top: 16px; /* Adds spacing above the first accordion */
  padding-bottom: 16px; /* Adds spacing below the last accordion */
}

.accordionWrapper .MuiAccordion-root:first-of-type {
  border-top-left-radius: 10px !important; /* Match the desired radius */
  border-top-right-radius: 10px !important;
}

.accordionWrapper .MuiAccordion-root:last-of-type {
  border-bottom-left-radius: 10px !important; /* Match the desired radius */
  border-bottom-right-radius: 10px !important;
}



.callToActionCard {
  margin: 16px auto;
  max-width: 357px;
}

.bannerCardWrapper {
  margin: 16px auto;
  max-width: 390px;
}


.moreMargin {
  margin-bottom: 20px;
}

.accordionTitle {
  color: #484848;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
}

.accordionDescription {
  color: #505050;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
}

.bottomSpace {
  margin-bottom: 8vh;
}


