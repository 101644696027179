/* Ensure full-page responsiveness */
.container {
  width: 100%;
  max-width: 420px; /* Adjusted for mobile */
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Top Navbar Styling */
.topNavbar {
  width: 100%;
  max-width: 420px;
  text-align: center;
}

/* Logo Styling */
.logo {
  width: 80%;
  max-width: 293px;
  height: auto;
}

/* Card Styling */
.card {
  width: 90vw;
  max-width: 357px;
  min-height: 203px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
}

/* Card Text */
.cardText {
  width: 85%;
  max-width: 319px;
  text-align: center;
  color: #505050;
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
}

/* Blue Card (Button) */
.blueCard {
  border-radius: 17px;
  background: #4350E6;
  width: 230px;
  height: 34px;
  flex-shrink: 0;
}

.blueCardText {
  color: #FFF;
  font-family: "GT Walsheim Pro";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
}

.cardTitle {
  color: #484848;
  text-align: center;
  font-family: "GT Walsheim Pro";
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 136.842% */
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .container {
    max-width: 100%;
  }

  .card {
    width: 95%;
  }

  .blueCard {
    width: 95%;
  }
}

.extraSpace {
  margin-bottom: 50px;
}