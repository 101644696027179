/* General Background */
.background {
  width: 100%;
  min-height: 100vh;
  height: auto; /* ✅ Allows it to grow as needed */
  background: linear-gradient(102deg, #E12541 42.2%, #F85833 82.58%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  position: relative;
  padding-top: 80px;
  overflow-y: auto; /* ✅ Enables full scrolling */
}

/* Top Section */
.topSection {
  width: 100%;
  text-align: center;
  color: #fff !important;
}

.stepNumber {
  font-family: "GT Walsheim Pro", sans-serif;
  font-size: 13px !important;
  color: #fff !important;
  margin-bottom: 8px !important;
}

.title {
  font-family: "GT Walsheim Pro", sans-serif;
  font-size: 18px !important;
  font-weight: 500 !important;
  color: #fff !important;
  margin-bottom: 16px !important;
}

/* White Card */
.whiteCard {
  width: 90%;
  max-width: 350px;
  min-height: 400px; /* ✅ Ensures a minimum height */
  height: auto; /* ✅ Adjusts dynamically */
  max-height: none; /* ✅ Allows it to expand beyond viewport */
  border-radius: 10px;
  background: #fff;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
}

/* Title 2 */
.title2 {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: #484848;
  text-align: center;
  margin-bottom: 20px;
}
  
  /* Calendar */
  .calendarContainer {
    margin: 20px 0;
    display: flex;
    justify-content: center;
  }
  
  .customCalendar {
    background-color: #f8f8f8;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px;
  }
  
  .navButton {
    width: 14px;
    height: 14px;
  }
  
  /* Button */
  .buttonContainer {
    margin-top: auto; /* ✅ Pushes button down */
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 12px; /* ✅ Prevents button from touching bottom edge */
  }
 
  /* Example partial CSS overrides for react-day-picker classes */
.calendarContainer .rdp {
  background-color: transparent;
  border: none;
}

.rdp-months {
  display: flex;
  justify-content: center;
}

.rdp-table {
  border-spacing: 8px;
}

/* Etc. You can target .rdp-day_selected, .rdp-caption_label, etc. 
   For details, see the DayPicker docs: 
   https://react-day-picker.js.org/docs/styling
*/

.stepNumberWrapper {
  color: #fff !important;
  font-family: "GT Walsheim Pro", sans-serif !important;
  font-size: 13px !important;
  margin-bottom: 8px !important;
}

/* Custom Button Styling */
.customButton {
  max-width: 320px;
  min-width: 280px;
  height: 40px;
  flex-shrink: 0;
  padding: 12px 16px;
  font-family: "GT Walsheim Pro", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  background-color: #4350e6 !important;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  text-align: center;
  transition: all 0.2s ease-in-out;
}

/* Hover Effect */
.customButton:hover {
  background-color: #3a46c2;
}

/* Disabled State */
.customButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.extraSpaceTop {
  margin-top: 12vh;
}

.smallSpace {
  margin-top: 2vh;
}