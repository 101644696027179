.headerCard {
  margin: 0;
  position: relative;
  width: 100vw; /* Ensure full viewport width */
  min-width: 100%; /* Prevent it from shrinking */
  box-sizing: border-box;
  overflow: hidden;
  min-height: 170px;
  padding: 0 16px 60px 16px; /* Ensure text doesn't touch edges */
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  justify-content: center;
}

/* At 768px or wider, keep the card at a max of 350px and center */
@media (min-width: 768px) {
  .headerCard {
    max-width: 400px !important;
    margin: 0 !important;
    padding: 0 8px 20px 16px;
/*     margin-right: 5px; */
  }
}

.headerImageWrapper {
  position: absolute;
  bottom: 0;
  right: 0;
}

.headerImage {
  width: 100%;
  height: auto;
  object-fit: contain;
}

/* Title sits above the image */
.headerTitle {
  position: relative;
  z-index: 2; 
  font-family: "GT Walsheim Pro", sans-serif;
  font-size: 30px;
  line-height: 33px;
  font-weight: 400;
  color: #333;
  max-width: 80%; 
  text-align: left; /* or center, if you want */
}