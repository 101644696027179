/* TermsOfUseAlert.module.css */

/* Entire page area: 390 x 844, white background */
.pageContainer {
  width: 390px;
  height: 844px;
  background: #FFF;
  margin: 0 auto; /* Center horizontally if there's space */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* The central "modal" content area */
.modalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 390px;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 80px;
}

/* The lock icon at top: 45px wide, 64.688px tall */
.icon {
  width: 45px;
  height: 64.688px;
  margin-bottom: 16px;
}

/* Title text: 20px, bold, #333, "GT Walsheim Pro", line-height: 25px */
.headerTitle {
  width: 268px; /* optional if you want a max line width */
  height: 65.25px; /* not strictly needed, but included per your spec */
  text-align: center;
  color: #333;
  font-family: "GT Walsheim Pro", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  margin: 0 auto 16px auto; /* top/bottom spacing */
}

/* Body text: 14px, #484848, Inter, line-height 22px, centered */
.description {
  width: 314px;
  height: auto; /* you can drop explicit heights so text can expand */
  text-align: center;
  color: #484848;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin: 0 auto 20px auto;
}

/* Checkbox row: keep label and checkbox aligned horizontally */
.checkboxRow {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

/* The custom checkbox box: 16x16, 1.5px border, radius 4px */
.customCheckbox input[type="checkbox"] {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 1.5px solid #4350E6;
  /* Some browsers use accent-color for checkboxes: */
  accent-color: #4350E6;
  margin: 0; /* remove default bootstrap spacing if needed */
  margin-right: 8px;
}

/* Text around links: #434343, Inter, 14px, normal weight */
.checkboxLabel {
  color: #434343;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  margin: 0;
}

/* Links: #4350E6, Inter, 14px, bold */
.link {
  color: #4350E6;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  text-decoration: none;
}

/* Continue Button: 350x40, radius 20px. 
   "Activated" version: background #4350E6, color #FFF
*/
.continueButton {
  width: 350px;
  height: 40px;
  border-radius: 20px;
  background: #4350E6;
  color: #FFF;
  text-align: center;
  font-family: "GT Walsheim Pro", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 25px;
  border: none;
  cursor: pointer;
}

/* "Disabled"/inactive version: background #E6E9EE, color #494949 */
.continueButtonDisabled {
  width: 350px;
  height: 40px;
  border-radius: 20px;
  background: #E6E9EE;
  color: #494949;
  text-align: center;
  font-family: "GT Walsheim Pro", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 25px;
  border: none;
  cursor: not-allowed;
}

/* OPTIONAL: If you want some responsiveness or smaller device handling */
@media (max-width: 420px) {
  .pageContainer {
    width: 100%;
    height: auto; /* allow scrolling if needed */
  }

  .modalContainer {
    max-width: 100%;
  }

  .continueButton,
  .continueButtonDisabled {
    width: 90%;
  }
}

.headerImage {
  width: 45px;
  height: 64.688px;
  margin-bottom: 30px;
}

.extraSpace {
  margin-bottom: 10px;
  margin-top: 10px;
}