/* IntroPage3.module.css */

.flexColumn {
  display: flex;
  flex-direction: column;
}

.growFullHeight {
  height: 100vh;
}

.pagesInnerDiv {
  padding: 5vw;
}

.centerContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
/*   padding-bottom: 10vh; */
}

.title {
  color: #333;
  text-align: center;
  font-family: "GT Walsheim Pro";
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px; /* 113.333% */
  width: 272px;
  margin-top: 5vh;
}

.description {
  font-size: 14px;
  color: #494949;
  text-align: center;
  font-family: Inter;
  font-weight: 400;
  line-height: 24px;
  width: 301px;
  height: 143px;
  margin-bottom: -50px;
}

.image {
  width: 170px;
  height: auto;
  margin-bottom: 10px;
}

.blueButton {
  width: 100%;
  max-width: 260px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 24px;
  background: #4350E6;
  color: #FFF;
  text-align: center;
  font-family: "GT Walsheim Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; /* 156.25% */
  cursor: pointer;
  padding: 12px 24px;
  left: 50%;
  margin-bottom: 20vh;
}

.chatInfoSection {
  /* margin-top: 20px; */
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
}

.description2 {
  color: #393939;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  width: 252px;
  height: 130px;
  flex-shrink: 0;
  left: 60px;
}

.icon {
  width: 33px;
  height: 33px;
  flex-shrink: 0;
  left: 10px;
  margin-right: 10px;
}

.button1 {
  width: 350px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #4350E6;
  color: #FFF;
  text-align: center;
  font-family: "GT Walsheim Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; /* 156.25% */
}

.extraSpace {
  margin-top: 20vh;
}