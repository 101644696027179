/* General Page Styling */
.testPageContainer {
  width: 100%;            /* fills narrow devices */      /* caps at 400px on large screens */
  margin: 0 /* auto */;         /* centers horizontally on big screens */
  /* padding-left: 25px; */
  /* padding-right: 5px; */
  padding-left: 2.5vh;
  padding-right: 2.5vh;
  box-sizing: border-box;
}

/* Header Card Styling */
.headerContainer {
  margin-bottom: 16px;
}

.headerTitle {
  color: #4350e6;
  font-family: "GT Walsheim Pro";
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
}

.appWrapper {
  width: 100%;
  margin: 0 auto;  /* ensures centered on large screens */
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* .libraryAbortionMenuContainer {
  width: 100%;
  padding-left: 5vw;
  padding-right: 5vw;
} */


/* Flexible Card Styling */
.flexibleCard {
  width: 357px;
  padding: 16px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
  margin-bottom: 16px;
  margin-top: 20px !important;
}

.flexibleCard .title {
  color: #4350e6;
  font-family: "GT Walsheim Pro";
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
}

.flexibleCard .description {
  color: #484848;
  font-family: Inter;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 8px;
}

/* Questions Section */
.questionsSection {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
  width: 100%;
  max-width: 357px;
}

.questionCard {
  max-width: 350px; /* Prevents it from getting too wide */
  min-width: 350px; /* Ensures it doesn’t get too small */
  height: auto;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.15);
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  margin: 0 auto; /* Centers it properly */
}

@media (max-width: 374px) {
  /* For small mobile screens */
 .questionCard {
   min-width: 280px;
   max-width: 300px;
 }
}

.questionCard p {
  color: #484848;
  font-family: Inter;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  word-break: break-word; /* Ensure long text breaks into new lines */
  margin-bottom: 8px; /* Add spacing below the text */
}

.questionCardButtons {
  display: flex;
  gap: 8px;
  justify-content: center; /* Center the buttons horizontally */
}

.yesNoButton {
  width: 60px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #f8f8f8; /* Default border color */
  border-radius: 6px;
  background: #eeeeee;
  font-family: "GT Walsheim Pro";
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  text-align: center;
  color: #494949;
  cursor: pointer;
  transition: all 0.3s ease; /* Smooth transition */
}

.yesNoButton.active {
  border-color: #e12541; /* Solid border color when active */
  background: #eeeeee; /* Keep the background consistent */
  color: #494949; /* Keep text color consistent */
}

/* Submit Button */
.submitButton {
  width: 357px;
  height: 40px;
  border-radius: 20px;
  background: #4350e6;
  color: #ffffff;
  font-family: "GT Walsheim Pro";
  font-size: 16px;
  font-weight: 700;
  line-height: 25px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  cursor: pointer;
  transition: background 0.3s ease;
}

@media (max-width: 374px) {
  /* For small mobile screens */
 .submitButton {
   min-width: 280px;
   max-width: 280px;
 }
}

.submitButton.inactive {
  width: 320px;
  height: 40px;
  border-radius: 20px;
  background: #E6E9EE;
  color: #A7A9AC;
  text-align: center;
  font-family: "GT Walsheim Pro";
  font-size: 16px;
  font-weight: 700;
  line-height: 25px;
  cursor: not-allowed; /* Prevent clicks */
}

.submitButton:hover {
  background: #3b48cc;
}

/* Feedback Card */
.feedbackCard {
  margin-bottom: 24px;
}

.feedbackCard .title {
  color: #484848;
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
}

/* Small Cards Section */
.smallCardsContainer {
  display: flex;
  gap: 16px;
  margin-top: 16px;
}

.smallCard {
  width: 133px;
  height: 92px;
  border-radius: 8px;
  border: 1px solid #ffcca7;
  background: #ffcca7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  text-align: center;
}

.smallCard .title {
  font-family: "GT Walsheim Pro";
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  color: #494949;
}

.smallCard .description {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #494949;
  margin-top: 4px;
}

.carouselContainer {
  display: flex;
  justify-content: flex-start; /* Align items from the start */
  flex-wrap: nowrap; /* Prevent wrapping to the next row */
  gap: 10px; /* Space between cards */
  overflow-x: auto; /* Enables horizontal scrolling */
  padding: 10px 0;
  scroll-snap-type: x mandatory;
  white-space: nowrap; /* Ensures all items stay in a row */
  width: 100%; /* Ensures full container width */
  max-width: 100%; /* Removes fixed width restriction */
  box-sizing: border-box;
}

.extraTitleTwo {
width: 312px;
height: 36px;
top: 10px;
margin-top: 4px;
margin-bottom: 5px;
flex-shrink: 0;
color: #484848;
font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 22px; /* 122.222% */
}

.leftAlignWrapper {
display: flex;
flex-direction: column;
align-items: flex-start; /* Ensures all child elements are left-aligned */
width: 100%; /* Allows the wrapper to take up the full width of its container */
max-width: 390px; /* Optional: Keeps the maximum width consistent for items */
margin: 0 auto;/* Center the wrapper itself if needed */
padding: 0; /* Adjusts the left/right padding */
box-sizing: border-box; /* Ensures padding doesn't affect width calculations */
margin-top: 15px;
margin-bottom: 5px;
}

.spacer {
  height: 50px; /* Or however much space is required */
  width: 100%;
}

/* Specific styles for the special FlexibleCard instance */
.specificFlexibleCard .blueTitle {
  max-width: 213px; /* Restrict width */
  word-wrap: break-word; /* Ensure text wraps */
}

.specificFlexibleCard .description {
  max-width: 320px; /* Restrict width */
  word-wrap: break-word; /* Ensure text wraps */
}

.bottomSpace {
  margin-bottom: 8vh;
}