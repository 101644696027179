.cardButton {
  width: 100%; /* ✅ Ensures it takes full available width */
  max-width: 380px; /* ✅ Allows it to be slightly larger on wider screens */
  min-width: 280px; /* ✅ Prevents it from getting too small */
  height: auto; /* Keeps flexible height */
  border-radius: 10px;
  border: 1px solid #dbdbdb;
  background: #f8f8f8;
  display: flex;
  align-items: start;
  justify-content: center;
  padding: 12px 16px;
  box-sizing: border-box;
  cursor: pointer;
  transition: border 0.2s ease;
}
  
  .cardButton.selected {
    border: 2px solid #e12541; /* Active state border */
  }
  
  .cardWithIcon {
    height: 66px; /* Height for cards with an icon */
    justify-content: space-between;
  }
  
  .cardContent {
    display: flex;
    align-items: start;
    width: 100%;
    justify-content: space-between;
  }
  
  .cardText {
    color: #494949;
    font-family: 'Inter', sans-serif;
    font-size: 12px; /* ✅ Slightly larger for readability */
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    word-break: normal; /* ✅ Prevents overly aggressive wrapping */
    white-space: normal; /* ✅ Allows text to wrap naturally */
    flex: 1; /* ✅ Allows text to expand */
}
  
  .iconButton {
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .questionmarkIcon {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
  }