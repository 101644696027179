.smallCardContainer {
  background-color: #4350E6;
  border-radius: 7px;
  /* padding: 10px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
  width: 120px;
  height: 150px;
  position: relative; /* Make the container a positioned element */
  flex-shrink: 0;
  transition: transform 0.2s ease-in-out;
}

.cardIcon {
  position: absolute; /* Absolutely position the image */
  bottom: 0; /* Align to the bottom */
  right: 0; /* Align to the right */
  width: 98px; /* Keep image width and height */
  height: 91px;
  border-radius: 0 0 0 0; /* Optional: Make sure corners match card's bottom right */
  flex-shrink: 0;
}

.cardTitle {
  color: #FFF;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(0, 0, 0, 0.00);
  font-family: "GT Walsheim Pro";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 146.667% */
  white-space: normal;
  word-wrap: break-word;
  margin-bottom: auto;
  z-index: 1; /* Ensure the text stays on top of the image */
  text-align: left !important;
  padding-left: 5px;
  box-sizing: border-box;
  width: 100%;
}

.cardLink {
  text-decoration: none;
  display: block;
  cursor: pointer; /* ✅ Adds pointer cursor */
}

.smallCardContainer:hover {
  transform: scale(1.05);
}