.pageContainer {
    width: 100%; /* Full width for responsiveness */
    min-height: 120vh; /* Ensure the page fills the screen */
    background: var(--Purple-gradient, linear-gradient(99deg, #4450E5 22.9%, #616CEE 77.43%));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 0 auto;
    position: relative;
    padding: 16px 0;
    padding-top: 80px;
}

.topSection {
    text-align: center;
    color: #fff;
}

.stepNumber {
    font-family: "GT Walsheim Pro";
    font-size: 13px;
    font-weight: 400;
    padding-bottom: 10px;
    color: #fff;
}

.title {
    color: #484848;
    font-family: Inter;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    text-align: center;
    margin-bottom: 8px;
}

.pageTitle {
    color: #FFF;
    text-align: center;
    font-family: "GT Walsheim Pro";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px; /* 138.889% */
  }

.whiteCard {
    width: 90%;
    max-width: 350px;
    min-height: 400px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Center child elements */
    padding: 16px;
}

@media (max-width: 380px) {
    .whiteCard {
        width: 95%;
    }
}

.optionContainer {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
    width: 100%;
    gap: 12px;
    flex-grow: 1; /* Ensures it expands to take available space */
}

.optionRow {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    width: 90%;
    max-width: 320px;
    min-height: 56px;
    max-height: 72px;
    background: #f8f8f8;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #505050;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    border: 1px solid #DBDBDB;
}

/* .optionRow:hover {
    background: #F0F0F0;
} */

.choiceSelected {
    border: 2px solid var(--red-orange-gradient, #e12541);
    background: #f8f8f8;
    font-weight: 600;
    color: #303030;
}

.continueButton {
    width: 90%;
    max-width: 320px;
    padding: 12px;
    font-size: 16px;
    color: #fff;
    background: #ccc;
    border: none;
    border-radius: 20px;
    cursor: not-allowed;
    margin-top: 10px; /* Allows natural spacing */
}

.continueButtonActive {
    background: #4350e6 !important;
    cursor: pointer;
}

.bottomSpace {
    margin-bottom: 4vh;
}