.realStoriesContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr)); /* Ensures two columns when possible */
  gap: 16px; /* Adjust space between cards */
  justify-content: center;
  align-items: stretch;
  width: 100%;
  max-width: 800px; /* Control max width */
  margin: 0 auto;
  padding: 16px;
}

/* Default: Two-column layout */
@media (min-width: 480px) { 
  .realStoriesContainer {
      grid-template-columns: repeat(2, 1fr); /* Two columns for phones */
  }
}

/* Large screens: Two-column stays */
@media (min-width: 768px) { 
  .realStoriesContainer {
      grid-template-columns: repeat(2, 1fr);
  }
}

/* Very small screens: Keep it flexible */
@media (max-width: 400px) {
  .realStoriesContainer {
      grid-template-columns: repeat(2, minmax(120px, 1fr)); /* Still keep two columns */
  }
}

/* Adjust card size */
.realStoryCard {
  width: 100%; /* Ensures it scales properly */
  max-width: 160px; /* Adjust if needed */
  height: auto;
}
  
  .cardContent {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    padding: 16px; /* Moved padding to content to keep text positioned well */
  }
  
  .imageContainer {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 130px; /* Adjust as needed to fit the card */
    height: 140px;
    z-index: 1;
    overflow: hidden; /* Ensures no part of the image overflows */
  }
  
  .cardImage {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image fully covers the container without stretching */
    border-bottom-right-radius: 10px; /* Matches the card's border radius */
  }
  
  .textContainer {
    z-index: 2;
    text-align: left;
    margin-bottom: 10px; /* Adjust as needed */
  }
  
  .cardTitle {
    color: #303030;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgba(0, 0, 0, 0.00);
    font-family: "GT Walsheim Pro";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 120% */
  }
  
  .cardDescription {
    color: #303030;
    font-family: "GT Walsheim Pro";
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px; /* 136.364% */
  }
  
  .iconContainer {
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: 3;
  }
  
  .cardIcon {
    width: 24px;
    height: 24px;
  }
  