.homePageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 390px; /* Keeps all items centered and within a fixed width */
  margin: 0 auto; /* Centers the container */
  padding: 16px !important; /* Adds padding to the left and right */
  box-sizing: border-box; /* Ensures padding does not increase the width */
  margin-top: -20px;
}

@media (min-width: 320px), (max-width: 376px) {
  /* Adjustments for narrow devices */
  .homePageContainer {
    padding-left: 35px;
  }
}

@media (min-width: 377px) {
  /* Adjustments for medium devices */
  .homePageContainer {
    padding-left: 42px;
  }
}

@media (min-width: 500px) { 
  /* Adjustments for medium devices */
  .homePageContainer {
    padding-left: 55px;
  }
}

@media (min-width: 768px) {
  /* Adjustments for narrow devices */
  .homePageContainer {
    padding-left: 35px; /* This is necessary for the header to stay centered */
  }
}

.bannerCardWrapper {
    display: flex;
    justify-content: center;
    max-width: 100%; /* Let the card fill the entire width */
    margin-bottom: 20px; /* Optional: add space below the card */
  }

  .extraSpace2 {
    margin-top: 35px;
  }

  .carouselContainer {
    display: flex;
    gap: 10px;
    overflow-x: auto;
    /* padding: 10px 0; */
    left: 0;
    scroll-snap-type: x mandatory;
    max-width: 100%; /* Restrict width to maintain visibility */
    margin: 0 auto; /* Centers the carousel container */
    box-sizing: border-box;
  }
  
  .carouselContainer::-webkit-scrollbar {
    display: none; /* Hides the scrollbar for a cleaner look */
  }
  
  .carouselCard {
    flex: 0 0 calc((390px / 2.5) - 10px); /* Adjust width to show 2.5 cards */
    scroll-snap-align: start; /* Aligns each card to the start */
  }
  
  .chatMenuContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 90%;
    box-sizing: border-box;
  }
  
  .contentWrapper {
    width: 100%;
    max-width: 100%; /* Keeps the maximum width consistent for all items */
    margin: 0 auto;
    padding: 0 16px; /* Adjust the padding to control the left/right margins */
    box-sizing: border-box; /* Ensures padding does not increase the width */
  }  
  
  .carouselContainer {
    display: flex;
    gap: 10px;
    overflow-x: auto;
    /* padding: 10px 0; */
    scroll-snap-type: x mandatory;
    max-width: 100%; /* Restrict width to maintain 2.5 cards visibility */
    margin: 0 0;
  }
  
  @media (min-width: 768px) {
    .carouselContainer {
      max-width: 100%; /* Keep the carousel constrained to 390px width for desktop too */
    }
  }
  
  .carouselContainer {
    display: flex;
    gap: 10px;
    overflow-x: auto;
    /* padding: 10px 0; */
    scroll-snap-type: x mandatory;
    max-width: 100%; /* Restrict width to maintain visibility */
    margin: 0 auto; /* Centers the carousel container */
    box-sizing: border-box;
  }
  
  .carouselContainer::-webkit-scrollbar {
    display: none; /* Hides the scrollbar for a cleaner look */
  }
  
  .carouselCard {
    flex: 0 0 calc((390px / 2.5) - 10px); /* Adjust width to show 2.5 cards */
    scroll-snap-align: start; /* Aligns each card to the start */
  }
  
  
  .exploraContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    max-width: 100%;
  }
  
  .sectionTitleExplora {
    color: #333;
    font-family: "GT Walsheim Pro";
    font-size: 30px;
    font-weight: 400;
    line-height: 24px;
  }
  
  .scrollInfo {
    color: #666;
    font-family: "GT Walsheim Pro";
    font-size: 14px;
    font-weight: 400;
  }
  
  .headerContainer {
    margin-bottom: 20px; /* Adjust this value to increase/decrease the gap */
    margin-left: 5px;
  }

  @media (min-width: 300px) {
    /* Adjustments for medium devices */
   .headerContainer {
     margin-right: 5px;
    }
  }
  
  .bannerCardWrapper {
    display: flex;
    justify-content: center;
    width: 100%; /* Let the card fill the entire width */
    margin-bottom: 20px; /* Optional: add space below the card */
  }

  .extraTitle {
    max-width: 300px;
    min-width: 280px;
    height: 39px;
    flex-shrink: 0;
    color: #505050;
    text-align: center;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px; /* 146.154% */
    margin-bottom: 20px;
  }


  .sourceText {
    max-width: 300px;
    height: 34px;
    flex-shrink: 0;
    color: #989898;
    text-align: center;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 140% */
    margin-top: 14px;
    margin-bottom: 17px;
  }

  .smallLabel {
    width: 63.411px;
    height: 16px;
    flex-shrink: 0;
    color: #FFF;
    text-align: center;
    font-family: "GT Walsheim Pro";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 169.231% */
  }

.extraTitletwo {
  width: 312px;
  height: 36px;
  top: 10px;
  margin-top: 10px;
  flex-shrink: 0;
  color: #484848;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 122.222% */
  align-items: flex-start;
}

.leftAlignWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Ensures all child elements are left-aligned */
  max-width: 100%; /* Allows the wrapper to take up the full width of its container */
  /* max-width: 390px; */ /* Optional: Keeps the maximum width consistent for items */
  margin: 0 auto; /* Center the wrapper itself if needed */
  padding: 0; /* Adjusts the left/right padding */
  box-sizing: border-box; /* Ensures padding doesn't affect width calculations */
}

/* RealStories.module.css */
.realStoriesContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);  /* 2 cards per row */
  gap: 16px;  /* Spacing between cards */
  padding: 16px;
  justify-items: center; /* Centers the cards */
}

/* Adjust this class if needed for small screens */
@media (max-width: 600px) {
  .realStoriesContainer {
    grid-template-columns: 1fr; /* One card per row on smaller screens */
  }
}


.moreSpace {
 margin-top: 10px;
 margin-bottom: 10px;
}


.accordionWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.accordionWrapper .MuiAccordion-root:first-of-type {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.accordionWrapper .MuiAccordion-root:last-of-type {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.MuiAccordionSummary-root {
  padding: 0 !important; /* Remove MUI default padding */
}

.MuiAccordionSummary-content {
  margin: 0 !important; /* Override margin */
}

.MuiAccordionSummary-expandIconWrapper {
  margin-left: auto; /* Align arrow to the right */
  transform: rotate(0deg); /* Ensure correct rotation for closed state */
}

.MuiAccordionSummary-expandIconWrapper.Mui-expanded {
  transform: rotate(180deg); /* Rotate arrow when expanded */
}

.MuiAccordionDetails-root {
  padding: 13px 16px; /* Match the specified padding */
  background-color: #FFF !important; /* Fix unwanted grey background */
}

.accordionWrapper {
  padding-top: 16px; /* Adds spacing above the first accordion */
  padding-bottom: 16px; /* Adds spacing below the last accordion */
}

.accordionWrapper .MuiAccordion-root:first-of-type {
  border-top-left-radius: 10px !important; /* Match the desired radius */
  border-top-right-radius: 10px !important;
}

.accordionWrapper .MuiAccordion-root:last-of-type {
  border-bottom-left-radius: 10px !important; /* Match the desired radius */
  border-bottom-right-radius: 10px !important;
}



.callToActionCard {
  margin: 16px auto;
  max-width: 357px;
}

.bannerCardWrapper {
  margin: 16px auto;
  max-width: 100%;
}


.moreMargin {
  margin-bottom: 20px;
}



.button1 {
  background: #4350E6;
  color: #fff;
  border-radius: 20px;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  border: none;
  margin-bottom: 10px;
  cursor: pointer;
}

.button2 {
  background: transparent;
  color: #4350e6;
  border: 2px solid #4350e6;
  border-radius: 20px;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
}

.button1, .button2 {
  width: 100%;        /* Let the container decide the width */
  max-width: 340px;   /* But never exceed 350px on larger devices */
  height: 40px;
  /* ... your existing styles (color, font, etc.) remain ... */
}

.bottomSpace {
  margin-bottom: 8vh;
}