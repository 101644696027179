.bannerCardContainer {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding: 13px 15px;
  border-radius: 10px;
  overflow: hidden;
  width: 100%; /* Ensure it takes full available width */
  min-width: 280px; /* ✅ Enforce minimum width */
  max-width: 350px; /* ✅ Enforce maximum width */
}

/* ✅ Ensuring the container adapts responsively */
@media (max-width: 400px) {
  .bannerCardContainer {
    max-width: 320px; /* Adjust for small screens */
  }
}

@media (max-width: 320px) {
  .bannerCardContainer {
    max-width: 280px; /* Minimum width for very small screens */
  }
}

.bannerImage {
  border-radius: 10px;
  flex-shrink: 0;
}

.bannerTextContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: left;
  max-width: 250px;
  padding-left: 10px;
  gap: 4px; /* ✅ Ensures consistent spacing between title and text */
}

.bannerTitle {
  color: #FFF;
  font-family: "GT Walsheim Pro";
  font-style: normal;
  font-size: 14px;
  font-weight: 700;
  word-wrap: break-word;
  text-align: left !important;
  margin-bottom: 0 !important;
  margin-top: 7px;
}

.bannerText {
  color: #FFF;
  font-family: "Inter";
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  word-wrap: break-word;
  text-align: left !important;
  margin-top: 0 !important;
  padding-bottom: 12px;
}

.bannerTitle,
.bannerText {
  width: 100%; /* ✅ Ensures the text expands properly */
  max-width: 200px; /* ✅ Prevents unnecessary wrapping */
  overflow-wrap: break-word; /* ✅ Ensures text wraps within the box */
  word-break: break-word;
  line-height: 1.2;
}