.container {
  width: 100%;
  max-width: 390px; /* Ensuring it fits within typical mobile screen sizes */
  margin: 0 auto; /* Centers the content */
  padding: 0;
  background-color: #fff;
}

.content {
  width: 100%;
  padding: 0 16px; /* Adds padding to ensure content doesn't touch edges */
}

.heading {
  color: #080808;
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  margin: 20px 0 10px; /* Adjust spacing for mobile */
}

.menuCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 43px;
  background-color: #fff;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2637);
  padding: 10px 16px; /* Space for text and icons */
  box-sizing: border-box; /* Ensures padding doesn't increase width */
  cursor: pointer;
}

.menuCardIcon {
  /* width: 24px; */ /* Default size for icons */
  height: auto;
  margin-right: 12px; /* Space between icon and text */
}

.extraSpace {
  margin-bottom: 80px;
}

.menuCardText {
  color: #333;
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
  flex-grow: 1; /* Ensures text expands while keeping layout aligned */
}

.menuCardArrow {
  width: 15px;
  height: auto;
  margin-left: auto; /* Pushes it to the right */
}

/* Make sure elements don’t overflow */
@media (max-width: 420px) {
  .container {
    max-width: 100%;
  }
  
  .menuCard {
    width: 100%;
    padding: 10px;
  }
}