/* General Container */
.headerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Centers content vertically */
  width: 100%;
  height: 100vh; /* Ensures full height */
  background-color: #fff;
  text-align: center;
}

/* Logo Styling */
.logo {
  width: 54px;
  height: 54px;
  margin-bottom: 10px;
}

/* Headings */
.heading {
  color: #4350E6;
  font-family: "GT Walsheim Pro", sans-serif;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 5px;
  font-style: normal;
}

.subheading {
  color: #7E7E7E;
  text-align: center;
  font-family: "GT Walsheim Pro";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 116.667% */
  width: 235px;
  margin-bottom: 20px;
  left: 60%;

}

/* Language Selection Container */
.radioContainer {
  display: flex;
  flex-direction: column;
  width: 85%;
  max-width: 350px;
  gap: 10px; /* Adds spacing between items */
}


/* Language Options */
.radioItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F7F7F7;
  border-radius: 10px;
  padding: 15px 20px;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  color: #333;
  cursor: pointer;
  transition: background 0.3s ease, border 0.3s ease;
  border: 1px solid transparent; /* Default border is invisible */
}

/* Add border when hovered */
.radioItem:hover {
  border: 1px solid #E12541;
}

/* Hide Default Radio Buttons */
.radioItem input[type="radio"] {
  display: none;
}

/* Apply red border when the radio input is checked */
.radioItem input[type="radio"]:checked + label {
  border: 1px solid #E12541;
}

/* Apply red border to the entire radio item when checked */
.radioItem input[type="radio"]:checked + label + .radioItem {
  border: 1px solid #E12541;
}

/* Custom Radio Indicator */
.radioItem label {
  flex: 1;
  cursor: pointer;
  font-size: 16px;
  border: 1px solid #E12541;
}

/* Continue Button */
.continueButton {
  width: 85%;
  max-width: 350px;
  height: 40px;
  border-radius: 20px;
  background: #4350E6;
  color: #FFF;
  text-align: center;
  font-family: "GT Walsheim Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; /* 156.25% */
  margin-top: 20px;
  border: none;
  cursor: pointer;
  transition: background 0.3s ease;
}

.continueButton:disabled {
  background-color: #E6E9EE;
  cursor: not-allowed;
  color: #A7A9AC;
}

.language1 {
  color: #494949;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px; /* 141.667% */
}

.language2 {
  color: #ABABAB;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
} 

.languageContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  width: 100%;
}

.titles {
  display: flex;
  /* justify-content: space-between; */
  flex-direction: column;
  width: 100%;
}