/* General container for the page */
.pageContainer {
  width: 100%;
  min-height: 100vh;
  height: auto; /* ✅ Allows it to grow as needed */
  background: linear-gradient(102deg, #E12541 42.2%, #F85833 82.58%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  position: relative;
  padding-top: 80px;
  overflow-y: auto; /* ✅ Enables full scrolling */
}

/* Top section with the progress and title */
.topSection {
  text-align: center;
  color: #fff;
}

.stepNumber {
  font-family: "GT Walsheim Pro";
  font-style: normal;
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 8px;
  color: #fff;
}

.title {
  font-family: "GT Walsheim Pro";
  font-style: normal;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  color: #fff;
}

/* White card styling */
.whiteCard {
  max-width: 350px;
  min-width: 300px;
  height: 592px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

@media (max-width: 361px) {
  /* Adjust the width and height for larger screens */
 .whiteCard {
   max-width: 280px;
   height: 90vh;
 }
}

/* Icon in the card */
.icon {
  width: 40px;
  height: 40px;
  margin-bottom: 20px;
  margin-top: 30px;
}

.icon2 {
  width: 40px;
  height: 40px;
  margin-bottom: 20px;
  margin-top: 80px;
}

.image {
  width: 138px;
  height: 136px;
  flex-shrink: 0;
  margin-bottom: 20px;
}

/* Text styling */
.youHave {
  color: #303030;
  text-align: center;
  font-family: "GT Walsheim Pro";
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 35px; /* 116.667% */
  margin: 10px 0; /* Add spacing around the sentence if needed */
}

.weeks {
  color: #303030;
  text-align: center;
  font-family: "GT Walsheim Pro";
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 35px; /* 116.667% */
}

.paragraph {
  color: #303030;
  text-align: center;
  width: 291px;
  flex-shrink: 0;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px; /* 146.154% */
  color: #303030;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* Button container */
.buttonContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 5px;
}

.button1 {
  width: 320px;
    height: 40px;
    border-radius: 20px;
    background: #4350E6;
    color: #fff;
    font-family: "GT Walsheim Pro";
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    line-height: 40px;
    border: none;
    cursor: pointer;
}

/* .button1:hover {
  background: #3030c4;
} */

.button2 {
  max-width: 320px;
  min-width: 280px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #4350E6;
  color: #fff;
  text-align: center;
  font-family: "GT Walsheim Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; /* 156.25% */
  margin-top: 10px;
}

.button3 {
  max-width: 320px;
  min-width: 280px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 20px;
  border: 2px solid #4350E6;
  background-color: #fff;
  color: #4350E6;
  text-align: center;
  font-family: "GT Walsheim Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; /* 156.25% */
  margin-top: 5px;
}

.extraSpaceTop {
  margin-top: 12vh;
}