.background {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #f8f9fa;
    min-height: 100vh;
  }
  
  .whiteCard {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 800px;
    text-align: center;
  }
  
  .pdfContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 20px;
  }
  
  .errorText {
    color: red;
    font-size: 16px;
    margin-top: 10px;
  }
  
  .loader {
    margin-top: 20px;
  }