/* ContraTestResultCard.module.css */
.resultCard {
  max-width: 350px;
  min-width: 280px;
  height: 54px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.15);

  /* So the text + icon can sit nicely side by side */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px; /* add some padding on the sides */
  margin: 8px auto; /* center horizontally + small vertical spacing */
}

.methodName {
  color: #303030;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  padding-top: 10px;
}

.icon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
