/* ContraTestResult.module.css */

.pageContainer {
    /* Whatever parent container styling you need */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding: 16px;
    width: 100%;
    min-height: 110vh;
  }

  .background{
    width: 100%;
    height: 100vh; /* Ensures the page fills the screen but allows it to grow */
    background: linear-gradient(102deg, #E12541 42.2%, #F85833 82.58%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; /* Align content to the top */
    margin: 0;
    position: relative;
    padding-top: 80px; /* Small padding to prevent overlap */
  }
  
  .headerArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
  }
  
  /* "upper title" styles */
  .title {
    width: 218px;
    height: 17px;
    flex-shrink: 0;
    color: #303030;
    text-align: center;
    font-family: "GT Walsheim Pro", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px; /* 138.889% */
    margin-bottom: 12px; /* bit of spacing below the title */
  }
  
  /* "subtitle" styles */
  .subTitle {
    width: 290px;
    color: #303030;
    text-align: center;
    font-family: "GT Walsheim Pro", sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px; /* 113.333% */
    margin-bottom: 16px; /* spacing below the subtitle */
  }
  
  /* "header image" styles */
  .headerImage {
    width: 182px;
    height: 222.85px;
    flex-shrink: 0;
    object-fit: contain; /* or cover, depending on your preference */
    margin-bottom: 24px;
  }
  
  /* Container around the banner card (if needed) */
  .bannerCardContainer {
    display: flex;
    justify-content: center;
    margin: 16px 0;
  }
  
  /* CTA container that holds your button */
  .ctaContainer {
    display: flex;
    justify-content: center;
    margin: 24px 0;
  }
  
  /* The CTA button itself */
  .ctaButton {
    width: 354px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 20px;
    background: #4350E6;
    border: none;       /* remove default border */
    cursor: pointer;    /* show pointer on hover */
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
  }
  
  /* Text inside the CTA button */
  .ctaButtonText {
    width: 236px;
    height: 37px;
    flex-shrink: 0;
    color: #FFF;
    text-align: center;
    font-family: "GT Walsheim Pro", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px; /* 156.25% */
  }

  .moreBottomSpace {
    margin-top: 8vh;
  }

  .moreTopSpace {
    margin-top: 8vh;
  }