.pageContainer {
  width: 100%;
  min-height: 140vh; /* Ensures the page fills the screen but allows it to grow */
  background: var(--red-orange-gradient, linear-gradient(102deg, #E12541 42.2%, #F85833 82.58%));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align content to the top */
  margin: 0;
  position: relative;
  padding-top: 10vh; /* Small padding to prevent overlap */
}

  .topSection {
    text-align: center;
    color: #fff;
  }
  
  .stepNumber {
    font-family: "GT Walsheim Pro";
    font-style: normal;
    font-size: 13px;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 20px;
    color: #fff;
  }
  
  .title {
    font-family: "GT Walsheim Pro";
    font-style: normal;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    color: #fff;
  }

  .whiteCard {
    width: 90%;
    max-width: 350px;
    min-height: 400px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Center child elements */
    padding: 16px;
  }

  @media (max-width: 380px) { 
    .whiteCard{
      width: 280px;
  }
  }
  
  .title2 {
    color: #484848;
    font-family: Inter;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    text-align: center;
    margin-bottom: 16px;
  }

  .optionRow {
    display: flex;
    align-items: center; /* Align items vertically */
    justify-content: space-between; /* Space between text and icon */
    width: 267px; /* Set consistent width for each row */
    min-height: 66px; /* Set consistent height for each row */
    max-height: 82px;
    margin: 0 auto 12px auto; /* Center align rows and add spacing below */
    /* padding-left: 22px; */ /* Add padding to the left */
    padding-right: 63px; /* Add padding to the right */
    position: relative; /* Position icon within row without affecting layout */
}

.choice {
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Align text to the left */
    background: #f8f8f8;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-size: 12px;
    line-height: 15px;
    font-weight: 400;
    color: #505050;
    text-align: left; /* Ensure text aligns to the left */
    flex-grow: 1; /* Allow to fill the remaining width */
    padding: 10px 12px; /* Add consistent padding inside cards */
    height: 100%; /* Full height of parent row */
    width: calc(100% - 40px); /* Leave space for icon */
    cursor: pointer;
}

  .choiceSelected {
    border: 2px solid var(--red-orange-gradient, #e12541);
    background: #f8f8f8;
  }

/* .choiceContent {
    display: flex;
    align-items: center;
    width: calc(100% - 20px);
} */

.icon {
    width: 24px;
    height: 24px;
    position: absolute; /* Position icon without affecting layout */
    right: 0 !important; /* Align to the far right of the parent row */
    top: 50%; /* Vertically center */
    transform: translateY(-50%); /* Adjust for proper centering */
    cursor: pointer;
}

@media (max-width: 400px) { 
  .icon{
    right: 25px !important;
}
}

.continueButton {
    width: 100%;
    padding: 10px;
    background: #ccc;
    border: none;
    border-radius: 20px;
    margin-top: 20px;
    cursor: not-allowed;
    color: #FFF;
    text-align: center;
    font-family: "GT Walsheim Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px; /* 156.25% */
}

.continueButtonActive {
    background: #4350e6;
    cursor: pointer;
}