.callToActionCard {
    display: flex;
    flex-direction: column;
    padding: 12px;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    flex-shrink: 0;
    border-radius: 10px;
    max-width: 100%;
    cursor: default !important;
}

@media (max-width: 374px) {
    /* For small mobile screens */
   .callToActionCard {
     min-width: 280px;
     max-width: 280px;
   }
  }

.cardContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.leftIconContainer {
    margin-right: 15px; /* Adjust margin as needed */
    display: flex;
    align-items: center; /* Vertically centers the icon */
    justify-content: center;
}

.rightIconContainer {
    margin-left: 15px; /* Adjust margin as needed */
    display: flex;
    align-items: center; /* Vertically centers the icon */
    justify-content: center;
}

.cardLeftIcon, .cardRightIcon {
    width: 24px; /* Adjust size as needed */
    height: 24px; /* Adjust size as needed */
    object-fit: contain; /* Make sure icon is not stretched or cropped */
}

.textContainer {
    flex-grow: 1;
    text-align: left;
}

.cardTitle {
    margin: 0;
}

.cardDescription {
    margin-top: 5px;
}