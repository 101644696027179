.navContainer {
  position: fixed;
  width: 100%;
  height: 65px;
  bottom: 0;
  left: 0;
  background: white;
  border-top: 1px solid #E0E0E0;
  z-index: 1001;
  /* margin-bottom: 5px; */
}

@media (min-width: 768px) {
  .navContainer {
      max-width: 400px; /* Limits the width */
      left: 50%;
      transform: translateX(-50%); /* Centers it */
  }
}

.topBorder {
  position: relative;
  height: 2px;
}

.borderSegment {
  position: absolute;
  height: 2px;
  background: transparent;
  transition: all 0.3s ease;
}

.activeBorder {
  background: #E12541;
  z-index: 2;
}

.navBar {
  display: flex;
  justify-content: space-around;
  padding: 8px 0;
}

.navItem {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  padding: 6px;
  /* margin-bottom: 5px; */
}

.iconContainer {
  width: 24px;
  height: 24px;
  margin-bottom: 4px;
}

.iconContainer img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* ✅ Default (Inactive) Title */
.title {
  font-size: 0.7rem;
  color: #949494;  /* Inactive titles are gray */
  text-align: center;
  transition: color 0.3s ease;
}

/* ✅ Active Title - Pink */
.activeTitle {
  color: #E12541 !important;
  font-weight: 500;
}