.languageChangeContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px; /* Added space at the top */
  margin-top: 60px;
}

.languagePageTitle {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #10123F;
}

.languageIntroText {
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 400;
}

.languageOptionsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.languageSelection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  width: 280px; /* Smaller width */
  height: 60px;
  border-radius: 10px;
  background: #F8F8F8;
  border: 1px solid #D3D3D3;
  font-size: 18px;
  font-weight: 500;
  color: #333;
  cursor: pointer;
  transition: border 0.3s ease-in-out;
  position: relative;
}

.languageSelection.selected {
  border: 1px solid #E12541;
  border-image-slice: 1;
  background: #fff;
  border-radius: 10px !important;
}

.languageSelection input[type="radio"] {
  display: none;
}

.languageText {
  flex-grow: 1;
}

.checkmark {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #F8F8F8;
  margin-left: auto;
  cursor: pointer;
  transition: background 0.3s ease-in-out, border 0.3s ease-in-out;
}

.languageSelection.selected .checkmark {
  background: #4350E6;
  border-color: #4350E6;
}

.checkmark::after {
  content: "✔";
  font-size: 14px;
  color: #fff;
  display: none;
}

.languageSelection.selected .checkmark::after {
  display: block;
}

.confirmButton {
  display: block;
  margin: 30px auto 0;
  padding: 12px 24px;
  border: none;
  border-radius: 24px;
  background-color: #4350E6;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  font-weight: 600;
  width: 220px; /* Adjusted smaller width */
  height: 44px;
  transition: background 0.3s ease-in-out;
}

.confirmButton:hover {
  background-color: #2E3BBF;
}

.confirmButton:disabled {
  background-color: #F8F8F8;
  cursor: not-allowed;
}