.homePageContainer {
  width: 100%;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: hidden;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lockIcon {
  width: 28px;
  height: 28px;
  flex-shrink: 0;
}

/* ================= TEXT STYLES ================ */

.sectionTitle {
  color: #080808;
  font-family: "GT Walsheim Pro";
  font-size: 30px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
  z-index: 10;
}

.sectionTitle2 {
  color: #080808;
  font-family: "Inter";
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  position: relative;
  z-index: 10;
  /* top: -10px; remove or adjust if it causes misalignment */
}

.sectionTitle3 {
  color: #080808;
  font-family: "GT Walsheim Pro";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
  z-index: 10;
  /* left: 150px; remove if it’s shifting everything right */
}

.sectionTitleExplora {
  flex-grow: 1;
  color: #333;
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
}

/* ================= SMALL CARDS ================ */

.smallCardsGrid {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.smallIconCard {
  /* Key to horizontal scrolling: each item is fixed/min width */
  min-width: 120px;
  margin-right: 10px;
  display: inline-block;
  flex-shrink: 0;
}

.cardIcon {
  width: 98px;
  height: 91px;
  flex-shrink: 0;
}

.cardTitle {
  margin-top: 5px;
  font-size: 14px;
  color: #fff;
  text-align: center;
}

.smallCardContainer {
  background-color: #4350E6;
  border-radius: 7px;
  padding: 10px;
  display: inline-block;
  text-align: center;
  width: 120px;
  height: 150px;
  flex-shrink: 0;
}

/* ================= EXPLORE SECTION ================ */

.exploraContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 390px;  remove or set to 100% if it caused horizontal overflow */
  width: 100%;
  margin-bottom: 10px;
}

.scrollInfo {
  white-space: nowrap;
  /* right: 10vh; if absolutely positioned, ensure correct container. */
  color: #333;
  font-family: Inter;
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
}

/* ================= HORIZONTAL CAROUSEL ================ */

/* This is the container that horizontally scrolls. */
.exploreSection {
  width: 100%;
  overflow-x: auto !important; /* Allow scrolling */
}

.carouselContainer {
  display: flex;
  gap: 10px;
  overflow-x: auto !important; /* Ensure scrolling is explicitly allowed */
  overflow-y: hidden;
  white-space: nowrap;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 10px;
  width: 100%; /* Ensure it fills the parent container */
  min-width: 100%; /* Make sure it doesn't shrink */
  max-width: none; /* Remove any max-width constraints */
  touch-action: auto; /* Allow both trackpad and mouse interaction */
  cursor: grab; /* Allows dragging */
}

.carouselContainer:active {
  cursor: grabbing;
}

/* Hide scrollbar for cleaner UI */
.carouselContainer::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for a cleaner look in webkit browsers */
.carouselContainer::-webkit-scrollbar {
  display: none;
}

/* ================= OTHER ================= */

.bottomSpace {
  margin-bottom: 6vh;
}

/* ================= MEDIA QUERIES ================ */

/* 
   If your top-level Layout sets max-width:400px, 
   these might cause double-centering. 
   Uncomment only if you truly need them. 
*/
/*
@media (min-width: 768px) {
  .homePageContainer {
    max-width: 350px;
    left: 50%;
    transform: translateX(-50%);
  }
  .headerContainer {
    max-width: 350px;
  }
  .carouselContainer {
    max-width: 350px;
    margin-right: auto;
  }
  .smallCardsGrid {
    width: auto;
  }
  .exploraContainer {
    max-width: 310px;
  }
  .scrollInfo {
    right: 0.5vh;
  }
}

@media (max-width: 767px) {
  .homePageContainer {
    max-width: 100% !important;
    overflow-x: visible !important;
  }
  .exploreSection {
    width: max-content !important;
    max-width: none !important;
  }
}

@media (min-width: 390px) {
  .scrollInfo {
    right: 4.5vh;
  }
}
*/