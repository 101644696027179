.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: #fff;
}

.upperCard {
  width: 357px;
  height: auto; /* ✅ Allow the card to expand as needed */
  flex-shrink: 0;
  border-radius: 10px;
  background: #fff;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.15);
  padding: 20px;
  text-align: left;
  overflow: hidden; /* ✅ Prevents content from spilling out */
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.cardDescription {
  width: 100%; /* ✅ Ensures text stays within the container */
  height: auto; /* ✅ Allow text to expand */
  color: #505050;
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.cardTitle {
  color: #484848;
  font-family: "GT Walsheim Pro", sans-serif;
  font-size: 19px;
  font-weight: 500;
  line-height: 26px;
}

/* .cardDescription {
  width: 319px;
  height: 144px;
  flex-shrink: 0;
  color: #505050;
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
} */

/* 🔹 PIN Card */
.pinCard {
  width: 357px;
  height: 282px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #fff;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.15);
  padding: 20px;
  text-align: center;
}

.pinTitle {
  color: #484848;
  font-family: "GT Walsheim Pro", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  width: 319px;
  margin-bottom: 15px;
}

/* 🔳 PIN Inputs */
.pinInputs {
  display: flex;
  justify-content: space-between;
  width: 230px;
  margin: 0 auto 20px;
}

.pinInput {
  width: 53px;
  height: 68px;
  flex-shrink: 0;
  border-radius: 17px;
  border: 1px solid #cdcdcd;
  background: #f8f8f8;
  text-align: center;
  font-size: 24px;
}

/* 🔵 Blue Button */
.blueButton {
  width: 230px;
  height: 34px;
  flex-shrink: 0;
  border-radius: 17px;
  background: #4350e6;
  color: #fff;
  font-family: "GT Walsheim Pro", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  margin: 15px auto;
}

/* 🔗 Lower Text Link */
.textLink {
  color: #505050;
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-weight: 400;
  text-decoration: underline;
  width: 319px;
  text-align: center;
  margin-top: 10px;
}

.extraSpace {
  margin-bottom: 50px;
}