.menuCard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 390px;
    height: 43px;
    padding: 10px;
    border-bottom: 1px solid rgba(112, 112, 112, 0.26);
    text-decoration: none;
    background: white;
  }
  
  .cardContent {
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  .icon {
    /* width: 20px; */ /* Adjustable based on Contentful icons */
    height: auto;
    margin-right: 15px;
  }
  
  .title {
    flex-grow: 1;
    font-size: 13px;
    color: #333;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    line-height: 19px;
  }
  
  .arrow {
    width: 20px;
    height: 10px;
  }