/* src/Components/MyCalendar.css */

/* Outer calendar container */
.myCustomCalendar {
    width: 105%;
    max-width: 350px;
    background-color: #f8f8f8;
    border: 1px solid #dbdbdb;
    border-radius: 10px;
    font-family: "GT Walsheim Pro", sans-serif;
    color: #727272;
}

/* The nav bar row with arrows + month name */
.myCalendarNavBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid #dbdbdb;
}

.myCalendarNavBar .leftArrow,
.myCalendarNavBar .rightArrow {
    background: none;
    border: none;
    cursor: pointer;
}

.myCalendarNavBar .monthYearLabel {
    font-size: 16px;
    font-weight: 500;
    color: #727272;
}

/* The DayPicker “wrapper” that contains the weekdays + day grid */
.myCustomCalendar .DayPicker-wrapper {
    padding: 0 1rem 1rem;
}

/* The row of weekdays (Ma, Mi, Ju, Vi, Sa, Do, Lu) */
.myCustomCalendar .DayPicker-Weekdays {
    margin-bottom: 0.5rem;
}
.myCustomCalendar .DayPicker-WeekdaysRow {
    display: flex;
    justify-content: space-around;
}
.myCustomCalendar .DayPicker-Weekday {
    font-size: 14px;
    color: #727272;
}

/* Each week row */
.myCustomCalendar .DayPicker-Week {
    display: flex;
    justify-content: space-evenly;
}

/* Each day cell */
.myCustomCalendar .DayPicker-Day {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4px auto;
    font-size: 14px;
    border-radius: 50%;
    transition: background-color 0.2s ease;
}

/* The day when hovered */
.myCustomCalendar .DayPicker-Day:not(.DayPicker-Day--outside):hover {
    background-color: #e6e6e6; /* Light hover effect */
}

/* Highlight for today's date */
.myCustomCalendar .DayPicker-Day--today {
    color: #4350E6;
    font-weight: bold;
    border: 2px solid #4350E6;
    border-radius: 50%;
    background-color: transparent;
}

/* Styling for the selected date */
.myCustomCalendar .DayPicker-Day--selected {
    background-color: #4350E6 !important;
    color: #fff;
    border-radius: 50%;
}

/* Remove bold styling from today if another date is selected */
.myCustomCalendar.selectedToday .DayPicker-Day--today {
    color: #727272; /* Normal font color */
    font-weight: normal; /* Reset font weight */
    border: none; /* Remove the border */
    background-color: transparent; /* Reset background */
}