.realStoriesContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px; /* Adjust spacing between cards */
  justify-content: center;
  align-items: stretch;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 16px;
}

/* Ensure smaller screens keep content inside */
@media (max-width: 390px) {
  .realStoriesContainer {
    padding: 8px;
    width: calc(100% - 16px); /* Keeps content from being cut off */
  }
}

.headerContainer {
  margin-top: 5vh;
}

.bottomContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
    padding: 20px;
  }
  
  .title {
    margin-top: 40px;
    margin-bottom: 20px;
    text-align: center;
  }

  .extraTitleTwo {
    color: #333;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 100% */
  }

  .bottomSpaceHeader {
    margin-bottom: 2vh;
  }
  
  .cardsContainer {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center horizontally */
    align-items: center;
  }
  
  .card {
    flex: 0 0 calc(50% - 20px); /* Adjust card width */
    margin: 10px; /* Adjust card margin */
  }
  
  .card img {
    max-width: 100%;
  }
  
  .cardDescription {
    color: white;
    margin-top: 10px;
    font-size: 16px;
    text-align: center;
  }
  
  @media (min-width: 768px) {
    .card {
      width: calc(50% - 20px);
    }
  }
  

  .container {
    margin-top: 100px
  }
  h2 {
    color: white;
    margin-top: 50px
  
  }
  
  .mainPage .cardContainer {
    overflow-x: auto;
    white-space: nowrap;
    
  }
  
  .mainPage .card {
    display: inline-block;
    width: 300px; /* Set your desired card width */
    margin-right: 20px;
  }
  
  .mainPage .card img {
    max-width: 100%;
  }
  
  .mainPage .cardDescription {
    color: white;
    margin-top: 10px;
  }
  
  @media (max-width: 768px) {
    .mainPage .card {
      width: 90%;
    }
  }
  
  .surveyIconsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px
  }
  
  .surveyIcon {
    width: 40px;
    height: 40px;
    margin-right: 10px; 
  }
  
  .bottomContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .bottomContainer > * {
    flex-basis: calc(50% - 10px); /* Each card takes up half of the available width with a small gap */
    margin-bottom: 20px;
  }
  
  .bottomContainer img {
    max-width: 100%;
  }
  
  .bottomContainer h3 {
    font-size: 16px;
    margin-top: 10px;
  }
  
  .bottomContainer p {
    margin-top: 5px;
  }
  
  .bottomSpace {
    margin-bottom: 12vh;
  }

  .topSpace {
    margin-top: 15vh;
  }

  .realStoriesContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Always enforce two columns */
    gap: 16px; /* Space between cards */
    justify-content: center;
    align-items: stretch;
    width: 100%;
    max-width: 800px; /* Prevents cards from stretching too wide */
    margin: 0 auto;
    padding: 16px;
}

/* Ensure it never collapses into a single column */
@media (max-width: 480px) { 
  .realStoriesContainer {
      grid-template-columns: repeat(2, 1fr); /* Force two columns */
  }
}

/* For extremely small screens, prevent single column */
@media (max-width: 360px) {
  .realStoriesContainer {
      grid-template-columns: repeat(2, 1fr) !important; /* Maintain two columns */
  }
}

/* Adjust card size */
.realStoryCard {
  width: 100%; /* Ensures it scales properly */
  max-width: 160px; /* Adjust to fit within columns */
  height: auto;
}

.bottomSpace {
  margin-bottom: 8vh;
}