.homePageContainer {
  width: 100%;            /* fills narrow devices */      /* caps at 400px on large screens */
  margin: 0 /* auto */;         /* centers horizontally on big screens */
  /* padding-left: 25px; */
  /* padding-right: 5px; */
  padding-left: 2.5vh;
  padding-right: 2.5vh;
  box-sizing: border-box;
}

.appWrapper {
  width: 100%;
  margin: 0 auto;  /* ensures centered on large screens */
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.bannerCardWrapper {
    display: flex;
    justify-content: center;
    width: 100%; /* Let the card fill the entire width */
    margin-bottom: 20px; /* Optional: add space below the card */
  }

  .carouselContainer {
    display: flex;
    justify-content: flex-start; /* Ensures alignment to the left */
    flex-wrap: wrap; /* Allows wrapping of cards */
    gap: 10px; /* Space between cards */
    overflow-x: auto; /* Enables horizontal scrolling if needed */
    padding: 10px 0;
    scroll-snap-type: x mandatory;
    max-width: 390px;
    margin-left: 0; /* Remove auto centering */
    box-sizing: border-box;
    align-items: flex-start; /* Prevents unwanted vertical alignment issues */
}
  
  .carouselContainer::-webkit-scrollbar {
    display: none; /* Hides the scrollbar for a cleaner look */
  }
  
  .carouselCard {
    flex: 0 0 calc((390px / 2.5) - 10px); /* Adjust width to show 2.5 cards */
    scroll-snap-align: start; /* Aligns each card to the start */
    flex: 0 0 auto;
    scroll-snap-align: start;
    width: fit-content;
  }
  
  .libraryAbortionMenuContainer {
    width: 100%;
/*     margin: 0 auto;
    padding: 10px auto; */
  }

  .carouselContainer {
    display: flex;
    justify-content: flex-start; /* Align items from the start */
    flex-wrap: nowrap; /* Prevent wrapping to the next row */
    gap: 10px; /* Space between cards */
    overflow-x: auto; /* Enables horizontal scrolling */
    padding: 10px 0;
    scroll-snap-type: x mandatory;
    white-space: nowrap; /* Ensures all items stay in a row */
    width: 100%; /* Ensures full container width */
    max-width: 100%; /* Removes fixed width restriction */
    box-sizing: border-box;
}
  
  @media (min-width: 768px) {
    .carouselContainer {
      max-width: 390px; /* Keep the carousel constrained to 390px width for desktop too */
    }
  }
  
  .carouselContainer::-webkit-scrollbar {
    display: none; /* Hides the scrollbar for a cleaner look */
  }
  
  .carouselCard {
    flex: 0 0 calc((390px / 2.5) - 10px); /* Adjust width to show 2.5 cards */
    scroll-snap-align: start; /* Aligns each card to the start */
  }
  
  .exploraContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    max-width: 390px;
  }
  
  .sectionTitleExplora {
    color: #333;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    font-size: 30px;
    font-weight: 400;
    line-height: 24px;
  }
  
  .scrollInfo {
    color: #666;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
  }
  
  .headerContainer {
    margin-bottom: 60px; /* Adjust this value to increase/decrease the gap */
    /* margin-left: 5px; */
    top: 50px;
    /* margin-left: -30px; */
  }

  @media (min-width: 768px){
    /* Adjustments for medium devices */
   .headerContainer {
     margin-right: 80px;
     margin-left: -100px;
    }
  }


  @media (min-width: 500px), (max-width: 767px){
    /* Adjustments for medium devices */
   .headerContainer {
     margin-right: 100px;
     margin-left: -85px;
    }
  }

  @media (min-width: 280px), (max-width: 499px){ 
    /* Adjustments for narrow devices */
   .headerContainer {
     margin-right: 5px;
     margin-left: -30px !important;
    }
  }

  .headerContainer2 {
    margin-right: 60px;
    top: 50px;
    margin-bottom: 80px; 
  }

  .headerContainerContraception {
    margin-bottom: 60px; /* Adjust this value to increase/decrease the gap */
    /* margin-left: 5px; */
    top: 50px;
    /* margin-left: 30px; */
  }
  
  .bannerCardWrapper {
    display: flex;
    justify-content: center;
    width: 100%; /* Let the card fill the entire width */
    margin-bottom: 20px; /* Optional: add space below the card */
  }

  .extraTitle {
    max-width: 300px;
    min-width: 280px;
    height: 34px;
    flex-shrink: 0;
    color: #989898;
    text-align: center;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 140% */
    margin: 10px;
    margin-top: 15px;
  }

  .sourceText {
    width: 300px;
    height: 34px;
    flex-shrink: 0;
    color: #989898;
    text-align: center;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 140% */
    margin-top: 14px;
    margin-bottom: 17px;
  }

  .smallLabel {
    width: 63.411px;
    height: 16px;
    flex-shrink: 0;
    color: #FFF;
    text-align: center;
    font-family: "GT Walsheim Pro";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 169.231% */
  }

.extraTitletwo {
  width: 312px;
  height: 36px;
  top: 5px;
  margin-top: 4px;
  margin-bottom: 5px;
  flex-shrink: 0;
  color: #484848;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 122.222% */
}

.leftAlignWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Ensures all child elements are left-aligned */
  width: 100%; /* Allows the wrapper to take up the full width of its container */
  max-width: 390px; /* Optional: Keeps the maximum width consistent for items */
  margin: 0 auto;/* Center the wrapper itself if needed */
  padding: 0; /* Adjusts the left/right padding */
  box-sizing: border-box; /* Ensures padding doesn't affect width calculations */
}

/* .centeredContent {
  text-align: center;
  padding-left: 16px;
  padding-right: 16px;
} */

/* RealStories.module.css */
.realStoriesContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 cards per row */
  gap: 16px; /* Spacing between cards */
  padding: 16px;
  justify-items: center; /* Centers the cards */
}

/* Adjust this class if needed for small screens */
@media (max-width: 600px) {
  .realStoriesContainer {
    grid-template-columns: repeat(2, 1fr); /* Keep 2 per row on small screens */
  }
}

/* Adjust for even smaller screens if needed */
@media (max-width: 400px) {
  .realStoriesContainer {
    grid-template-columns: 1fr; /* Fall back to 1 per row for extra small screens */
  }
}


.moreSpace {
 margin-top: 10px;
 margin-bottom: 10px;
}

.bottomSpace {
  margin-bottom: 12vh;
}

.bottomSpaceHeader {
  margin-bottom: 2vh;
}

.audioTitle {
  color: #FFF;
  text-align: center;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(0, 0, 0, 0.00);
  font-family: "GT Walsheim Pro";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 120% */
  width: 190px;
  height: 90px;
  flex-shrink: 0;
}

.audioContainer {
  display: flex;
  align-items: center;
}

.audioIcon {
  margin-right: 8px; /* Adjust spacing between icon and title */
}

.extraSpace2 {
  margin-top: 55px;
}