.largeCardContainer {
  background-color: #FDE8D8;
  border-radius: 7px;
  padding: 15px;
  cursor: pointer;
  margin-top: 20px;
  width: 100%;
  max-width: 348px;
  height: 128px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden; /* Ensure no overflow */
}

.cardContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  top: 15px;
  left: 15px;
}

.cardTitle {
  color: #333;
-webkit-text-stroke-width: 1;
-webkit-text-stroke-color: rgba(0, 0, 0, 0.00);
font-family: "GT Walsheim Pro";
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: 22px; /* 146.667% */
}

.arrowIcon {
  position: absolute;
  left: 15px;
  bottom: 15px;
  width: 24px;
  height: 24px;
}

.cardImage {
  position: absolute;
  bottom: 0;
  right: 0;
  width: auto; /* Allow natural width */
  height: 100%; /* Ensure full height */
  /* max-width: 45%; */ /* Restrict width to fit inside the card */
  object-fit: contain; /* Ensures full image is visible */
  border-radius: 10px;
}