.pageContainer {
  width: 100%;
  min-height: 100vh;
  height: auto; /* ✅ Allows it to grow as needed */
  background: linear-gradient(102deg, #E12541 42.2%, #F85833 82.58%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  position: relative;
  padding-top: 80px;
  overflow-y: auto; /* ✅ Enables full scrolling */
}

.topSection {
  text-align: center;
  color: #fff;
}

.stepNumber {
  font-family: "GT Walsheim Pro";
  font-style: normal;
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 20px;
  color: #fff;
}

.title {
  font-family: "GT Walsheim Pro";
  font-style: normal;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  color: #fff;
}

.whiteCard {
  width: 350px;
  height: 592px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.title2 {
  color: #484848;
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
  margin-bottom: 16px;
  margin-top: 3vh;
}

.image {
  width: 155px;
  height: 78px;
  margin-bottom: 16px;
  top: 3vh !important;
}

.description {
  width: 297px;
  color: #505050;
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
  text-align: center;
  margin-bottom: 24px;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  top: 5vh;
}

.button1 {
  width: 320px;
  height: 40px;
  border-radius: 20px;
  background: #4350E6;
  color: #fff;
  font-family: "GT Walsheim Pro";
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  line-height: 40px;
  border: none;
  cursor: pointer;
}

.button2 {
  width: 320px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid #4350E6;
  color: #4350E6;
  font-family: "GT Walsheim Pro";
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  line-height: 40px;
  background: #fff;
  cursor: pointer;
}

.extraSpaceTop {
  margin-top: 12vh;
}