/* PopUpNew.module.css */
.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popupContainer {
  background: #fff;
  border-radius: 12px;
  padding: 20px;
  width: 300px;
  max-width: 80%;
}

.popupTitle {
  margin-top: 0;
  font-size: 20px;
  text-align: center;
}

.popupDescription {
  margin: 15px 0;
  text-align: center;
}

.buttonRow {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.popupButton {
  padding: 5px 5px;
  border: none;
  background: #4350E6;
  color: #fff;
  border-radius: 17px;
  cursor: pointer;
  width: 96px;
  height: 34px;text-align: center;
  font-family: "GT Walsheim Pro";
  font-size: 15px;
  font-style: normal;
}

.popupLink {
  color: #4350E6;
  text-decoration: underline;
}