.cardContainer {
    width: 100%;
    max-width: 350px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
    padding: 16px;
    margin-bottom: 20px;
    position: relative;
    box-sizing: border-box;
  }

  @media (max-width: 360px) {
    /* Adjustments for desktop */
   .cardContainer {
     max-width: 280px;
   }
  }
  
  .oval {
    position: absolute;
    top: -12px;
    left: 16px;
    background-color: #4350e6;
    color: #fff;
    padding: 5px 12px;
    font-size: 12px;
    border-radius: 50px;
    font-family: "Inter", sans-serif;
    font-weight: 500;
  }
  
  .blueTitle {
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    color: #4350E6;
    margin-bottom: 4px;
    margin-top: 7px;
  }
  
  .blackTitle {
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px;
    color: #484848;
    margin-bottom: 8px;
  }
  
  .imageContainer {
    display: flex;
    justify-content: center;
    align-items: center; /* Ensures vertical alignment */
    width: 100%;
    max-width: 100%;
    margin-top: 16px;
  }
  
  .cardImage {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .description {
    color: #505050;
    font-family: "Inter", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px !important; /* 146.154% */
    margin-top: 8px;
  }
  
  .blackTitleBottom {
    font-size: 15px;
    font-weight: 700;
    color: #484848;
    margin-top: 10px; /* Adds spacing below description */
  }
  
  .audioIconContainer {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 10;
  }
  
  .audioIcon {
    width: 24px;
    height: 24px;
  }