/* Ensure splash container is centered on larger screens */
.splashContainer {
  width: 390px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  background-color: #FFFFFF;
}

/* Inner wrapper to limit width & center the app on larger screens */
.splashWrapper {
  width: 100%;
  max-width: 390px; /* Simulates mobile width */
  height: 100vh;
  max-height: 844px; /* Simulates mobile height */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #FFFFFF;
  position: relative;
  overflow: hidden;
  border-radius: 20px; /* Optional: Makes it feel like a mobile screen */
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1); /* Soft shadow for realism */
}

/* Individual splash screens */
.splashScreen {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: 0;
  left: 0;
}

/* LOGO */
.logo {
  width: 60vw;
  max-width: 250px;
  margin-top: 8vh;
  position: relative;
  z-index: 2;
}

/* SUBTITLE - Ensures it's directly below the logo */
.subTitle {
  color: #FFF;
  text-align: center;
  font-family: "GT Walsheim Pro", sans-serif;
  font-size: 14px;
  max-width: 80%;
  position: relative;
  margin-top: 2vh;
  z-index: 2;
}

/* Woman Illustrations */
.imageBlueWoman {
  /* width: 299px; */
  width: 100%;
  height: 422px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

/* White Woman Illustration */
.imageWhiteWoman {
  width: 110%;
  max-width: 100%;
  height: 450px;
  position: absolute;
  bottom: 0;
  left: 0;
  object-fit: cover;
  object-position: top center;
  z-index: 1;
}

/* Flower Animation */
.flowerAnimation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  max-width: 408px;
  height: 422px;
  z-index: 0;
  pointer-events: none;
  opacity: 1;
}
