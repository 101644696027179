
.background {
    background: var(--Purple-gradient, linear-gradient(99deg, #4450E5 22.9%, #616CEE 77.43%));
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    height: 120vh;
    margin-top: 3vh;
}

.title {
    margin-top: 40px;
    font-family: "Inter";
    font-size: 15px;
    color: #fff;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
}

.extraSpace {
    margin-top: 20px;
}

.title2 {
    font-family: "GT Walsheim Pro", sans-serif;
    font-size: 30px;
    color: #fff;
    text-align: center;
    font-weight: 400;
    line-height: 35px;
    margin-top: 10px;
    /* width: 311px; */
    height: auto; /* Allow the height to adjust dynamically */
}

.imageContainer {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin: 0; /* Ensure no extra margin */
    /* top: 50px; */
}

.image {
    width: 252px;
    height: 252px;
    margin: 0; /* Remove any margin */
    padding: 0; /* Remove any padding */
}
  
.button {
    margin-top: 20px;
    max-width: 350px;
    min-width: 280px;
    height: 40px;
    border-radius: 20px;
    background: #FFF;
    color: #4350E6;
    text-align: center;
    font-family: "GT Walsheim Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px; /* 156.25% */
    text-align: center;
    border: none;
    cursor: pointer;
    padding: 10px;
    z-index: 1001;
}

.linkText {
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px; /* 146.154% */
    max-width: 350px;
    min-width: 280px;
    height: 141px;
    flex-shrink: 0;
    margin-top: 12px;
}

.bottomSpace {
    margin-bottom: 5vh;
  }