
.flexColumn {
  display: flex;
  flex-direction: column;
}

.growFullHeight {
  height: 100vh;
}

.pagesInnerDiv {
  padding: 5vw;
  height: 100vh;
  overflow: hidden;
}

@media (min-width: 768px) {
  .pagesInnerDiv{
    /* max-width: 350px; */
    left: 50%;
    transform: translateX(-50%);
  }
}

.centerContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Ensures content starts from the top */
  height: 100%; /* Full height for consistent layout */
}

.centerContent2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Ensures content starts from the top */
  height: 100%; /* Full height for consistent layout */
  margin-top: 20vh;
}

.title {
  color: #333;
  text-align: center;
  font-family: "GT Walsheim Pro";
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px; /* 113.333% */
  width: 272px;
  top: 10vh
}

.description {
  margin-bottom: 15px;
  top: 12vh;
  max-width: 80%;
  color: #494949;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
}

.blueButton {
  width: 80%;
  max-width: 260px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 24px;
  background: #4350E6;
  color: #FFF;
  text-align: center;
  font-family: "GT Walsheim Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; /* 156.25% */
  margin-top: 100px;
  cursor: pointer;
  padding: 12px 24px;
  left: 50%;
  bottom: 8vh;
}

.extraSpace {
  margin-top: 5vh;
}