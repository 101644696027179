.background{
  width: 100%;
  height: 100vh; /* Ensures the page fills the screen but allows it to grow */
  background: linear-gradient(102deg, #E12541 42.2%, #F85833 82.58%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align content to the top */
  margin: 0;
  position: relative;
  padding-top: 80px; /* Small padding to prevent overlap */
}

/* Title and Step Number Container */
.titleContainer {
  text-align: center;
  margin-bottom: 20px;
}

.title {
  color: #FFF;
  font-family: "GT Walsheim Pro";
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  margin: 0;
}

.stepCounter {
  color: #FFF;
  font-family: "GT Walsheim Pro";
  font-size: 13px;
  font-weight: 400;
  margin: 5px 0 0;
}

/* White Card */
.whiteCard {
  width: 90%;
  max-width: 350px;
  min-height: 300px; /* ✅ Ensures it has enough space but isn't too tall */
  max-height: 80vh; /* ✅ Prevents it from taking up the whole screen */
  border-radius: 10px;
  background: #fff;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

.subtitle {
  color: #484848;
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
  margin-bottom: 20px;
}

/* Radio Group */
.radioGroup {
  width: 60%; /* ✅ Ensures the radio card fills the available space */
  max-width: 280px; /* ✅ Prevents it from getting too wide */
  gap: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px;
}

/* Button */
.continueButton {
  max-width: 320px;
  min-width: 280px;
  height: 40px;
  border-radius: 20px;
  font-family: "GT Walsheim Pro";
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  line-height: 25px;
  margin-top: 20px;
  border: none;
  cursor: pointer;
}

.buttonInactive {
  background: #E6E9EE;
  color: #A7A9AC;
  cursor: not-allowed;
}

.buttonActive {
  background: #4350E6;
  color: #FFF;
  cursor: pointer;
}

.extraSpaceTop {
  margin-top: 8vh;
}