/* IntroPageSurvey.module.css */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  margin: auto;
  padding: 20px;
  background-color: #fff;
}

.centerContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  top: 10vh;
}

.title {
  /* font-size: clamp(24px, 5vw, 36px); */
  color: #10123F;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px; /* 125% */
  max-width: 240px;
  margin-top: 50px;
}

.image {
  width: 155px;
  height: 78px;
  flex-shrink: 0;
}

.radioGroup {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 16px 0;
}

.radioLabel {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
  color: #10123F;
}

.radioLabel input {
  margin-right: 10px;
}

.blueButton {
  font-size: 20px;
  font-family: "GTWalsheimPro-Medium";
  color: #fff;
  background-color: #4350E6 !important;
  border: none;
  padding: 10px 20px;
  border-radius: 24px;
  cursor: pointer;
  width: 80%;
  max-width: 320px;
  min-width: 280px;
  height: 44px;
  margin: 30px auto 0 auto;
  position: relative;
  text-transform: capitalize;
}

.bottomText {
  color: #4350E6;
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 169.231% */
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
  cursor: pointer;
  margin-top: 10px;
}
