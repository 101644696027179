/***** MOBILE FIRST *****/

/* - GT Walsheim pro, all fonts in folder if needed */
/* Fonts */
@font-face {
  font-family: "Pattaya";
  src: local("Pattaya"), url(./Fonts/Pattaya-Regular.ttf) format("truetype");
}

.pattayaFont {
  font-family: "Pattaya", sans-serif;
}

@font-face {
  font-family: 'GT Walsheim Pro';
  src: url('./Fonts/GTWalsheimPro-Regular.ttf') format('truetype'),
       url('./Fonts/GTWalsheimPro-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GTWalsheimPro-Regular";
  src: local("GTWalsheimPro-Regular"),
    url(./Fonts/GTWalsheimPro-Regular.ttf) format("truetype");
}

.regularFont,
#regularFont,
.css-1eoe787-MuiSlider-markLabel {
  font-family: "GTWalsheimPro-Regular", sans-serif !important;
}

@font-face {
  font-family: "GTWalsheimPro-Medium";
  src: local("GTWalsheimPro-Medium"),
    url(./Fonts/GTWalsheimPro-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "AbhayaLibre-Medium";
  src: local("AbhayaLibre-Medium"),
  url(./Fonts/AbhayaLibre-Medium.ttf) format("truetype");

}

@font-face {
  font-family: "AbhayaLibre-ExtraBold";
  src: local("AbhayaLibre-ExtraBold"),
  url(./Fonts/AbhayaLibre-ExtraBold.ttf) format("truetype");

}

.mediumFont {
  font-family: "GTWalsheimPro-Medium", sans-serif;
}

@font-face {
  font-family: "GTWalsheimPro-Bold";
  src: local("GTWalsheimPro-Bold"),
    url(./Fonts/GTWalsheimPro-Bold.ttf) format("truetype");
}

.boldFont,
#boldFont {
  font-family: "GTWalsheimPro-Bold", sans-serif;
}

html, body {
  font-family: "Walsheim Pro", "Inter", sans-serif !important;
}

body {
  font-family: "GT Walsheim Pro", "Inter", sans-serif;
}

@font-face {
  font-family: "GTWalsheimPro-RegularOblique";
  src: local("GTWalsheimPro-RegularOblique"),
    url(./Fonts/GTWalsheimPro-RegularOblique.ttf) format("truetype");
}

@font-face {
  font-family: "GTWalsheimPro-Regular";
  src: local("GTWalsheimPro-Regular"),
    url(./Fonts/GTWalsheimPro-Regular.ttf) format("truetype");
}

/* Add Inter from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

/* Global Font Styles */
body, html {
  font-family: "GTWalsheimPro-Regular", "Inter", sans-serif;
}

/* Ensure Inter is used in places where GT Walsheim Pro is not applied */
.interFont {
  font-family: "Inter", sans-serif;
}

/* Ensure a fallback for components that might not be using the correct font */
* {
  font-family: "GTWalsheimPro-Regular", "Inter", sans-serif;
}

.symptomDescriptionPadding {
  padding-top: 10px;
}

@media screen and (max-width: 410px) {
  .symptomDescriptionPadding {
    padding-top: 150px !important;
  }
}
  
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden !important; /* Prevent horizontal scrollbars */
  overflow-y: auto !important;
  box-sizing: border-box;
  font-family: sans-serif; /* Adjust as needed */
  overflow-y: scroll;
}
*, *::before, *::after {
  box-sizing: border-box;
}
.symptomButtonsWrapper {
  display: flex;
  align-items: center;
}

.symptomContainer {
  margin-left: -40px; 
}

.symptomButtonsWrapper2 {
  padding-left: 10px;
}

.symptomButtonsWrapper3 {
  padding-left: 50px;
}



.fontSize10 {
  font-size: 10px;
}
.fontSize15 {
  font-size: 15px;
}

.fontSize20 {
  font-size: 20px;
}

.fontSize25 {
  font-size: 25px;
}

.lineHeight2 {
  line-height: 2.4285em;
}

.regularObliqueFont {
  /* Similar to italic */
  font-family: "GTWalsheimPro-RegularOblique", sans-serif;
}

/* body {
  background-color: #10123d !important;
  font-family: "GTWalsheimPro-Regular", sans-serif; */
  /* height: 300em; */
/* } */

body {
  background-color: #fff !important;
  font-family: "GTWalsheimPro-Regular", sans-serif;
  /* height: 300em; */
}

body * {
  margin: 0;
}

.main-container { 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 450px;
  min-height: 100vh; /* Ensures it takes up the full height */
  padding-bottom: 80px; /* Space for the bottom navbar */
}

@media (min-width: 768px) {
  .main-container {
    max-width: 400px; /* Ensures a centered container on larger screens */
  }
}


/* .appBackgroundColor {
  background-color: #10123d;
} */

.appBackgroundColor {
  background-color: #fff;
}

/* .app {
  max-width: 927px;
  min-height: 100vh;
  margin: auto;
  max-height: 500em;
} */

.marginAuto {
  margin: auto;
}

/* Removing default styles */
div {
  position: relative;
}

p {
  margin-bottom: 3px;
  word-break: break-word;
}

a {
  color: unset;
  text-decoration: none;
}
a:hover {
  text-decoration: none !important;
}

.linkHasUnderline a {
  text-decoration: underline;
}

#hasBlueLink a {
  color: #22479f;
}

button {
  background-color: transparent;
  border: none;
  padding: 0px;
  line-height: 15px !important;
}

button:focus {
  outline: none;
}

*:focus {
  outline: none;
}

/* Font sizes */
.fontSize77 {
  font-size: 77px;
}

.fontSize43 {
  font-size: 43px;
}

.fontSize46 {
  font-size: 46px;
}

.fontSize40 {
  font-size: 40px;
}

.fontSize35 {
  font-size: 35px;
}

.fontSize30 {
  font-size: 30px;
}

.fontSize28 {
  font-size: 28px;
}

.fontSize25 {
  font-size: 25px;
}

.fontSize24 {
  font-size: 24px;
}

.fontSize23 {
  font-size: 23px;
}

.fontSize22 {
  font-size: 22px;
}

.fontSize20 {
  font-size: 20px;
}

.fontsize19 {
  font-size: 19px;
}

.fontSize18 {
  font-size: 18px;
}

.fontSize16 {
  font-size: 16px;
}

.fontSize15 {
  font-size: 15px;
}

.fontSize14 {
  font-size: 14px;
}

.fontSize13 {
  font-size: 13px;
}

.fontSize12 {
  font-size: 12px;
}

.fontSize11 {
  font-size: 11px;
}

.fontSize10 {
  font-size: 10px;
}

.fontSize9 {
  font-size: 9px;
}

.fontWeightBold {
  font-weight: 900;
}
.fontSizeRem {
  font-size: 0.9rem;
}

/* line height */

.lineHeight5 {
  line-height: 5px;
}

.lineHeight10 {
  line-height: 10px;
}

.lineHeight11 {
  line-height: 11px;
}

.lineHeight14 {
  line-height: 14px;
}

.lineHeight15 {
  line-height: 15px;
}

.lineHeight16 {
  line-height: 16px;
}

.lineHeight17 {
  line-height: 17px;
}

.lineHeight18 {
  line-height: 18px;
}

.lineHeight20 {
  line-height: 20px;
}

.lineHeight22 {
  line-height: 22px;
}

.lineHeight23 {
  line-height: 23px;
}

.lineHeight24 {
  line-height: 24px;
}

.lineHeight25 {
  line-height: 25px;
}

.lineHeight26 {
  line-height: 26px;
}

.lineHeight28 {
  line-height: 28px;
}

.lineHeight30 {
  line-height: 30px;
}

.lineHeight95Percent {
  line-height: 95%;
}

.lineHeightNormal {
  line-height: normal;
}



/* Font Colors */
.colorWhite,
a.colorWhite,
.colorWhite a {
  color: #ffffff !important;
}

.colorDarkGrey {
  color: #525252;
}

.colorBlack0 {
  color: #524f4f;
}

.backgroundColorLightGray {
  background-color: #f0f0f0;
}

.backgroundColorNavy {
  background-color: #22386c;
}

.backgroundColorBlue {
  background-color: #22469f;
}

.colorBlack1 {
  color: #3d3d3d;
}
.colorBlack2 {
  color: #333333;
}
.colorBlack3 {
  color: #262525; /* #292A2B */
}
.colorBlack4 {
  color: #191818;
}

.colorLightPurple {
  background-color: #94AEEC;
}

.colorPink {
  color: #fda4a5;
}

.colorPeach1 {
  color: #f77478;
}
.colorPeach2 {
  color: #f57279;
}

.colorPeach3 {
  color: #f75f65; /* same #F75F64, #f65f64 */
}
.colorPeach4,
.hasColorPeach4Span span,
#colorPeach4 {
  color: #f74a57; /* same #F85258 */
}
.colorPeach5 {
  color: #f74a58;
}

.colorPeach6 {
  color: #FF5D69;
}

.colorBlue1 {
  color: #b9c0ef;
}
.colorBlue2 {
  color: #6f8ed9;
}
.colorBlue3 {
  color: #5d7cc7;
}
.colorBlue4 {
  color: #476abf;
}
.colorBlue5 {
  color: #3156b2;
}
.colorBlue6 {
  color: #22469f;
}
.colorBlue7 {
  color: #0a2974;
}
.colorBlue8,
#colorBlue8,
#colorBlue8 a,
.hasColorBlue8Span span,
.hasColorBlue8ATags a,
a.colorBlue8,
.colorBlue8 a {
  color: #051d58 !important;
}

.colorPurple {
  color: #7674BE;
}

.colorGreyTracker {
  color: #525252;
}

.contentfulMarkdown.hasColorGreenSpan span {
  color: #16d307;
}

/* Background Colors */
.backgroundColorClear {
  background-color: rgba(0, 0, 0, 0);
}

.backgroundColorWhite {
  background-color: #ffffff;
}

.backgroundColorNone {
  background-color: none;
}

.backgroundColorGrey1 {
  background-color: #dedddd;
}

.backgroundColorPink {
  background-color: #fda4a5; /* 1% same: #FBA4A6 */
}

.backgroundColorPeach1 {
  background-color: #f77478;
}
.backgroundColorPeach2 {
  background-color: #F75F64; /* same #f75f65, #f65f64 */
}
.backgroundColorPeach2Transparent {
  background-color: #F75F641A;
}
.backgroundColorPeach3 {
  background-color: #f85258;
}
.backgroundColorPeach4 {
  background-color: #f74a57;
}
.backgroundColorPeach5 {
  background-color: #f74a58;
}


.backgroundColorPeach6 {
  background-color: #f97d81;
}

.backgroundColorTransparent {
  background-color: transparent;
}

.backgroundColorTransparentBlue {
  background-color: rgba(0, 0, 0, 0.2);
}

.backgroundColorBlue1 {
  background-color: #b9c0ef;
}
.backgroundColorBlue2 {
  background-color: #6f8ed9;
}
.backgroundColorBlue2B {
  background-color: #6482ce;
}
.backgroundColorBlue3 {
  background-color: #5d7cc7;
}
.backgroundColorBlue4 {
  background-color: #476abf;
}
.backgroundColorBlue5 {
  background-color: #3156b2;
}
.backgroundColorBlue6 {
  background-color: #22469f; /* same #22479f */
}
.backgroundColorBlue7 {
  background-color: #0a2974; /* #0a2a74 */
}
.backgroundColorBlue8 {
  background-color: #051d58;
}
.backgroundColorBlue9 {
  background-color: #bac0ef;
}

.backgroundColorBlue10 {
  background-color: #4764af;
}

.backgroundColorBlueGrey1 {
  background-color: #818da9;
}

.backgroundColorPurple {
  background-color: #7674BE;
}

.backgroundColorDarkPurple {
  background-color: #4350E6;
}

.underline {
  text-decoration: underline;
}

.positionRelative {
  position: relative;
}

.positionAbsolute {
  position: absolute;
}

.positionFixed {
  position: fixed;
}

.positionStatic {
  position: static;
}

.positionInherit {
  position: inherit;
}

.positionSticky {
  position: sticky;
}

.absoluteTopLeft0 {
  top: 0;
  left: 0;
}

.absoluteTopRight0 {
  top: 0;
  right: 0;
}

.absoluteBottomLeft0 {
  bottom: 0;
  left: 0;
}

.zIndex50 {
  z-index: 50;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.flexWrap {
  flex-wrap: wrap;
}

.displayFlex {
  display: flex;
}

.flexRowReverse {
  display: flex;
  flex-direction: row-reverse;
}

.flexWrap {
  flex-wrap: wrap;
}

.displayFlex {
  display: flex;
}

.flexRowReverse {
  display: flex;
  flex-direction: row-reverse;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.flexGrow1 {
  flex-grow: 1;
}

.flexGrow2 {
  flex-grow: 2;
}

.flexWrap {
  flex-wrap: wrap;
}

.flexWrapReverse {
  flex-wrap: wrap-reverse;
}

.justifyStart {
  justify-content: flex-start;
}

.justifyCenter {
  justify-content: center;
}

.justifyBetween {
  justify-content: space-between;
}

.justifyEnd {
  justify-content: flex-end;
}

.justifySelfFlexEnd {
  justify-self: flex-end;
}

.justifySpaceAround {
  justify-content: space-around;
}

.justifySpaceEvenly {
  justify-content: space-evenly;
}

.c {
  align-items: flex-start;
}

.alignCenter {
  align-items: center;
}

.alignFlexEnd {
  align-items: flex-end;
}

.alignSelfFlexStart {
  align-self: flex-start;
}

.alignSelfCenter {
  align-self: center;
}

.alignSelfFlexEnd {
  align-self: flex-end;
}
.textCenterAlign {
  text-align: center;
  padding: 0 30px;
}
.textAlignCenter {
  text-align: center;
}

.textAlignLeft {
  text-align: left;
}

.textAlignRight {
  text-align: right;
}

/* Margin */
.marginAuto {
  margin: 0% auto;
}

.marginTop1em {
  margin-top: 1em;
}
.alignItemsCenter {
  align-items: center;
}
.marginTop2em {
  margin-top: 2em;
}

.marginTop8em {
  margin-top: 8em;
}

.marginTop14em {
  margin-top: 14em;
}

.marginTop18em {
  margin-top: 18em;
}

.marginTop20 {
  margin-top: 20px !important;
}

.marginTop19 {
  margin-top: 19px !important;
}



.positionRelativeRight7 {
  position: relative;
  right: 7px;
}

.marginTop30AutoBottom30Auto {
  margin: 30px auto 30px auto !important;
}

.marginTop75AutoBottom30Auto {
  margin: 75px auto 30px auto;
}
.marginTop90AutoBottom30Auto {
  margin: 90px auto 30px auto;
}

.marginLeftRightAuto {
  margin-left: auto;
  margin-right: auto;
}

.marginLeftRight25 {
  margin-left: 25px;
  margin-right: 25px;
}

.marginLeft100 {
  margin-left: 100px;
}

.marginLeft150 {
  margin-left: 150px;
}

.marginLeft170 {
  margin-left: 170px;
}

.marginLeft200 {
  margin-left: 200px;
}

.marginLeft225 {
  margin-left: 225px;
}

.marginLeft16Vw {
  margin-left: 16vw;
}

.marginLeft10Vw {
  margin-left: 10vw;
}

.marginLeft18Vw {
  margin-left: 18vw;
}

.marginLeft4 {
  margin-left: 4px;
}

.marginLeft14vw {
  margin-left: 14vw;
}

.marginLeft16 {
  margin-left: 16px;
}

.marginLeft50 {
  margin-left: 50px;
}

.marginLeft60 {
  margin-left: 60px;
}

.marginLeft70 {
  margin-left: 70px;
}

.marginLeft80 {
  margin-left: 80px;
}

.marginLeft90 {
  margin-left: 90px;
}

.marginLeft150 {
  margin-left: 150px;
}

.margin0 {
  margin: 0px;
}

.marginTopNegative18 {
  margin-top: -8px;
}
/* .marginTopNegative18 {
  margin-top: -18px;
} */

.marginTopNegative30 {
  margin-top: -30px;
}

.marginTopNegative45 {
  margin-top: -45px;
}

.marginTopNeg {
  margin-top: -50%;
}

.marginTopNeg100 {
  margin-top: -100px;
}

.marginTopNeg365 {
  margin-top: -365px;
}

.marginTop0 {
  margin-top: 0px;
}

.marginTop3 {
  margin-top: 3px;
}

.marginTop6 {
  margin-top: 6px;
}

.marginTop12 {
  margin-top: 12px;
}
.marginTop20 {
  margin-top: 20px;
}

.marginTop23 {
  margin-top: 23px;
}

.marginTop30 {
  margin-top: 30px;
}

.marginTop40 {
  margin-top: 40px;
}

.marginTop50 {
  margin-top: 50px;
}

.marginTop54 {
  margin-top: 54px;
}

.marginTop75 {
  margin-top: 75px !important;
}

.marginTop85 {
  margin-top: 85vw !important;
}

.marginTop95 {
  margin-top: 95px;
}

.marginTop135 {
  margin-top: 135px;
}

.marginTop150 {
  margin-top: 150px;
}

.marginTop170 {
  margin-top: 170px;
}

.marginTop180 {
  margin-top: 180px;
}

.marginTop190 {
  margin-top: 190px;
}

.marginTop200 {
  margin-top: 200px !important;
}

.marginTopBottom14 {
  margin-top: 14px;
  margin-bottom: 14px;
}

.marginTopBottom25 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.marginTop0Bottom50 {
  margin-top: 0px;
  margin-bottom: 50px;
}

.marginTop16Bottom14 {
  margin-top: 16px;
  margin-bottom: 14px;
}

.marginTop26Bottom36 {
  margin-top: 26px;
  margin-bottom: 36px;
}

.marginTopBottom50 {
  margin-top: 50px;
  margin-bottom: 50px;
}
.marginLeft40 {
  margin-left: 40px;
  margin-top: -10px;
}

.marginTopAuto {
  margin-top: auto;
}

.marginLeft150 {
  margin-left: 320px;
}

.marginRightNegative6-7rem {
  margin-right: -6.7rem;
}

.marginRightMinus6 {
  margin-right: -20px;
}

.marginRightAuto {
  margin-right: auto;
}

.marginRight2 {
  margin-right: 2px;
}

.marginRight4 {
  margin-right: 4px;
}

.marginRight5 {
  margin-right: 5px;
}

.marginRight8 {
  margin-right: 8px;
}

.marginRight10 {
  margin-right: 10px;
}

.marginRight15 {
  margin-right: 15px;
}

.marginRight17 {
  margin-right: 17px;
}

.marginRight20 {
  margin-right: 20px;
}

.marginRight25 {
  margin-right: 25px;
}

.marginBottomNegative20 {
  margin-bottom: -20px;
}

.marginBottomNegative150 {
  margin-bottom: -150px;
}

.marginBottomNegative200 {
  margin-bottom: -200px;
}

.marginBottom0 {
  margin-bottom: 0px;
}

.marginBottom2em {
  margin-bottom: 2em;
}

.marginBottom4 {
  margin-bottom: 4px;
}

.marginBottom4em {
  margin-bottom: 4em;
}

.marginBottom6em {
  margin-bottom: 6em;
}

.marginBottom8em {
  margin-bottom: 8em;
}

.marginBottom10em {
  margin-bottom: 10em;
}

.marginBottom5 {
  margin-bottom: 5px;
}

.marginBottom7 {
  margin-bottom: 7px;
}

.marginBottom8 {
  margin-bottom: 8px;
}

.marginBottom9 {
  margin-bottom: 9px;
}

.marginBottom10 {
  margin-bottom: 10px;
}

.marginBottom12 {
  margin-bottom: 12px;
}

.marginBottom13 {
  margin-bottom: 13px;
}

.marginBottom14 {
  margin-bottom: 14px;
}

.marginBottom15 {
  margin-bottom: 15px;
}

.marginBottom10em {
  margin-bottom: 10em;
}

.marginBottom15em {
  margin-bottom: 15em;
}

.marginBottom16 {
  margin-bottom: 16px;
}

.marginBottom18 {
  margin-bottom: 18px;
}

.marginBottom20 {
  margin-bottom: 20px;
}

.marginBottom21 {
  margin-bottom: 21px;
}

.marginBottom22 {
  margin-bottom: 22px;
}

.marginBottom24 {
  margin-bottom: 24px;
}

.marginBottom25 {
  margin-bottom: 25px;
}

.marginBottom26 {
  margin-bottom: 26px;
}

.marginBottom28 {
  margin-bottom: 28px;
}

.marginBottom30 {
  margin-bottom: 30px;
}

.marginBottom30Important {
  margin-bottom: 30px !important;
}

.marginBottom32 {
  margin-bottom: 32px;
}

.marginBottom36 {
  margin-bottom: 36px;
}

.marginBottom40 {
  margin-bottom: 40px;
}

.marginBottom45 {
  margin-bottom: 45px;
}

.marginBottom50 {
  margin-bottom: 50px;
}

.marginBottom60 {
  margin-bottom: 60px;
}

.marginBottom70 {
  margin-bottom: 70px !important;
}

.marginBottom74 {
  margin-bottom: 74px;
}

.marginBottom100 {
  margin-bottom: 100px !important;
}

.marginBottom5em {
  margin-bottom: 5em !important;
}

.marginBottom5vh {
  margin-bottom: 5vh;
}

.marginLeftNeg3Percent {
  margin-left: -3%;
}

.marginLeftMinus14 {
  margin-left: -14px;
}

.marginLeftNeg25Percent {
  margin-left: -25%;
}

.marginLeft10 {
  margin-left: 10px;
}

.marginLeft15 {
  margin-left: 15px;
}

.marginLeft20 {
  margin-left: 20px;
}

.marginLeft25 {
  margin-left: 25px;
}

.marginLeft30 {
  margin-left: 30px;
}

.marginLeft40 {
  margin-left: 40px;
}

.marginLeft50 {
  margin-left: 50px;
}

.marginLeft60 {
  margin-left: 60px;
}

.marginLeft65 {
  margin-left: 65px;
}

.marginLeftMinus100 {
  margin-left: -100px;
}

.margin20 {
  margin: 20px;
}

.margin20Percent {
  margin: 20%;
}

.margin21-0 {
  margin: 21px 0px;
}

.margin26-0-21 {
  margin: 26px 0px 21px;
}

.margin26-0 {
  margin: 26px 0px;
}

/* padding */
.paddingTop20PercentVw {
  padding-top: calc(100vw * 0.2);
}

.paddingTop40 {
  padding-top: 40px;
}

.paddingTop23PercentVw {
  padding-top: calc(100vw * 0.23);
}

.paddingTop24PercentVw {
  padding-top: calc(100vw * 0.24);
}

.paddingTopMinus10 {
  padding-top: -10px;
}
.paddingTop40PercentVw {
  padding-top: calc(100vw * 0.40);
}

.paddingTop45PercentVw {
  padding-top: calc(100vw * 0.45);
}

.paddingTop50PercentVw {
  padding-top: calc(100vw * 0.5);
}

.paddingTop60PercentVw {
  padding-top: calc(100vw * 0.6);
}

.paddingTop70PercentVw {
  padding-top: calc(100vw * 0.7);
}

.paddingTop15PercentVw {
  padding-top: calc(100vw * 0.15);
}

.paddingTop10PercentVw {
  padding-top: calc(100vw * 0.1);
}

.paddingTop3Rem {
  padding-top: 3rem;
}

.paddingTop7PercentVw {
  padding-top: calc(100vw * 0.07);
}

.paddingTop5PercentVw {
  padding-top: calc(100vw * 0.05);
}

.paddingTop0 {
  padding-top: 0px;
}

.paddingTop4 {
  padding-top: 4px;
}

.paddingTop5 {
  padding: 5px;
}

.paddingTop10 {
  padding: 10px;
}

.paddingTop0 {
  padding-top: 0px;
}

.paddingTop5 {
  padding-top: 5px;
}

.paddingTop10 {
  padding-top: 10px;
}
.paddingTop14 {
  padding-top: 14px;
}

.paddingTop16 {
  padding-top: 16px;
}

.paddingTop17 {
  padding-top: 17px;
}

.paddingTop20 {
  padding-top: 20px;
}

.paddingTop23 {
  padding-top: 23px;
}

.paddingTop25 {
  padding-top: 25px;
}

.paddingTop27 {
  padding-top: 27px;
}
.paddingTop30 {
  padding-top: 30px;
}

.paddingTop40 {
  padding-top: 40px;
}

.paddingTop46 {
  padding-top: 46px;
}

.paddingTop60 {
  padding-top: 60px;
}

.paddingTop65 {
  padding-top: 65px;
}

.paddingTop70 {
  padding-top: 70px;
}

.paddingTop75 {
  padding-top: 75px;
}

.paddingTop80 {
  padding-top: 80px;
}

.paddingTop85 {
  padding-top: 85px;
}

.paddingTop90 {
  padding-top: 90px;
}

.paddingTop93 {
  padding-top: 93px;
}

.paddingTop100 {
  padding-top: 100px;
}
.paddingTop110 {
  padding-top: 110px;
}

.paddingTop125 {
  padding-top: 125px;
}

.paddingTop150 {
  padding-top: 150px;
}


.paddingTopBottom5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.paddingTopBottom10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.paddingTopBottom10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.paddingTopBottom15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.paddingTopBottom20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.paddingTopBottom25 {
  padding-top: 25px;
  padding-bottom: 25px;
}

.paddingTopBottomAuto {
  padding-top: 25px;
  padding-bottom: 25px;
}

.paddingLeftRightAuto {
  padding-left: auto;
  padding-right: auto;
}

.paddingLeftRight5 {
  padding-left: 5px;
  padding-right: 5px;
}

.paddingLeftRight10 {
  padding-left: 10px;
  padding-right: 10px;
}

.paddingLeftRight15 {
  padding-left: 15px;
  padding-right: 15px;
}

.paddingLeftRight10 {
  padding-left: 10px;
  padding-right: 10px;
}

.paddingLeftRight16 {
  padding-left: 16px;
  padding-right: 16px;
}

.paddingLeftRight20 {
  padding-left: 20px;
  padding-right: 20px;
}

.paddingLeftRight25 {
  padding-left: 25px;
  padding-right: 25px;
}

.paddingLeftRight30 {
  padding-left: 30px;
  padding-right: 30px;
}

.paddingLeftRight32 {
  padding-left: 32px;
  padding-right: 32px;
}

.paddingLeftRight35 {
  padding-left: 35px;
  padding-right: 35px;
}

.paddingLeftRight40 {
  padding-left: 40px;
  padding-right: 40px;
}

.paddingLeftRight45 {
  padding-left: 45px;
  padding-right: 45px;
}

.paddingLeftRight54 {
  padding-left: 54px;
  padding-right: 54px;
}

.paddingLeftRight66 {
  padding-left: 66px;
  padding-right: 66px;
}

.paddingLeftRight85 {
  padding-left: 85px;
  padding-right: 85px;
}

.paddingleftrightauto {
  padding-left: auto;
  padding-right: auto;
}

.paddingRight10 {
  padding-right: 10px;
}

.paddingRight15 {
  padding-right: 15px;
}

.paddingRight20 {
  padding-right: 20px;
}

.paddingRight20 {
  padding-right: 20px;
}

.paddingRight23 {
  padding-right: 23px;
}

.paddingRight30 {
  padding-right: 30px;
}

.paddingRight25 {
  padding-right: 25px;
}

.paddingRight30 {
  padding-right: 30px;
}

.paddingRight36 {
  padding-right: 36px;
}


.paddingRight40 {
  padding-right: 40px;
}

.paddingRight45 {
  padding-right: 45px;
}

.paddingRight50 {
  padding-right: 50px;
}

.paddingRight80 {
  padding: 5px 80px 20px 0px;
}

.paddingBottomNegative50 {
  padding-bottom: -50px;
}

.paddingBottom0 {
  padding-bottom: 0px;
}

.paddingBottom5 {
  padding-bottom: 5px;
}
.paddingRight-20 {
  padding-right: -20px !important;
}

.paddingBottom0 {
  padding-bottom: 0px;
}

.paddingBottom1 {
  padding-bottom: 1px;
}

.paddingBottom7 {
  padding-bottom: 7px;
}

.paddingBottom10 {
  padding-bottom: 10px;
}

.paddingBottom13 {
  padding-bottom: 13px;
}

.paddingBottom18 {
  padding-bottom: 18px;
}

.paddingBottom20 {
  padding-bottom: 20px;
}

.paddingBottom20Important {
  padding-bottom: 2px !important;
}

.paddingBottom23Percent {
  padding-bottom: 23%;
}

.paddingBottom25 {
  padding-bottom: 25px;
}

.paddingBottom35 {
  padding-bottom: 35px;
}

.paddingBottom40 {
  padding-bottom: 40px;
}

.paddingBottom40Important {
  padding-bottom: 40px !important;
}

.paddingBottom43 {
  padding-bottom: 43px;
}

.paddingBottom45 {
  padding-bottom: 45px;
}

.paddingBottom64 {
  padding-bottom: 64px;
}

.paddingLeft5 {
  padding-left: 5px;
}

.paddingLeft-10 {
  padding-left: -10px;
}

.paddingLeft10 {
  padding-left: 10px;
}

.paddingLeft15 {
  padding-left: 15px;
}

.paddingLeft5 {
  padding-left: 5px;
}

.paddingLeft-10 {
  padding-left: -10px;
}

.paddingLeft10 {
  padding-left: 10px;
}

.paddingLeft15 {
  padding-left: 15px;
}

.paddingLeft18 {
  padding-left: 18px;
}

.paddingLeft20 {
  padding-left: 20px;
}

.paddingLeft23 {
  padding-left: 23px;
}

.paddingLeft25 {
  padding-left: 25px;
}

.paddingLeft36 {
  padding-left: 36px;
}

.paddingLeft40 {
  padding-left: 40px;
}

.paddingLeft45 {
  padding-left: 45px;
}

.paddingLeft50 {
  padding-left: 50px;
}

.paddingLeft60 {
  padding-left: 60px;
}

.paddingLeft70 {
  padding-left: 70px;
}

.paddingLeft80 {
  padding-left: 80px;
}

.paddingLeft100 {
  padding-left: 100px;
}

.paddingLeft120 {
  padding-left: 120px;
}

.paddingLeft130 {
  padding-left: 130px;
}

.paddingLeft150 {
  padding-left: 150px;
}

.padding0-15 {
  padding: 0px 15px;
}

.padding0-0 {
  padding: 0px 0px;
}

.padding0-20-30 {
  padding: 0px 20px 30px;
}

.padding0-25-25 {
  padding: 0px 25px 25px;
}

.padding0-25-14-45 {
  padding: 0px 25px 14px 45px;
}

.padding0-30-57 {
  padding: 0px 30px 57px;
}

.padding0-36-70 {
  padding: 0px 36px 70px;
}

.padding10-0 {
  padding: 10px 0px;
}

.padding10-20-0 {
  padding: 10px 20px 0px;
}

.padding11-0-62 {
  padding: 11px 0px 62px;
}

.padding17-43-20 {
  padding: 17px 43px 20px;
}
.textWidth45 {
  padding: 17px 25px;
  /* align-self: center; */
  /* width: 45%; */
}

.padding19-30-20 {
  padding: 19px 30px 20px;
}

.padding20 {
  padding: 20px;
}

.padding10 {
  padding: 10px;
}

.padding20-25 {
  padding: 20px 25px;
}

.padding21-0 {
  padding: 21px 0px;
}

.padding24-30-36-25 {
  padding-top: 24px;
  padding-right: 30px;
  padding-bottom: 36px;
  padding-left: 25px;
}

.padding26-0-21 {
  padding: 26px 0px 21px;
}

.padding27-30-70 {
  padding: 27px 30px 70px;
}

.padding28-28-0 {
  padding: 28px 28px 0px;
}

.padding28-39-25-46 {
  padding: 28px 39px 25px 46px;
}

.padding30-34 {
  padding: 30px 34px;
}

.padding30-35-7 {
  padding: 30px 35px 7px;
}

.padding8vh-0-3vh {
  padding: 8vh 0vh 3vh;
}

.padding36-15-48 {
  padding: 36px 15px 48px;
}

.padding37-30-34 {
  padding: 37px 30px 34px;
}

.padding40-0 {
  padding: 40px 0px;
}

.padding40-38 {
  padding: 40px 38px;
}

.padding40-40-36 {
  padding: 40px 40px 36px;
}

.padding56-18 {
  padding: 56px 18px;
}

.padding56-21-30-34 {
  padding: 56px 21px 30px 34px;
}

.padding65-35-35 {
  padding: 65px 35px 35px;
}

.padding90-30-62 {
  padding: 90px 30px 62px;
}

.padding90-0-62 {
  padding: 90px 0px 62px;
}

.padding80-30-62-46 {
  padding: 80px 30px 62px 46px;
}

.padding90-30-72 {
  padding: 90px 30px 72px;
}

.padding90-30-62 {
  padding: 90px 30px 62px;
}

.paddingLeft20Percent {
  padding-left: 20%;
}




/* div width and heights */
.fitContentWidth {
  width: fit-content;
}

.widthAuto {
  width: auto;
}

.width8-4Percent {
  width: 8.4%;
}

.width20Percent {
  width: 20%;
}

.width10Percent {
  width: 10%;
}

.width22Percent {
  width: 22%;
}

.width28Percent {
  width: 28%;
}

.width33Percent {
  width: 33%;
}

.width38Percent {
  width: 38%;
}

.width45Percent {
  width: 45%;
}

.width50Percent {
  width: 50%;
}

.width55Percent {
  width: 55%;
}

.width62Percent {
  width: 62%;
}

.width65Percent {
  width: 65%;
}

.width71Percent {
  width: 71%;
}

.width74Percent {
  width: 74%;
}

.width75Percent {
  width: 75%;
}

.width78Percent {
  width: 78%;
}

.width81Percent {
  width: 81%;
}

.width83Percent {
  width: 83%;
}

.width88Percent {
  width: 88%;
}

.width90Percent {
  width: 90%;
}

.width91Percent {
  width: 91%;
}

.width93Percent {
  width: 93%;
}

.width96Percent {
  width: 96%;
}

.width100Percent {
  width: 100%;
}

.width103Percent {
  width: 103%;
}

.width115Percent {
  width: 115%;
}

.width120Percent {
  width: 120%;
}

.width250Percent {
  width: 250%;
}

.width100HeightAuto {
  width: 100%;
  height: auto;
}

.widthAndHeight-100 {
  width: 100%;
  height: 100%;
}

.widthAndHeight-90 {
  width: 90%;
  height: 90%;
}

.widthAndHeight-80 {
  width: 80%;
  height: 80%;
}

.widthAndHeight-50 {
  width: 50%;
  height: 50%;
}

.width64vw {
  width: 64vw;
}

.width30Percent {
  width: 30%;
}

.width36Percent {
  width: 36%;
}

.width46Percent {
  width: 46%;
}

.width49Percent {
  width: 49%;
}

.width55Percent {
  width: 55%;
}

.width62Percent {
  width: 62%;
}

.width64Percent {
  width: 64%;
}

.width67Percent {
  width: 67%;
}

.width69Percent {
  width: 69%;
}

.width70Percent {
  width: 70%;
}

.width74Percent {
  width: 74%;
}

.width76Percent {
  width: 76%;
}

.width80Percent {
  width: 80%;
}

.width83-4Percent {
  width: 83.4%;
}

.width85Percent {
  width: 85%;
}

.width23 {
  width: 23px;
}

.width25 {
  width: 25px;
}

.width38 {
  width: 38px;
}

.width40 {
  width: 40px;
}

.width14Height14 {
  width: 14px;
  height: 14px;
}

.width16Height16 {
  width: 16px;
  height: 16px;
}

.width30Height30 {
  width: 30px;
  height: 30px;
}

.widthHeight35 {
  width: 35px;
  height: 35px;
}

.widthHeight40 {
  width: 40px;
  height: 40px;
}

.width50Height50 {
  width: 50px;
  height: 50px;
}

.width52Height52 {
  width: 52px;
  height: 52px;
}

.widthHeight17 {
  width: 17px;
  height: 17px;
}

.widthHeight50 {
  width: 50px;
  height: 50px;
}

.widthHeight60 {
  width: 60px;
  height: 60px;
}

.widthHeight63 {
  width: 63px;
  height: 63px;
}

.widthHeight100 {
  width: 100px;
  height: 100px;
}
.width230Height34 {
  width: 230px;
  height: 34px;
}

.width90Percent {
  width: 90%;
}

.maxWidth90Percent {
  max-width: 90%;
}

.maxWidth275 {
  max-width: 275px;
}

.maxWidth315 {
  max-width: 315px;
}

.heightAuto {
  height: auto;
}

.height110vh {
  height: 110vh;
}

.height1000 {
  height: 1000px
}
.minHeight100Percent {
  min-height: 100%;
}

.minHeight100vw {
  min-height: 100vw;
}

.height100vw {
  height: 100vw;
}

.height100Percent {
  height: 100%;
}

.height23 {
  height: 23px;
}

.height25 {
  height: 25px;
}

.height35 {
  height: 35px;
}

.height38 {
  height: 38px;
}

.height50 {
  height: 50px;
}

.height60 {
  height: 60px;
}

.height100 {
  height: 100px;
}

.height300 {
  height: 300px;
}

.height30 {
  height: 30px;
}

.height25 {
  height: 25px;
}

.height20 {
  height: 20px;
}

.maxHeight45 {
  max-height: 45px;
}

.maxHeight100vw {
  max-height: 100vw;
}

.maxHeight120 {
  max-height: 120px;
}

/* background image width and heights */
.backgroundImgSizeCover {
  background-size: cover;
}

.backgroundImgW100HAuto {
  background-size: 100% auto;
}

.backgroundImgWAutoH100 {
  background-size: auto 100%;
}

.backgroundImgWAutoH77Percent {
  background-size: auto 77%;
}

.backgroundImgWAutoH81Percent {
  background-size: auto 81%;
}

.backgroundImgXYCenter {
  background-position: center;
}

.backgroundImgXCenter {
  background-position-x: center;
}

.backgroundImgX45Percent {
  background-position-x: 45%;
}

.backgroundImgXRightYTop {
  background-position-x: right;
  background-position-y: top;
}

.backgroundXCenterYBottom {
  background-position-x: center;
  background-position-y: bottom;
}

.backgroundImgXLeft {
  background-position-x: left;
}

.backgroundImgYBottom {
  background-position-y: bottom;
}

.backgroundImgY105Percent {
  background-position-y: 105%;
}

.dontRepeatImage {
  background-repeat: no-repeat;
}

.opacity0 {
  opacity: 0;
}

.blue8BoxShadow {
  box-shadow: 2.5px 0px 10px #051d58a6;
}

.borderRadius0 {
  border-radius: 0px;
}

.borderRadius20 {
  border-radius: 20px;
}

.borderRadius9 {
  border-radius: 9px;
}

.borderRadius3rem {
  border-radius: 3rem;
}

.borderRadius50Percent {
  border-radius: 50%;
}

.borderNone {
  border: none;
}

/* Reusable components */
.smallLogo {
  width: 40px;
  height: 40px;
}

.largeButtonContainer {
  text-align: center;
  
}

.largeButton {
  min-width: 64vw;
  margin-top: 1em;
  /* padding: 9px 20px 9px; */
  border-radius: 2rem; /* 17px */
  color: white;
  padding: 0.75em;
}

.largeButtonIcon {
  min-width: 95%;
  padding: 15px 2px 15px;
  border-radius: 4rem; /* 17px */
  color: white;
  margin: auto;
  max-height: 78px; 
}

.widthMatchLargeButton {
  width: 64vw;
}

/* Dashboard for monitor page */
.datesWrapper {
  display: flex;
  justify-content: space-between;
  /* width: 80%; */
  margin-top: 38px;
  overflow: scroll;
  overflow-x: auto;
}

.datesWrapper::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.numOfMonth {
  color: #484848;
  text-align: center;
  font-family: "GT Walsheim Pro";
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 17px;
}

.dayOfWeek {
  color: #484848;
  text-align: center;
  font-family: "GT Walsheim Pro";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.datesWrapper div {
  margin: 5px;
  /* border: 1px solid #051d58; */
  border-radius: 56px;
  padding: 10px;
  max-height: 76px;
  min-width: 44px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.trackerCalendarIconNew {
  position: absolute;
  right: 50px;
  left: auto;
}


.crisp-client .cc-tlyw .cc-kxkl .cc-nsge {
  z-index: 2 !important;
  display: block !important;
  bottom: 137px !important;
  border-radius: 100% !important;
}

.today {
  background-color: blue;
  margin: 5px;
  border-radius: 56px;
  padding: 10px;
  max-height: 76px;
  min-width: 44px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.today-text {
  text-align: center;
  width: 100%;
}

.dateContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.monitored-days {
  background-color: #ccd1f4;
  margin: 5px;
  border-radius: 56px;
  padding: 10px;
  max-height: 76px;
  min-width: 44px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
/* .todayActive {
  border: 2px solid #f74a56;
} */

.todayActive {
  border-radius: 15px;
  border: 1.5px solid #222EB9;
}


.active1 {
  border: 2px solid #768bc3;
}

.active-first {
  border: 2px solid #f74a56;
  background-color: #FDE8D8;
}

.other-days {
  background-color: #eeeeee;
  margin: 5px;
  border-radius: 56px;
  padding: 10px;
  max-height: 76px;
  min-width: 44px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.first-day {
  background-color: "#FDE8D8";
  margin: 5px;
  border-radius: 56px;
  padding: 10px;
  max-height: 76px;
  min-width: 44px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.days-moitored {
  background-color: "#DAF0FF";
}

.first-pill-date {
  background-color: #768bc3;
  color: #fff !important;
}

.calandarImageWrapper {
  display: flex;
  justify-content: flex-end;
  margin-right: 1.3em;
}

.monthWrapper {
  margin-bottom: -49px;
}

.specialText > :first-child {
  width: 50%;
  /* margin: auto; */
  text-align: center;
  margin: 30px auto;
}

.x-close-button {
  color: white;
  top: 50px;
  left: 80px;
  z-index: 100;
}

.mui-slider-wrapper .MuiSlider-markLabel {
  color: white;
}

.active-emotion-normal img {
  border: 2px solid white !important;
}

.MuiSlider-thumb {
  background-image: url("./Images/right-left-arrows.svg");
  background-size: cover;
  width: 25px;
  height: 25px;
}

.what-to-monitor {
  width: 90%;
}

.what-to-monitor img {
  position: relative;
  bottom: 100px;
  margin-top: 91px;
  width: 62%;
}

.leave-button-main-page button {
  max-width: 80vw;
  min-width: 80vw;
  padding: 1em;
}

.leave-button-main-page span {
  font-size: 20px;
}

.leave-button-why-monitor-page button {
  max-width: 80vw;
  min-width: 60vw;
  padding: 1em;
}

@media screen and (min-device-width: 600px) and (max-device-width: 2000px) {
  .leave-button-why-monitor-page button {
    min-width: 20vw !important;
    max-width: 20vw !important;
  }
  }

/* .leave-button-wrapper {
  margin-bottom: 15em;
} */

.leave-button-wrapper button {
  width: 70%;
  padding: 1em;
 
}

.leave-button-wrapper span {
  font-size: 20px;
}

.leave-button-wrapper {
  margin-top: 4em;
}

.leave-button-monitor-descriptions button {
  margin-bottom: 4em;
}
/* .bleeding-form-wrapper {
  width: 80%;
  margin: auto;
} */

.bleeding-form-wrapper {
  width: 90%;
  margin: auto;
}

.tracker-dashboard-main-img {
  position: relative;
  top: 3px;
}

.pregSymptomAdviceText {
  width: 75%;
  margin: auto;
  margin-top: 3em;
}
.text-wrapper-range {
  width: 75%;
  margin: auto;
}

.description-see-progress img {
  display: flex;
  justify-content: center;
}

.rec-hero-wrapper img {
  height: 200px;
  margin-bottom: 2em;
}


.rec-hero-wrapper-no-margin img { 
  height: 200px;
  margin-bottom: -25px;
  margin-top: 10px;
}

.rec-hero-wrapper-no-margin2 img { 
  height: 200px;
  margin-bottom: -15px;
  margin-top: 10px;
}

.recommendation-text {
  color: #525252 !important;
}

@media screen and (min-device-width: 250px) and (max-device-width: 350px) {
  .recommendation-text {
    font-size: 13px;
  }
  }

.recommendation-vertical-align {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* .leave-button-recs {
  margin-top: 2em;
} */

.rec-button-more {
  margin-top: 4em;
  /* width: 45%; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* margin-left: 5em; */
}

/* @media screen and (min-device-width: 250px) and (max-device-width: 350px) {
  .rec-button-more {
    width: 20%;
    margin-right: 8em;
  }
  } */

/* .progress-page {
  margin-top: 0em;
}

.progress-page1 {
  margin-top: 3em;
} */

.top-nav-popup {
  margin-top: 3em;
}

.topnav-small-screens {
  padding-top: 0;
} 

@media screen and (min-device-width: 250px) and (max-device-width: 759px) {
  .topnav-small-screens{
    top: 0;
    left: 0;
    
  }
  }
  


.monitorPage .top-nav-popup {
  margin-top: 0em;
}

.leave-button-recs button {
  width: 100%;
  padding: 1em;
}

.leave-button-recs span {
  font-size: 18px;
}

/* .monitor-page {
  height: 160vh !important;
} */

.monitor-button-dashboard button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.monitor-button-dashboard span {
  font-size: 24 !important;
}

.monitor-button-dashboard img {
  height: 55px;
}

.see-progress-from-recs-button {
  /* display: flex;
  justify-content: center; */
  margin-bottom: 8em;
  /* margin-left: 20px !important; */
  /* padding-left: 30px !important; */
}

.bottom-main-image {
  padding-bottom: 1em;
  margin-bottom: 15em;
}

.blue-recs-text p {
  color: #0a2974;
}

.align-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot-animation:after {
  content: ".";
  animation: dot-pulse 1.5s infinite;
}

@keyframes dot-pulse {
  0% {
    color: transparent;
  }
  50% {
    color: #000;
  }
  100% {
    color: transparent;
  }
}


.enter-app-button button {
  padding: 1em;
}

/* Sqaure Button with images from the symptom tracker */

.sqaureButton {
  /* padding: 9px 20px 9px; */
  border-radius: 2rem;
  color: white;
}
.why-monitor-symptom-drawer {
  border-top-right-radius: 100px;
  border-top-left-radius: 100px;
  width: 100%;
  color: white;
  padding: 2em;
  height: 2500px;
}

.step4 {
  position: relative;
  bottom: 460px;
}
.step5 {
  position: relative;
  bottom: 555px;
}

.why-monitor-progress-graphic {
  width: 45%;
  margin: auto;
  position: relative;
  bottom: 190px;
}

/* .why-monitor-progress-graphic {
  bottom: 210px;
} */

/* .why-monitor-progress-graphic {
  bottom: 279px;
  width: 50%;
} */

.recs-graphic {
  width: 60%;
  margin: auto;
  position: relative;
  bottom: 430px;
}

.chat-graphic {
  width: 18%;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 30px;
  position: relative;
  bottom: 331px;
}

/* .chat-graphic {
  bottom: 415px;
  width: 26%;
} */

/* .chat-graphic {
  bottom: 331px;
  width: 28%;
} */

.leave-button {
  width: 30%;
  margin: auto;
  position: relative;
  /* bottom: 130px; */
  bottom: 400px;
  display: flex;
  justify-content: center;
}

.volver-button-why-monitor {
  position: absolute;
  bottom: 300px; /* Adjust this value as needed */
  left: 50%;
  transform: translateX(-50%);
  justify-content: center;
  /* margin-bottom: 100px; */
 /*  margin-top: -20px; */
}

.example-monitor-button {
  width: 75%;
  position: relative;
  bottom: 120px;
  margin: auto;
}

.number-graphic-why-monitor img {
  height: 70px;
}

@media screen and (min-device-width: 250px) and (max-device-width: 350px) {
  .number-graphic-why-monitor img {
    height: 40px;
  }
  }

.number2 {
  position: relative;
  top: 45px;
}

.rightArrowWhyMonitor {
  position: relative;
  right: 180px;
  bottom: 100px;
}

.goToProgressButton {
  color: white;
  padding: 0.5em 3em 0.5em 3em;
  border-radius: 30px;
  /* pointer-events: none; */
}

.recCardWrapper {
  display: flex;
  text-align: center;
  justify-content: center;
  height: 113px;
  max-width: 100%;/*this is the width of the recommendation boxes in recomendaciones menu*/
  cursor: pointer;
  flex: 1 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (min-device-width: 250px) and (max-device-width: 399px) {
  .recCardWrapper img {
    max-width: 90%;
    padding-left: 0px;
    padding-right: 0px;
  }

  }


  .center-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .emotionRecText3 {
    text-align: left;
    
  }

  .center-image-rec-emotion {
    display: block;
    margin: auto;
  }
/* .recCardWrapper img {
  height: 110px;
  
  width: 115%;
 
 margin-left: -10px;
 margin-top: 3px;
  
} */

.recCardWrapperBleedingImg {
  padding-bottom: 10px !important;
}

.recCardWrapperPainImg {
  padding-bottom: 14px !important;
  padding-right: 5px;
}

.recCardWrapperBlueWidth {
  width: 23%;
}

/* .recCardWrapperBig {
  height: 113px;
  width: 291px;
} */

/* .recCardWrapperImgLow {
  height: 165px !important;
} */

.recCardWrapper p {
  font-size: 18px;
}

.recCardWrapper .painRecTitle {
  margin-top: 20px;
}

/* .recCardWrapper img {
  max-height: 150px;
  max-width: 150px;
} */

/* .recCardWrapper div {
  z-index: 1;
} */

/* .recCardWrapperBox {
  max-height: 120px;
  max-width: 125px;
} */

/* .recCardWrapperImgBig {
  width: 200% !important;
} */

/* .recPainImgCard {
  width: 120% !important;
  
} */

.recommendation-snippet-wrapper {
  /* text-align: left; */
  /* text-align: center; */
  height: 113px;
  width: 230px;
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
}

.recommendation-snippet {
  /* height: 50px !important; */
 /*  z-index: 100; */
  /* text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; */
  text-align: left;
  /* padding-top: 10px;
  padding-bottom: 10px; */
  /* font-size: 25px; */
  padding-left: 20px;
}

.recommendation-snippet p {
  margin-bottom: none;
  /* margin-top: 20px; */
  padding-top: 10px;
}



/* @media (min-width: 400px) and (max-width: 767.98px) { 
.arrow 
{
  position: absolute;
  top: 134px;
  z-index: 1;
  left: 250px;
}
} */

.sqaureButtonText {
  font-size: 14px;
  min-height: 65px;
  border-bottom-right-radius: 2rem;
  border-bottom-left-radius: 2rem;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.sqaureButtonText p {
  position: relative;
  top: 10px;
}

.saveSymptomButton {
  background-color: #f74a56;
  color: white;
  border-radius: 50px;
  width: 60%;
  padding: 10px;
  margin-top: 50px;
  margin-bottom: 10em;
  font-family: "GTWalsheimPro-Bold", sans-serif;
  font-size: large;
}

.moreRecsButtonSeeProgressPage {
  background-color: #3156b2;
  color: white;
  border-radius: 50px;
  width: 90%;
  padding: 10px;
  margin-top: 10px;
  
  font-family: "GTWalsheimPro-Bold", sans-serif;
  font-size: large;
}

.moreRecsButtonSeeProgressPagePeach {
  background-color: #FF5D69;
  color: white;
  border-radius: 50px;
  width: 90%;
  padding: 10px;
  margin-top: 10px;
  
  font-family: "GTWalsheimPro-Bold", sans-serif;
  font-size: large;
}

/* Owl Carousel */

.positionAbsoluteZIndex {
  position: absolute;
  z-index: 1;
}


.slide-1 {
  position: absolute;
  z-index: 1;
  bottom: 60vw;
  margin-bottom: 1rem;
  left: 17vw
}

.slide-2 {
  position: absolute;
  z-index: 1;
  bottom: 30vw;
  margin-bottom: 1rem;
  left: 17vw
}

/* .slide-3 {
  position: absolute;
  z-index: 1;
  bottom: 10vw;
  margin-bottom: 35vw;
  left: 10vw;
  right: 8vw;
} */

.slide-4 {
  position: absolute;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-size: 12px;
  line-height: 14px;
  z-index: 1;
  top: 2.5rem;
  width: 100%;
  left: 1.5rem;

}

 @media screen and (min-width: 310px){.slide-4{
  font-size: 16px;
  line-height: 18px;
  top: 3rem;
  left: 1.5rem;
}}

@media screen and (min-width: 360px){.slide-4{
  font-size: 18px;
  line-height: 20px;
  top: 4.5rem;
  left: 2rem;
}}

@media screen and (min-width: 390px){.slide-4{
  font-size: 22px;
  top: 4rem;
  line-height: 24px;
}}
@media screen and (min-width: 400px){.slide-4{
  font-size: 24px;
  top: 5rem;
  line-height: 26px;
}}

@media screen and (min-width: 500px){.slide-4{
  font-size: 32px;
  top: 8rem;
  left: 3rem;
  line-height: 32px;
}}

@media screen and (min-width: 760px){.slide-4{
  font-size: 38px;
  top: 9rem;
  left: 3.5rem;
  line-height: 38px;
}}

.slide-5 {
  position: absolute;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-size: 12px;
  /* line-height: 1rem !important; */
  z-index: 1;
  top: 2.5rem;
  width: 100%;
  left: 1.5rem;

}

.slide-5 p {
  line-height: 1.5rem !important;
}

 @media screen and (min-width: 310px){.slide-5{
  font-size: 16px;
  /* line-height: 16px; */
  top: 3rem;
  left: 1.5rem;
}}

@media screen and (min-width: 360px){.slide-5{
  font-size: 18px;
  /* line-height: 18px; */
  top: 4.5rem;
  left: 2rem;
}}

@media screen and (min-width: 390px){.slide-5{
  font-size: 22px;
  top: 4rem;
  /* line-height: 22px; */
}}
@media screen and (min-width: 400px){.slide-5{
  font-size: 24px;
  top: 5rem;
  /* line-height: 24px; */
}}

@media screen and (min-width: 500px){.slide-5{
  font-size: 32px;
  top: 8rem;
  left: 3rem;
  /* line-height: 30px; */
}}

@media screen and (min-width: 760px){.slide-5{
  font-size: 38px;
  top: 7rem;
  left: 3.5rem;
  /* line-height: 36px; */
}}

@media screen and (min-width: 760px){.slide-5 p{
  
  line-height: 3rem !important;
}}


/* .coral-quotes {
  position: absolute;
  left: 17vw;
  bottom: 86vw;
  width: 27vw;
  height: 28vw;
  z-index: 50;
} */


.moreRecsButtonSeeProgressPagePeach svg {
  position: relative;
  right: 10px;
}

.moreRecsButtonSeeProgressPage svg {
  position: relative;
  right: 10px;
}

/* .learnMoreSymptomButton {
  color: white;
  border-radius: 50px;
  margin-top: 2em !important;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-right: 30px;
  padding-left: 30px;
  margin-top: 20px;
  font-family: "GTWalsheimPro-Bold", sans-serif;
  font-size: large;
  display: flex;
  align-items: center;
  justify-content: space-around;
} */
.learnMoreSymptomButton p {
  margin-bottom: 0px;
}

.learnMoreSymptomButton svg,
.painSymptomButton svg {
  height: 30px;
  width: 30px;
  margin-left: 5px;
}

.learnMoreSymptomButton {
  position: relative;
}

.learnMoreSymptomButton {
text-align: center;
font-family: Inter;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 19px; /* 146.154% */
text-decoration-line: underline !important;
text-decoration-style: solid !important;
text-decoration-skip-ink: none !important;
text-decoration-thickness: auto !important;
text-underline-offset: auto !important;
text-underline-position: from-font;
margin-top: 20px;
}

.learnMoreEmotionColor {
  color: #6D79E1;
}

.learnMoreBleedingColor {
  color: #FF6A7B;
}

.learnMorePregSymptomsColor {
  color: #FA720D;
}

.learnMorePainColor {
  color: #259FF4;
}

.painSymptomButton {
  background-color: #6482ce;
  color: white;
  border-radius: 50px;
  width: 28%;
  /* padding: 8px; */
  padding-bottom: 10px;
  padding-top: 10px;
  padding-right: 0px;
  padding-left: 0px;
  margin-top: 20px;
  font-family: "GTWalsheimPro-Bold", sans-serif;
  font-size: large;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.seeMyProgressButton {
  font-size: 18px;
  text-decoration: underline;
  color: #051d58;
}

.bleedingSymptomForm div {
  margin-bottom: 15px;
  font-size: 15px;
  text-align: left;
  display: flex;
  align-items: center;
}

/* Create a custom radio button */
.radio-custom {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border: 1px solid white;
  border-radius: 50%;
  background-color: transparent;
  vertical-align: middle;
  position: relative;
}

.radio-input:checked + .radio-label::before {
  background: radial-gradient(circle closest-side, #F85258 65%, transparent 65%);
  border: 2px solid white;
}

.radio-label::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border: 2px solid white;
  border-radius: 50%;
  background-color: transparent;
}

.radio-label {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  margin-right: 10px;
  cursor: pointer;
}

.radio-text {
  display: inline-block;
  vertical-align: middle;
  max-width: 150px; /* Adjust this as needed */
  margin-left: 10px; /* Space between image and text */
  
}

/* .bleedingSymptomForm input {
  height: 25px;
  width: 25px;
  background-color: pink;
  margin-right: 10px;
} */

.bleedingSymptomForm label {
  margin-left: 15px;
  width: 70%;
}
.bleedingSymptomForm p {
  margin-top: 5em;
}


/* Hide the default radio button */
.radio-input {
  visibility: hidden;
  position: absolute;
}

/* @media screen and (min-device-width: 250px) and (max-device-width: 399px) {
  .graphwrapper {
    max-width: 30%;
  }
  } */

/* input[type="radio"] + img + label:before {
  content: '';
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 10px;
  border: 2px solid white;
  border-radius: 50%;
  background-color: transparent;
  vertical-align: middle;
}


input[type="radio"]:checked + img + label:before {
  background-color: transparent;
  border: 2px solid white;
  box-shadow: inset 0 0 0 6px #FF8384; 
} */


.sqaureButtonWrapper {
  min-width: 100%;
  max-width: 100%;
  min-height: 170px;
  max-height: 170px;
  padding-top: 30px;
  border-radius: 1.75rem;
  color: white;
  /* margin-top: 20px; */
}


/* this is the div of every single square box in monitor dashboard*/
.sqaureButtonWrapperParent {
  /* width: 43%; */
  width: 43%;
  min-height: 130px;
  /* margin: auto; */
  /* padding-right: 0.2em; */
  padding-left: 0.5em;
  margin-left: 0.5em;
  margin-top: 1em;
}

/* .sqaureButtonImg {
 
 
} */

.squareButtonImgLarger {
  /* height: 80px; */
  width: 117%;
}

/* needs to be adapted again, thw arrow is too far right atm*/
.arrow {
  position: absolute;
  top: calc(100% - 36px);
  left: calc(100% - 36px);
  z-index: 1;
}


@media screen and (min-device-width: 394px) and (max-device-width: 539px) {
.arrow {
  position: absolute;
  top: 134px;
  z-index: 1;
  left: 170px;
}
}

@media screen and (min-device-width: 279px) and (max-width: 393px) {
  .arrow {
    left: 100px;
    height: 16px;
  } 

}

@media only screen and (min-width: 279px) and (max-width: 393px) {

  .symptomButtonsWrapper2 > div {
    font-size: 12px
  }

  /* .symptomButtonsWrapper2 > div:nth-child(odd) {
    margin-right: 8px;
    
  } */

  .boldFont.fontSize14 {
    font-size: 9px; /* You can adjust the font size as needed */
  }
}

/* .circleSymptomTrackerButton {
  position: relative;
  width: 100px;
  height: 100px;
  z-index: 50;
  display: flex;
  padding: 1.5em;
  align-items: center;
  justify-content: center;
  top: 0;
}

.circleSymptomTrackerButtonActive {
  position: relative;
  width: 100px;
  height: 100px;
  z-index: 50;
  border: 5px solid white;
  border-style: double;
  display: flex;
  padding: 1.5em;
  align-items: center;
  justify-content: center;
  top: 0;
} */

/* .circleSymptomTrackerButton {
  position: relative;
  width: 100px;
  height: 100px;
  z-index: 50;
  display: flex;
  padding: 1.5em;
  align-items: center;
  justify-content: center;
  top: 0;
  box-sizing: border-box; 
} */

/* .circleSymptomTrackerButtonActive {
  position: relative;
  width: 100px;
  height: 100px;
  z-index: 50;
  border: 5px solid red;
  border-style: double;
  display: flex;
  padding: 1.5em; 
  align-items: center;
  justify-content: center;
  top: 0;
  box-sizing: border-box;
}
 */

/* .circleSymptomTrackerButton img {
  position: relative;
}

.circleSymptomTrackerButton {
  width: 70px;
  height: 70px;
}
.circleSymptomTrackerButtonActive {
  width: 70px;
  height: 70px;
} */

/* .circleSymptomTrackerButton {
  position: relative;
  width: 75px;
  height: 75px;
  z-index: 50;
  display: flex;
  padding: 1.5em;
  align-items: center;
  justify-content: center;
  top: 0;
  box-sizing: border-box;
}

.circleSymptomTrackerButtonActiveBlue {
  position: relative;
  width: 75px;
  height: 75px;
  z-index: 50;
  border: 2px solid #6382CE;
  display: flex;
  padding: 1.5em;
  align-items: center;
  justify-content: center;
  top: 0;
  box-sizing: border-box;
}

.circleSymptomTrackerButtonActiveDarkPeach {
  position: relative;
  width: 75px;
  height: 75px;
  z-index: 50;
  border: 2px solid #F85258;
  display: flex;
  padding: 1.5em;
  align-items: center;
  justify-content: center;
  top: 0;
  box-sizing: border-box;
}

.circleSymptomTrackerButtonActiveLightPeach {
  position: relative;
  width: 75px;
  height: 75px;
  z-index: 50;
  border: 2px solid #FF8384;
  display: flex;
  padding: 1.5em;
  align-items: center;
  justify-content: center;
  top: 0;
  box-sizing: border-box;
}

.circleSymptomTrackerButton img {
  position: relative;
  width: 70px; 
  height: 70px;
}

.circleSymptomTrackerButtonActive img {
  width: 70px; 
  height: 70px;
}
 */

 /*These are the 4 symptom buttons on /monitor page including their colored borders that appear in active state*/

.circleSymptomTrackerButton, .circleSymptomTrackerButtonActiveOrange, .circleSymptomTrackerButtonActiveBlue, .circleSymptomTrackerButtonActiveDarkPeach, .circleSymptomTrackerButtonActiveLightPeach {
  position: relative;
  width: 75px; 
  height: 75px; 
  z-index: 50;
  display: flex;
  /* padding: 0.5em; */
  /* padding: 0.1em; */
  align-items: center;
  justify-content: center;
  top: 0;
  box-sizing: content-box;
}

.circleSymptomTrackerButtonActiveBlue {
  border: 2px solid #57B7FA;
}

.circleSymptomTrackerButtonActiveDarkPeach {
  border: 2px solid #FF6A7B;
}

.circleSymptomTrackerButtonActiveLightPeach {
  border: 2px solid #838EEA;
}

.circleSymptomTrackerButtonActiveOrange {
  border: 2px solid #FF8A33;
}

.circleSymptomTrackerButton img, .circleSymptomTrackerButtonActiveOrange img, .circleSymptomTrackerButtonActiveBlue img, .circleSymptomTrackerButtonActiveDarkPeach img, .circleSymptomTrackerButtonActiveLightPeach img {
  position: relative;
  width: 70px;
  /* height: 70px; */
  height: 90px;
}

.symptomButtonWriting {
  color: #494949;
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px; /* 146.154% */
}

/* End of symptom buttons on /monitor page*/

.emotion-wrapper {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: flex-start; */
  margin: auto;
  align-items: center;
  width: 110%;
}
.emotion-wrapper div {
  margin: 1em;
  width: 24%;
}

/* .emotion-wrapper div img {
  border: 2px solid white;
  border-style: double;
} */

.emotion-wrapper div p {
  margin-top: 1em;
}

.normal-emotion div {
  flex-direction: column;
}

.good-emotion div img {
  background-color: #6482ce;
  border-radius: 50px;
}

.normal-emotion div img {
  background-color: #ff8385;
  border-radius: 50px;
}

.regular-emotion div img {
  background-color: #f85358;
  border-radius: 50px;
}

.symptomDrawer {
  border-top-right-radius: 70px;
  border-top-left-radius: 70px;
  max-width: 100%;
  color: white;
  /* padding: 2em; */
  /* max-height: 3000px; */
  /* height: 100%; */
  /* margin-bottom: 60px; */
  padding: 0px;
  overflow: hidden;
/*   display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; */
}

.symptomDrawerRecs {
  border-top-right-radius: 70px;
  border-top-left-radius: 70px;
  max-width: 100%;
  color: white;
  /* padding: 2em; */
  /* max-height: 3000px; */
  /* height: 100%; */
  /* margin-bottom: 60px; */
  /* padding: 0px; */
  /* padding-top: 2em;
  margin-top: 2em; */
  overflow: hidden;
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  /* height: 100vh; */ /* This makes the container take the full viewport height */
  text-align: left; /* Keep text left-aligned */
}



@media screen and (min-device-width: 278px) and (max-width: 321px) {
  .symptomDrawer{
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
  } 
}


/* .symptomDrawer {
  padding: 0px;
} */
/* .why-monitor-symptom-drawer {
  border-top-right-radius: 100px;
  border-top-left-radius: 100px;
  width: 100%;
  color: white;
  padding: 2em;
  height: 2500px; */
/* } */

.see-progress-drawer {
  border-top-right-radius: 70px;
  border-top-left-radius: 70px;
  width: 100%;
  color: white;
  padding-top: 2em;
  text-align: center;
  /* height: 70vh; */
}

@media screen and (min-device-width: 670px) {
  .see-progress-drawer{
    padding-top: 0 !important;
    padding-bottom: 20px !important;
  } 
}


.topLandingImg {
/*   height: 217px;
  width: 250px; */
  max-width: 70%;
  margin-bottom: -28px;
}

.graphDrawer {
  border-top-right-radius: 70px;
  border-top-left-radius: 70px;
  width: 100%;
  background-color: #f0f0f0;
  /* padding: 2em; */
}

.userGraph {
  position: relative;
  bottom: 59px;
}

.currentMonth {
  color: #494949;
  text-align: center;
  font-family: "GT Walsheim Pro";
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/* .descriptionDrawer {
  
  border-radius: 100px;
  width: 100%;
  color: white;
  padding: 2em;
}

.largeButton2 {
  min-width: 64vw;
  padding: 9px 20px 9px;
  border-radius: 2rem; /* 17px */
 /*  color: #FF5D69;
} */

.largeButton3 {
  min-width: 64vw;
  max-width: 50px;
  border-radius: 2rem; /* 17px */
  color: white;
  text-align: center;
}

.largeButton2 {
  min-width: 64vw;
  padding: 9px 20px 9px;
  border-radius: 2rem; /* 17px */
  color: #FF5D69;
}

.largeButton3 {
  min-width: 64vw;
  max-width: 50px;
  border-radius: 2rem; /* 17px */
  color: white;
  text-align: center;
}

.descriptionDrawer {
  border-radius: 70px;
  width: 100%;
  color: #505050;
  padding: 2em;
  margin: auto;
  position: relative;
  /* right: 18px; */
  color: #505050;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
}

.descriptionDrawer img {
  margin: auto;
}

/* RANGE.CSS */

/* input[type="range"] {
  -webkit-appearance: none;
  height: 7px;
  background: grey;
  border-radius: 5px;
  background-image: linear-gradient(#d46a6a, #d46a6a);
  background-repeat: no-repeat;
  width: 100%;
} */

input[type="range"] {
  -webkit-appearance: none;
  appearance: none; 
  background: grey;
  border-radius: 5px;
  background-image: linear-gradient(#d46a6a, #d46a6a);
  background-repeat: no-repeat;
  width: 100%;
}


/* input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #d46a6a;
  cursor: pointer;
  box-shadow: 0 0 2px 0 #555;
} */

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none; 
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #d46a6a;
  cursor: pointer;
  box-shadow: 0 0 2px 0 #555;
}


input[type="range"]::-moz-range-thumb {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #d46a6a;
  cursor: pointer;
  box-shadow: 0 0 2px 0 #555;
}


input[type="range"] + datalist {
  display: block;
  margin-top: -4px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}
input[type="range"] + datalist optgroup {
  display: inline-block;
  width: calc((100% - 12px) / (var(--list-length) - 1));
  text-align: center;
}
input[type="range"] + datalist optgroup:first-child {
  width: calc((100% - 12px) / ((var(--list-length) - 1) * 2) + 6px);
  text-align: left;
  white-space: pre-line;
}
input[type="range"] + datalist optgroup:last-child {
  width: calc((100% - 12px) / ((var(--list-length) - 1) * 2) + 6px);
  text-align: right;
}

/* #my-datalist optgroup {
  margin-right: 17px;
} */

#output {
  margin-top: 4em;
}

.new-line {
  white-space: pre-line;
}

.MuiSlider-markLabel {
  font-size: 14px !important;
}

.oneAndFivelabelTextWrapper {
  display: flex;
  font-size: 11px;
  justify-content: space-between;
  margin-left: -21px;
  margin-right: -18px;
  opacity: 43%;
  width: 107%;
}

.rangeLabels {
  display: flex;
  justify-content: space-between;
  font-size: 8px;
  font-size: 10px;
  margin-top: -29px;
}

.input-range__label {
  display: none;
}

.input-range__slider {
  height: 14px;
  width: 14px;
  background: #425160;
  border: 1px solid #425160;
}

.input-range__track {
  background: #bcbec0;
  height: 4px;
}
.input-range__track--active {
  background: #425160;
}

.input-range__track,
.input-range__slider,
.input-range__slider-container {
  transition: none;
}

.divider-container {
  display: flex;
  position: relative;
  justify-content: space-between;
  bottom: -16px;
}

.divider-container__item {
  height: 14px;
  border: 1px solid #bcbec0;
}

.top20 {
  top: 20px;
}

.top25 {
  top: 25px;
}

.top32 {
  top: 32px;
}

.symptomButtonsWrapper {
  display: flex;
  justify-content: space-around;
  margin-right: 5%;
  margin-left: 5%;
}

.symptomButtonsWrapper2 {
  display: flex;
  justify-content: space-around;
  margin-right: 5%;
  margin-left: 4%;
  /* padding-left: 3%;
  padding-right: 8%; */
}

/* Prev and next bottom nav */
.prevAndNextBottomNav {
  width: 100%;
  position: absolute;
  bottom: 0px;
  z-index: 10;
}

.prevAndNextBottomNav .navBtn .image {
  width: 30px;
  height: 30px;
  margin-top: 100px;
}

/* This is for PrevAndNextBottomNavTwo.js only for the Next Btn on IntroPage */
.navBtn2 {
  width: 30px;
  height: 30px;
  margin-top: 450px;
}

.prevButtonBottomIntro {
  width: 30px;
  height: 30px;
  margin-top: 400px !important;
  margin-left: 100px !important
}

.prevBtnTop {
  position: fixed;
  width: 40px;
  height: 40px;
  z-index: 10;
  margin-left: 2rem;
  padding-top: 4rem;
  display: inline-block;
  position: fixed;
}

.prevBtnTopLow {
  width: 40px;
  height: 40px;
  z-index: 10;
  margin-left: 1rem;
  top: 4px;
  padding-top: 3rem;
  position: fixed;
}

.prevBtnTopDivBlue {
  width: 600px;
  background-color: #051D58 !important;
  position: fixed;
  display: flex;
  justify-content: flex-start;
  height: 40px;
  overflow: hidden;
}

.prevBtnTopResponsive {
  padding: 5px 520px 18px 5px;
  }
  
  @media all and (max-width:30em){
  .prevBtnTopResponsive{
  display:block;
  margin:0.4em auto;
  }
  }

  @media all and (min-width:768px){
    .prevBtnTopResponsive{
    display:block;
    margin:0.5em auto;
    }
    }

.prevBtnTopFixedBlue {
  position: fixed;
  display: inline;
  width: 40px;
  height: 40px;
  margin-left: 4vw;
  top: 1px;
  background-color: #051D58 !important;
}

.prevBtnTopFixedWhite {
  position: fixed;
  top: 1px;
  background-color: #fff;
}

.prevBtnTopDivWhite {
  background-color: #fff;
  height: 50px;
  margin-top: 3rem;
  margin-bottom: 20px;
  max-width: 100%;
  position: fixed;
  z-index: 100;
  min-width: 600px;
}

.prevBtnTopDivClear {
  background-color: rgba(0, 0, 0, .0);
  height: 50px;
  margin-top: 3rem;
  margin-bottom: 20px;
  max-width: 100%;
  position: fixed;
  z-index: 100;
  min-width: 600px;
}

.prevBtnTop1 {
  position: absolute;
  width: 10%;
  left: 0px;
  z-index: 1;
  margin-left: 3vw;
  margin-top: -14vw;
  padding-top: 1%;
}

.prevBtnTop2 {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 0px;
  z-index: 10;
}

.prevBtnTop3 {
  width: 40px;
 
  height: 40px;
  
  z-index: 10;
  margin-left: 3vw;
  margin-top: 10vw;
  padding-top: 10%;
}


.pinInputs {
  width: 53px;
  height: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4350E6;
  font-weight: bold;
  background-color: #fff;
  border-radius: 17px;
  text-align: center;
  border: none;
}
.pinInputs::placeholder {
  color: #4350E6 !important;
}

.introPageTwo .prevAndNextBottomNav {
  padding-right: 30px;
  bottom: -5px;
}

.introPropPages .prevAndNextBottomNav {
  /* position: relative; */
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  padding-bottom: 0px;
}

@media screen and (min-device-width: 750px) {
  .introPropPages {
    min-height: 100vh;
  }
}

.introPropPages .largeButtonContainer {
  z-index: 11;
}

/* React calendar */
.react-calendar,
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus,
.react-calendar__navigation button[disabled],
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus,
.react-calendar__tile--now,
.react-calendar__tile--active {
  background: transparent !important;
  background-color: transparent !important;
}

.react-calendar {
  width: 100% !important;
  border: none !important;
}

.react-calendar__navigation {
  margin-bottom: 0px !important;
}

.react-calendar button {
  color: #ffffff;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation__prev-button,
.react-calendar__navigation__next-button {
  flex-grow: 2;
}

.react-calendar__navigation__prev-button {
  text-align: right;
}

.react-calendar__navigation__next-button {
  text-align: left;
}

.activateNotificationsPage .react-calendar__navigation__prev-button,
.activateNotificationsPage .react-calendar__navigation__next-button {
  color: transparent;
  position: relative;
}

.activateNotificationsPage .react-calendar__navigation__prev-button::after {
  content: "";
  width: 14px;
  height: 14px;
  background: url("./Images/prevBtnImage-whiteAndBlue.svg");
  background-size: 100% auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin-top: auto;
  margin-bottom: auto;
}

.activateNotificationsPage .react-calendar__navigation__next-button::after {
  content: "";
  width: 14px;
  height: 14px;
  background: url("./Images/nextBtnImage-whiteAndBlue.svg");
  background-size: 100% auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: left;
  margin-top: auto;
  margin-bottom: auto;
}

.react-calendar .react-calendar__navigation__label__labelText--from {
  font-family: "GTWalsheimPro-Bold", sans-serif;
  font-size: 15px;
  line-height: 26px;
  text-transform: capitalize;
}

.react-calendar__month-view__weekdays abbr {
  font-family: "GTWalsheimPro-Bold", sans-serif;
  font-size: 9px;
  line-height: 26px;
  text-decoration: none !important;
}

button.react-calendar__tile {
  display: grid;
  /* flex-direction: column; */
  position: relative;
}

.react-calendar__tile abbr {
  font-family: "GTWalsheimPro-Regular", sans-serif;
  font-size: 11px;
  line-height: 26px;
  z-index: 2;
}

.react-calendar__tile--active::before {
  content: "";
  width: 25px;
  height: 25px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background-color: #f74a57;
  border-radius: 50%;
  z-index: 1;
}

.react-calendar__month-view__days__day--neighboringMonth abbr {
  color: #6678a3;
}

/* Rounded top divs */
.topRoundDiv {
  border-top-left-radius: 3rem;
  border-top-right-radius: 3rem;
  margin-top: -40px;
  padding-bottom: 40px;
}

.topRoundDiv3 {
  border-top-left-radius: 3rem;
  border-top-right-radius: 3rem;
  margin-top: 40px;
  padding-bottom: 100px;
  margin-bottom: 50px;
}

.bottomRoundDiv {
  border-top-left-radius: 3rem;
  border-top-right-radius: 3rem;
  margin-top: -40px;
  padding-bottom: 40px;
  border-bottom-left-radius: 3rem;
  border-bottom-right-radius: 3rem;
}

.topRoundDiv2 {
  border-top-left-radius: 3rem;
  border-top-right-radius: 3rem;
  margin-top: -40px;
  padding-bottom: 40px;
  height: 400px;
}

.topRoundDivlonger {
  border-top-left-radius: 3rem;
  border-top-right-radius: 3rem;
  margin-top: -40px;
  padding-bottom: 150px;
}

.bottomRoundDiv {
  border-top-left-radius: 3rem;
  border-top-right-radius: 3rem;
  margin-top: -40px;
  padding-bottom: 40px;
  border-bottom-left-radius: 3rem;
  border-bottom-right-radius: 3rem;
}

.topRightRadius9 {
  border-top-right-radius: 9px;
}

.bottomRightRadius9 {
  border-bottom-right-radius: 9px;
}

.topRoundDiv.hasShadow {
  box-shadow: 0px -5px 10px #0000001c;
}

.topRoundDiv.hasBlueShadow {
  box-shadow: 0px -5px 15px #0a297443;
}

.smallTagDiv {
  display: flex;
  width: fit-content;
  height: 20px;
  align-items: center;
  padding: 0px 11px 0px;
  border-radius: 1rem;
}

/* small(11/11), medium(29/29) and large(44/44) radio buttons */
.smallRadioBtn {
  width: 11px;
  height: 11px;
  appearance: none;
  position: relative;
  border-radius: 50%;
  border: none;
  cursor: pointer;
}
.smallRadioBtn:checked:after {
  width: 7px;
  height: 7px;
  display: block;
  position: relative;
  content: "";
  border-radius: 50%;
}

.smallMediumRadioBtn {
  width: 25px;
  height: 25px;
  appearance: none;
  position: relative;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  border: 2px solid #051d58;
}
.smallMediumRadioBtn:checked:after {
  width: 9px;
  height: 9px;
  display: block;
  position: relative;
  content: "";
  border-radius: 50%;
}

.mediumRadioBtn {
  width: 29px;
  height: 29px;
  appearance: none;
  position: relative;
  border-radius: 50%;
  border: 2px solid white;
  cursor: pointer;
}
.mediumRadioBtn:checked:after {
  width: 19px;
  height: 19px;
  display: block;
  position: relative;
  content: "";
  border-radius: 50%;
}

.largeRadioBtn {
  width: 44px;
  height: 44px;
  appearance: none;
  position: relative;
  border-radius: 50%;
  border: none;
  cursor: default;
}
.largeRadioBtn:checked:after {
  width: 28px;
  height: 28px;
  display: block;
  position: relative;
  content: "";
  border-radius: 50%;
}

.radioCheckPeach:checked:after {
  background-color: #f74a57;
}

.radioCheckBlue:checked:after {
  background-color: #051d58;
}

/* Carousel */
.carousel,
.carousel-item {
  flex-grow: 1;
  
  /* height: 100vh; */ /* commented this out because it cuts the carousel slides off and makes them too short*/
}

.carousel-item-2 {
  width: 70vh;
}

/* carousel-item used to have background-color: #ffffff;, removed for testimonials*/

ol.carousel-indicators li {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #edeaea;
  border: none;
  margin-right: 7px;
  margin-left: 7px;
}

ol.carousel-indicators li.active {
  background-color: #f74a58;
}

/* carousel-item used to have background-color: #ffffff;, removed for testimonials*/

ol.carousel-indicators li {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #edeaea;
  border: none;
  margin-right: 7px;
  margin-left: 7px;
}

ol.carousel-indicators li.active {
  background-color: #f74a58;
}
.positionAbsoluteZIndex {
  position: absolute;
  z-index: 1;
}


/* Owl Carousel */
.slide-1 {
  position: absolute;
  z-index: 1;
  bottom: 60vw;
  margin-bottom: 1rem;
  left: 17vw
}

.positionAbsoluteZIndex {
  position: absolute;
  z-index: 1;
}


.slide-1 {
  position: absolute;
  z-index: 1;
  bottom: 60vw;
  margin-bottom: 1rem;
  left: 17vw
}

.slide-2 {
  position: absolute;
  z-index: 1;
  bottom: 30vw;
  margin-bottom: 1rem;
  left: 17vw
}

/* .slide-3 {
  position: absolute;
  z-index: 1;
  bottom: 10vw;
  margin-bottom: 35vw;
  left: 10vw;
  right: 8vw;
} */

/* .slide-4 {
  position: absolute;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-size: 12px;
  line-height: 14px;
  z-index: 1;
  top: 2.5rem;
  width: 100%;
  left: 1.5rem;

}

@media screen and (min-width: 310px){.slide-4{
  font-size: 16px;
  line-height: 18px;
  top: 3rem;
  left: 1.5rem;
}}

@media screen and (min-width: 360px){.slide-4{
  font-size: 18px;
  line-height: 20px;
  top: 4.5rem;
  left: 2rem;
}}

@media screen and (min-width: 390px){.slide-4{
  font-size: 22px;
  top: 4rem;
  line-height: 24px;
}}
@media screen and (min-width: 400px){.slide-4{
  font-size: 24px;
  top: 5rem;
  line-height: 26px;
}}

@media screen and (min-width: 500px){.slide-4{
  font-size: 32px;
  top: 8rem;
  left: 3rem;
  line-height: 32px;
}}

@media screen and (min-width: 760px){.slide-4{
  font-size: 38px;
  top: 9rem;
  left: 3.5rem;
  line-height: 38px;
}}
 */
/* .coral-quotes {
  position: absolute;
  left: 17vw;
  bottom: 86vw;
  width: 27vw;
  height: 28vw;
  z-index: 50;
} */

.black-font {
  font-family: 'GTWalsheimPro-Bold', sans-serif;
  font-size: 20px;
  line-height: 26px;
  color: #051D58;
}

/* Tiny-Slider Carousel Testimonials */


.slide-2 {
  position: absolute;
  z-index: 1;
  bottom: 30vw;
  margin-bottom: 1rem;
  left: 17vw
}

/* .slide-3 {
  position: absolute;
  z-index: 1;
  bottom: 10vw;
  margin-bottom: 35vw;
  left: 10vw;
  right: 8vw;
} */

/* .slide-4 {
  position: absolute;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-size: 12px;
  line-height: 14px;
  z-index: 1;
  top: 2.5rem;
  width: 100%;
  left: 1.5rem;
 
}

@media screen and (min-width: 310px){.slide-4{
  font-size: 16px;
  line-height: 18px;
  top: 3rem;
  left: 1.5rem;
}}

@media screen and (min-width: 360px){.slide-4{
  font-size: 18px;
  line-height: 20px;
  top: 4.5rem;
  left: 2rem;
}}

@media screen and (min-width: 390px){.slide-4{
  font-size: 22px;
  top: 4rem;
  line-height: 24px;
}}
@media screen and (min-width: 400px){.slide-4{
  font-size: 24px;
  top: 5rem;
  line-height: 26px;
}}

@media screen and (min-width: 500px){.slide-4{
  font-size: 32px;
  top: 8rem;
  left: 3rem;
  line-height: 32px;
}}

@media screen and (min-width: 760px){.slide-4{
  font-size: 38px;
  top: 9rem;
  left: 3.5rem;
  line-height: 38px;
}} */

/* .coral-quotes {
  position: absolute;
  left: 17vw;
  bottom: 86vw;
  width: 27vw;
  height: 28vw;
  z-index: 50;
} */

.black-font {
  font-family: 'GTWalsheimPro-Bold', sans-serif;
  font-size: 20px;
  line-height: 26px;
  color: #051D58;
}

/* Tiny-Slider Carousel Testimonials */




.needsBorders {
  border-top-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}

.borderWidth1 {
  border-width: 1px;
  border-style: solid;
}

.borderWidth4 {
  border-width: 4px;
  border-style: solid;
}

.borderTopWidth1 {
  border-top-width: 1px;
  border-bottom-width: 0px;
  border-style: solid;
}

.borderLeftRight0 {
  border-left-width: 0px;
  border-right-width: 0px;
}

.borderBottomWidth1 {
  border-bottom-width: 1px;
  border-style: solid;
}

#hasTopBottomPeachBorder {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-style: solid;
  border-color: #f74a57;
  padding: 14px 0px;
}

#hasTopBlueBorder {
  border-top-width: 1px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-style: solid;
  border-color: #051d58;
  padding-top: 14px;
}

.topNavBar .bellIcon {
  margin-right: 2px;
}

#hasBottomBlueBorder {
  border-top-width: 0px;
  border-bottom-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-style: solid;
  border-color: #051d58;
  padding-bottom: 14px;
}

.borderColorWhite {
  border-color: #ffffff;
}

.borderColorPeach4 {
  border-color: #f74a57;
}

.borderColorBlue6 {
  border-color: #22469f;
}

.borderColorBlue8 {
  border-color: #051d58;
}

.borderColorRed {
  border-color: red;
}

/* Top nav bar */
.topNavBar {
  z-index: 100;
  display: inline;
  position: fixed;
  /* max-width: 600px;
  height: 60px; */
}


.topNavBar2 .containerDivs {
  width: 40px;
  height: 40px;
}

.topNavBar2 .containerDivs.spaceTop {
  padding-top: 5px;
  height: 35px;
  margin-top: 0px;
}

.topNavBar2 .hamburgerMenuIcon {
  width: 24px;
  height: auto;
}

.topNavBar .generalCalendarPrevButton {
  position: relative;
  left: 10px;
}

.topNavBar .generalCalendarPrevButton {
  position: relative;
  left: 10px;
}


.topNavBar2 .bellIcon {
  margin-right: 2px;
}

.topNavBar2 .newNotificationCircle,
.activateNotificationsPage .newNotificationCircle {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
}

/* Hamburger menu */
.hamburgerMenuContainer .bm-overlay {
  background: none !important;
}

.hamburgerMenuContainer *:focus {
  outline: none;
}

/* For pages */
.minPageHeight100vh {
  min-height: 100vh;
}

.minPageHeight135vh {
  min-height: 135vh;
}

.minPageHeight150vh {
  min-height: 150vh;
}


.pagesInnerDiv {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: scroll;
}

/* This hides the scroll  bar to the right that is added by browsers to pagesInnerDiv because of overflow-y:scroll*/

#scroll::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

::-webkit-scrollbar {
  display: none;
}

#scroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.pageWithBottomMainNav {
  padding-bottom: 50px;
}

/* Contentful Markdown */
.contentfulMarkdown p {
  margin-bottom: 1em;
}

/* this is not the color just a marker  */
.contentfulMarkdown.psMargin0 p {
  margin-bottom: 0px;
}

.contentfulMarkdown.psMargin5 p {
  margin-bottom: 5px;
}

.contentfulMarkdown ul,
.contentfulMarkdown ol {
  padding-left: 1.4em;
  margin-bottom: 1em;
}

.contentfulMarkdown.circleBullets ul {
  list-style-type: circle;
}

.contentfulMarkdown strong {
  font-family: "GTWalsheimPro-Bold", sans-serif;
}

.contentfulMarkdown.hasBookmarkIcon img {
  content: url("./Images/bookmark-icon-peach.svg");
  width: 11px;
  height: 14px;
  display: inline-block;
  margin-right: 4px;
  vertical-align: bottom;
}

.contentfulMarkdown.hasBookmarkIcon.bookmarkMargin0 img {
  margin-right: 0px;
}

.contentfulMarkdown.hasBookmarkIcon.bookmarkAlignFlexStart img {
  align-self: flex-start;
}

.contentfulMarkdown.hasImportantBullets ul {
  list-style: none;
  padding-left: 10px;
}
.contentfulMarkdown.hasImportantBullets ul li {
  display: flex;
  flex-direction: row;
}
.contentfulMarkdown.hasImportantBullets ul li::before {
  content: url("./Images/important-icon-peach.svg");
  width: 25px;
  height: 25px;
}

.contentfulMarkdown.hasImportantBullets ul li p {
  margin-left: 10px;
}

.noHover {
  pointer-events: none;
}

.underlineLink {
  text-decoration: underline;
  text-decoration-color: "white";
}

/* Bottom nav bar */
.bottomNavBar {
  width: 100%;
  height: 65px;
  position: fixed;
  bottom: 0px;
  left: 0;
  z-index: 1001;
  background-color: #ffffff !important;
}

/* Intro value proposition pages */

.headerArea {
  /* ( ~ 180px) */
  min-height: calc(100vw * 0.5);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.headerArea.justText {
  min-height: auto;
}

.stepsMenuPage .topRoundDiv,
.notificationsMenuPage .topRoundDiv,
.contraceptionAssessmentOptionsMenuPage .topRoundDiv,
.contraceptionMenuPage .topRoundDiv {
  margin-top: -45px;
}

/* .example-calendar-why-monitor {
  position: relative;
  bottom: 339px;
  width: 10%;
  right: 0px;
  left: 330px;
} */

/* .why-monitor-step-4-left-arrow {
  width: 50%;
  position: relative;
  bottom: 515px;
  left: 305px;
} */

/* .why-monitor-step-4-left-arrow {
  width: 40%;
  position: relative;
  bottom: 412px;
  left: 195px;
} */

/* .why-monitor-step-4-left-arrow {
  width: 32%;
  bottom: 314px;
  left: 200px;
} */

.why-monitor-step-4-left-arrow {
  width: 30%;
  position: relative;
  bottom: 314px;
  left: 350px;
}

@media screen and (min-device-width: 322px) and (max-width: 415px) {
  .why-monitor-step-4-left-arrow{
  width: 30%;
  position: relative;
  bottom: 340px;
  left: 220px;
  } 
}

@media screen and (min-device-width: 278px) and (max-width: 321px) {
  .why-monitor-step-4-left-arrow{
  width: 30%;
  position: relative;
  bottom: 330px;
  left: 210px;
  } 
}
/* .why-monitor-step-2-left-arrow {
  width: 40%;
  position: relative;
  bottom: 352px;
  left: 305px;
} */

/* .why-monitor-step-2-left-arrow {
  width: 40%;
  position: relative;
  bottom: 257px;
  left: 195px;
} */

.why-monitor-step-2-left-arrow {
  width: 29%;
  position: relative;
  bottom: 260px;
  left: 370px;
}

@media screen and (min-device-width: 278px) and (max-width: 415px) {
  .why-monitor-step-2-left-arrow{
  width: 25%;
  position: relative;
  bottom: 200px;
  left: 235px;
  } 
}

.step-2-text {
  position: relative;
  /* bottom: 210px; */
  bottom: 50px !important;
 /*  width: 59%; */
}

.step-4-text {
  position: relative;
  bottom: 414px;
  /* width: 50%; */
  left: 38px;
}

/* .step-4-text {
  bottom: 342px;
  left: 3px;
  width: 61%;
} */

.step-5-text {
  position: relative;
  bottom: 354px;
  width: 80%;
  /* left: 138px; */
  left: 30px;
  padding-bottom: 40px;
}

/* .step-5-text {
  bottom: 402px;
  left: 67px;
  width: 80%;
} */

/* Abortion Step Pages */
.abortionStepPages .headerArea {
  min-height: calc(100vw * 0.686);
}

.abortionStepPages .headerArea.empty {
  /* 72px */
  min-height: 72px;
}

.abortionStepPages .topRoundDiv,
.selfCarePage .topRoundDiv {
  margin-top: 0px !important;
}

/* Self care pages */
.selfCareSlides.slide1 .headerArea {
  min-height: calc(100vw * 0.32);
}
.selfCareSlides.slide3 .headerArea {
  min-height: calc(100vw * 0.56);
}
.selfCareSlides.slide4 .headerArea {
  min-height: calc(100vw * 0.35);
}

.selfcareMenuImage {
  width: 100%;
  top: 109px;
  left: 68px;
  width: 224px;
height: 216px;
}

/* media query for slide 1 */
@media (max-width: 600px) {
  .mobile-self-care-class-four {
   padding-top: 60px;
   position: sticky;
  }
}

/* media query for slide 3 */
@media (min-width: 450px) and (max-width: 3000px) {
  .mobile-self-care-class {
   padding-top: 135px;
   position: sticky;
  }
}

/* media query for slide 4 */
@media (min-width: 450px) and (max-width: 3000px) {
  .mobile-self-care-class-two {
   padding-top: 80px;
   position: sticky;
  }
}


/* Notifications */

/* activateNotificationsPage */
.activateNotificationsPage .react-date-picker__inputGroup {
  display: none;
}

.activateNotificationsPage .datePickerBtn,
.activateNotificationsPage .timePickerBtn {
  position: absolute;
  top: 0;
  left: 0;
}

.activateNotificationsPage .react-date-picker__calendar {
  width: 100% !important;
  margin: 0% auto !important;
}

.activateNotificationsPage .react-calendar {
  border-radius: 1.5rem;
}

.activateNotificationsPage .inputContainer * {
  border: none !important;
}

.activateNotificationsPage .react-date-picker__calendar-button,
.activateNotificationsPage .timePickerBtn {
  width: 100%;
  height: 100%;
}

.activateNotificationsPage .react-date-picker__calendar-button svg {
  display: none;
}

.activateNotificationsPage .react-calendar {
  background-color: #051d58 !important;
}

.activateNotificationsPage .timePickerBtn input[name="time"] {
  width: 100%;
  left: 0;
}

.activateNotificationsPage .react-time-picker__inputGroup__input {
  color: #ffffff;
}

.activateNotificationsPage .phoneInput::placeholder {
  color: #051d58;
}

/* Any Phone Width in Landscape mode */
@media screen and (max-height: 480px) {
  .landscapeWidth67Percent {
    width: 67% !important;
    margin-left: auto;
    margin-right: auto;
  }

  .landscapeWidth75Percent {
    width: 75% !important;
    margin-left: auto;
    margin-right: auto;
  }

  .landscapePositionRelative {
    position: relative !important;
  }

  .landscapeMarginTop95 {
    margin-top: 95px !important;
  }

  .largeButton {
    min-width: calc(600px * 0.37) !important;
  }
  .widthMatchLargeButton {
    width: calc(600px * 0.37) !important;
  }

  .buttonWidth55Percent {
    width: calc(600px * 0.55) !important;
  }

  .hamburgerMenuContainer .bm-item-list {
    padding-bottom: 65px !important;
  }
}

.contraceptionButton button {
  padding: 0.75em;
  margin-bottom: 20px;
}

  .oneAndFivelabelTextWrapper {
    width: 111%;
  }

  .crisp-client .cc-tlyw[data-full-view="true"] .cc-kxkl .cc-nsge {
    bottom: 158px !important;
  }

  .recs-graphic {
    bottom: 350px;
    width: 50%;
  }
  .pregSymptomAdviceText {
    width: 85%;
    margin-bottom: 2em;
  }

  
  .why-monitor-symptom-drawer {
    border-top-right-radius: 100px;
    border-top-left-radius: 100px;
    width: 100%;
    color: white;
    padding: 2em;
    height: 2500px;
  }

 /*  .step3 {
    bottom: 280px;
  } */

  .step3 {
    bottom: 205px;
  }

  @media screen and (min-device-width: 250px) and (max-width: 415px) {
    .step3{
    bottom: 175px;
    } 
  }

/*   .step3 {
    position: relative;
    bottom: 380px;
  } */

  .step4 {
    bottom: 372px;
  }
  .step5 {
    bottom: 432px;
  }
  /* .circleSymptomTrackerButton {
    width: 75px;
    height: 75px;
  }

  .circleSymptomTrackerButtonActive {
    width: 80px;
    height: 80px;
  }

  .circleSymptomTrackerButton img {
    height: 70px;
  } */


  .emotion-explanation {
    width: 90%;
  }

  
  /* .circleSymptomTrackerButton .smile-logo {
    top: 7px;
  } */

  .mainPageDescriptor {
    width: 80%;
    margin: auto;
  }
  
  .number2 {
    top: 0px;
  }


  /* .top-nav-popup {
    margin-top: 0em;
  } */
  .rightArrowWhyMonitor {
    right: 120px;
  }
  .step-2-text {
    bottom: 170px;
  }


  .monitor-page {
    padding-right: 5px;
    padding-left: 5px;
  }

/*   .symptomButtonsWrapper {
    margin-right: 5px;
    margin-left: 10px;
  } */
 /*  .why-monitor-title {
    font-size: 20px;
  } */
  .why-monitor-symptom-drawer {
    height: auto;
  }
  .step-1-why-monitor-text {
    /* width: 95%; */
    margin: auto;
    /* font-size: 13px; */
    padding-top: 2em;
  }
  .rightArrowWhyMonitor {
    right: 95px;
    bottom: 52px;
  }
  .example-monitor-button {
    bottom: 64px;
  }
  .number2 {
    top: 21px;
  }
  .step-2-text {
    bottom: 103px;
 /*    width: 59%;
    font-size: 13px; */
    margin-left: 13px;
  }
  .example-calendar-why-monitor {
    position: relative;
    bottom: 380px;
    width: 27%;
    right: 0px;
    /* left: 187px; */
    left: 370px;
  }

  @media screen and (min-device-width: 278px) and (max-width: 399px) {
    .example-calendar-why-monitor{
      bottom: 260px;
      left: 230px; 
      right: 0px;
    } 
  }

  @media screen and (min-device-width: 400px) and (max-width: 500px) {
    .example-calendar-why-monitor{
      bottom: 330px;
      left: 230px; 
      right: 0px;
    } 
  }

 /*  .whyMonitorTextSmall {
    font-size: 13px;
  } */

  @media screen and (min-device-width: 278px) and (max-width: 393px) {
    .whyMonitorTextSmall{
      font-size: 14px;
    } 
  }

  

 /*  .example-calendar-why-monitor {
    bottom: 310px;
    left: 222px;
  } */


  .step4 {
    bottom: 260px;
  }
  .recs-graphic {
    bottom: 257px;
  }

/*   .step-3-text {
    position: relative;
    bottom: 200px;
   /*  width: 57%; */
    /* left: 111px; */
    /* font-size: 13px; */
  /* } */

  .step-3-text {
    bottom: 200px;
    left: 50px;
    width: 75%;
  }

/*   .step-3-text {
    position: relative;
    bottom: 350px;
    
    left: 230px;
  } */

  .rightArrowWhyMonitor2 {
    position: relative;
    right: 140px;
    bottom: 250px;
    width: 25%;
  }

 /*  .rightArrowWhyMonitor2 {
    position: relative;
    right: 130px;
    bottom: 430px;
  } */

 /*  .rightArrowWhyMonitor2 {
    right: 96px;
    bottom: 350px;
  } */

  .step5 {
    bottom: 355px;
  }
 
  .step-4-text {
    bottom: 257px;
    left: 12px;
   /*  width: 74%;
    font-size: 13px; */
  }
  
  .step5 {
    bottom: 333px;
  }

 /*  .leave-button {
    bottom: 230px;
  } */
  /* .why-monitor-main-text {
    font-size: 13px;
  } */

  .main-page-return button {
    margin-bottom: 11em;
  }
 
  .recs-main-content {
    height: 100vh;
  }
  .recs-hero-img {
    /* height: 100px; */
    width: 90%;
    bottom: -30px;
  }

  .recs-title {
    padding-top: 2em;
  }

  .symptom-rec-title p {
    padding-top: 60px;
  }

  .recs-header-title-size {
    max-width: 20px;
  }

  .slider-wrapper {
    width: 80%;
    margin: auto;
  }

  .description-wrapper-title {
    width: 80%;
    font-size: 15px;
    padding-top: 4em;
  }
  .description-wrapper {
    width: 80%;
    font-size: 90%;
  }
  .pregSymptomAdviceText {
    width: 90%;
  }
  /* .smile-logo {
    top: 5px;
  } */


/* Tablets - Desktops */
@media screen and (min-width: 600px) and (min-height: 600px) {
  /*(min-height: 927px) { */
  /* Hamburger menu */

/*   .app {
    width: 600px !important;
    max-width: 600px !important;
    min-height: 900px !important;
  } */

  .width40 {
    width: 40%;
  }

  .width46 {
    width: 46%;
  }

  .width64vw {
    width: calc(600px * 0.64) !important;
  }

  .minHeight100vw {
    min-height: 600px !important;
  }

  .height100vw {
    height: 600px !important;
  }

  .maxHeight100vw {
    max-height: 600px !important;
  }

  .minPageHeight100vh {
    min-height: 100% !important;
  }

  .pageWithBottomMainNav {
    padding-bottom: 0px !important;
  }

  /* reusable components */
  .largeButton {
    min-width: calc(600px * 0.37) !important;
  }
  .widthMatchLargeButton {
    width: calc(600px * 0.37) !important;
  }

  .buttonWidth55Percent {
    width: calc(600px * 0.55) !important;
  }

  .topRoundDiv {
    margin-top: -50px !important;
  }

  .bottomNavBar {
    width: 600px !important;
    /* position: relative !important; */
    left: unset !important;
  }

  /* Hamburger menu */
  .hamburgerMenuOpacity0 {
    opacity: 0;
    transition: opacity 0.4s;
  }

  .hamburgerMenuOpacity1 {
    opacity: 1;
    transition: opacity 0.4s;
  }

  .hamburgerMenuContainer .bm-menu-wrap {
    width: calc(600px * 0.7) !important;
  }

  .headerArea {
    /* ( ~ 180px) */
    min-height: calc(600px * 0.49) !important;
  }

  .headerArea.justText {
    min-height: auto !important;
  }

  /* abortion steps pages */
  .abortionStepPages .headerArea.empty {
    min-height: 72px !important;
  }
}

/* registration input */
.textInput {
  width: 100%;
  height: 40px;
  border-radius: 8px;
}
.headingWidth {
  width: 50%;
}

.eyeIcon {
  margin-top: 12px;
  margin-left: 90%;
  position: absolute;
  margin-right: 20px;
  height: 16px !important;
  width: 26px;
}

/* Abortion Buckets */

.roundedBoxTop {
  top: 50px;
  width: 295px;
  height: 126px;
  margin-top: 20px;
  background: #F75F64 0% 0% no-repeat padding-box;
  border-radius: 17px;
  opacity: 1;
}

.roundedBoxBottom {
  top: 160px;
  width: 295px;
  height: 126px;
  margin-top: 20px;
  background: #B9C0EF 0% 0% no-repeat padding-box;
  border-radius: 17px;
  opacity: 1;
}

.roundedBoxMiddleLight {
  top: 80px;
  width: 295px;
  height: 126px;
  margin-top: 20px;
  background: #22469F 0% 0% no-repeat padding-box;
  border-radius: 17px;
  opacity: 1;
}

.roundedBoxMiddleDark {
  top: 110px;
  width: 295px;
  height: 126px;
  margin-top: 20px;
  background: #051D58 0% 0% no-repeat padding-box;
  border-radius: 17px;
  opacity: 1;
}

.roundedBoxTransparentPink {
  width: 295px;
  height: 72px;
  margin-top: 20px;
  background: #F75F641A  0% 0% no-repeat padding-box;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  border-top-left-radius: 18px;
  border-bottom-left-radius: 18px;
  opacity: 1;
  border: 0.7px solid #F75F64;
}

.smallRoundedBoxPink {
  width: 78px;
  height: 72px;
  background: #F75F64  0% 0% no-repeat padding-box;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  opacity: 1;
  border: 1px solid #F75F64;
  padding: 0px 0px 0px 0px;
}

.roundedBoxTransparentLightBlue {
  width: 295px;
  height: 72px;
  margin-top: 20px;
  background: #6F8ED915  0% 0% no-repeat padding-box;
  border-radius: 17px;
  opacity: 1;
  border: 0.7px solid #22469F;
}

.smallRoundedBoxLightBlue {
  width: 78px;
  height: 72px;
  background: #22469F  0% 0% no-repeat padding-box;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  opacity: 1;
  border: 1px solid #22469F;
  padding: 0px 0px 0px 0px;
}

.roundedBoxTransparentDarkBlue {
  width: 295px !important;
  height: 72px !important;
  margin-top: 20px !important;
  background: #051D581A  0% 0% no-repeat padding-box !important;
  border-radius: 17px !important;
  opacity: 1 !important;
  border: 0.7px solid #051D58 !important;
}

.extraSmallRoundedBoxLilac {
  width: 13px !important;
  height: 72px !important;
  background: #B9C0EF  0% 0% no-repeat padding-box !important;
  border-top-left-radius: 16px !important;
  border-bottom-left-radius: 16px !important;
  opacity: 1 !important;
  border: 1px solid #B9C0EF!important;
  padding: 0px 0px 0px 0px !important; 
}

.roundedBoxTransparentLilac {
  width: 295px !important;
  height: 72px !important;
  margin-top: 20px !important;
  background: #B9C0EF1A  0% 0% no-repeat padding-box !important;
  border-radius: 17px !important;
  opacity: 1 !important;
  border: 0.7px solid #B9C0EF !important;
}

/* roundedCardLilac is the custom CSS for Bootstrap Accordion in Abortion Buckets FAQ */

.roundedCardLilac {
  border: none !important;
  background-color: none !important;
  width: 295px !important;
  margin-top: 20px !important;
}

.roundedCardLilacOpenShortest {
  border: none !important;
  background-color: none !important;
  /* border: 1px solid #B9C0EF !important; */
  width: 295px !important;
  min-height: 100% !important;
  padding-top: 10px !important;
  padding-bottom: 20px !important;
  margin-bottom: 40px !important;
  padding-bottom: 280px !important;
}


.roundedCardLilacOpenShort {
  border: none !important;
  background-color: none !important;
  /* border: 1px solid #B9C0EF !important; */
  width: 295px !important;
  min-height: 100% !important;
  padding-top: 10px !important;
  padding-bottom: 20px !important;
  margin-bottom: 40px !important;
  padding-bottom: 300px !important;
}

.roundedCardLilacOpenLessShort {
  border: none !important;
  background-color: none !important;
  /* border: 1px solid #B9C0EF !important; */
  width: 295px !important;
  min-height: 100% !important;
  padding-top: 10px !important;
  padding-bottom: 20px !important;
  margin-bottom: 40px !important;
  padding-bottom: 350px !important;
}

.roundedCardLilacOpenMedium {
  border: none !important;
  background-color: none !important;
  /* border: 1px solid #B9C0EF !important; */
  width: 295px !important;
  min-height: 100% !important;
  padding-top: 10px !important;
  padding-bottom: 20px !important;
  margin-bottom: 40px !important;
  padding-bottom: 370px !important;
}

.roundedCardLilacOpenMedium400 {
  border: none !important;
  background-color: none !important;
  /* border: 1px solid #B9C0EF !important; */
  width: 295px !important;
  min-height: 100% !important;
  padding-top: 10px !important;
  padding-bottom: 20px !important;
  margin-bottom: 40px !important;
  padding-bottom: 400px !important;
}

.roundedCardLilacOpen {
  border: none !important;
  background-color: none !important;
  /* border: 1px solid #B9C0EF !important; */
  width: 295px !important;
  min-height: 100% !important;
  padding-top: 10px !important;
  padding-bottom: 20px !important;
  margin-bottom: 40px !important;
  padding-bottom: 450px !important;
}

.roundedCardLilacOpenMediumShort {
  border: none !important;
  background-color: none !important;
  /* border: 1px solid #B9C0EF !important; */
  width: 295px !important;
  min-height: 100% !important;
  padding-top: 10px !important;
  padding-bottom: 20px !important;
  margin-bottom: 40px !important;
  padding-bottom: 470px !important;
}

.roundedCardLilacOpenMediumShort500 {
  border: none !important;
  background-color: none !important;
  /* border: 1px solid #B9C0EF !important; */
  width: 295px !important;
  min-height: 100% !important;
  padding-top: 10px !important;
  padding-bottom: 20px !important;
  margin-bottom: 40px !important;
  padding-bottom: 500px !important;
}

.roundedCardLilacOpenMediumShort550 {
  border: none !important;
  background-color: none !important;
  /* border: 1px solid #B9C0EF !important; */
  width: 295px !important;
  min-height: 100% !important;
  padding-top: 10px !important;
  padding-bottom: 20px !important;
  margin-bottom: 40px !important;
  padding-bottom: 550px !important;
}

.roundedCardLilacOpenMediumLong {
  border: none !important;
  background-color: none !important;
  /* border: 1px solid #B9C0EF !important; */
  width: 295px !important;
  min-height: 100% !important;
  padding-top: 10px !important;
  padding-bottom: 20px !important;
  margin-bottom: 40px !important;
  padding-bottom: 600px !important;
}

.roundedCardLilacOpenMediumLonger {
  border: none !important;
  background-color: none !important;
  /* border: 1px solid #B9C0EF !important; */
  width: 295px !important;
  min-height: 100% !important;
  padding-top: 10px !important;
  padding-bottom: 20px !important;
  margin-bottom: 40px !important;
  padding-bottom: 700px !important;
}

.roundedCardLilacOpenLong {
  border: none !important;
  background-color: none !important;
  /* border: 1px solid #B9C0EF !important; */
  width: 295px !important;
  min-height: 100% !important;
  padding-top: 10px !important;
  padding-bottom: 20px !important;
  margin-bottom: 40px !important;
  padding-bottom: 750px !important;
}

.roundedCardLilacOpenLonger {
  border: none !important;
  background-color: none !important;
  /* border: 1px solid #B9C0EF !important; */
  width: 295px !important;
  min-height: 100% !important;
  padding-top: 10px !important;
  padding-bottom: 20px !important;
  margin-bottom: 40px !important;
  padding-bottom: 800px !important;
}

.roundedCardLilacOpenLongest {
  border: none !important;
  background-color: none !important;
  /* border: 1px solid #B9C0EF !important; */
  width: 295px !important;
  min-height: 100% !important;
  padding-top: 10px !important;
  padding-bottom: 20px !important;
  margin-bottom: 40px !important;
  padding-bottom: 830px !important;
}

.abortionBucketFAQAccordion {
 border: 1px solid #B9C0EF !important;
  /* border-top-left-radius: none !important;
  border-top-right-radius: none !important;
  border-bottom-left-radius: 18px !important;
  border-bottom-right-radius: 18px !important; */
  width: 295px !important;
  border-radius: 18px;
  /* border-top: none !important;
  border-bottom: 1px solid #B9C0EF !important ;
  border-left: 1px solid #B9C0EF !important;
  border-right: 1px solid #B9C0EF !important; */
  margin-bottom: 50px !important;
}

.smallRoundedBoxDarkBlue {
  width: 78px !important;
  height: 72px !important;
  background: #051D58  0% 0% no-repeat padding-box !important;
  border-top-left-radius: 16px !important;
  border-bottom-left-radius: 16px !important;
  opacity: 1 !important;
  border: 1px solid #051D58 !important;
  padding: 0px 0px 0px 0px !important; 
}

.smallDivRoundedBox {
  width: 295px;
  height: 72px;
}

.textWidth {
  width: 145px;
}

.textWidth2 {
  width: 160px;
}

.textWidth3 {
  width: 175px;
}

.textWidth4 {
  width: 180px;
}

.displayInline {
  display: inline-block;
}

.roundedBoxNarrowLilac {
width: 295px;
height: 69px;
margin-top: 20px;
background: #B9C0EF 0% 0% no-repeat padding-box;
border-radius: 17px;
opacity: 1;
}

.roundedBoxNarrowPeach {
  width: 295px;
  height: 69px;
  margin-top: 20px;
  background: #F75F64 0% 0% no-repeat padding-box;
  border-radius: 17px;
  opacity: 1;
  }

  .roundedBoxNarrowMediumBlue {
    width: 295px;
    height: 69px;
    margin-top: 20px;
    background: #22469F 0% 0% no-repeat padding-box;
    border-radius: 17px;
    opacity: 1;
    }

.roundedBoxNarrowDarkBlue {
      width: 295px;
      height: 69px;
      margin-top: 20px;
      background: #051D58 0% 0% no-repeat padding-box;
      border-radius: 17px;
      opacity: 1;
  }

.smallRoundedBoxNarrow {
  width: 78px;
  height: 69px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  padding: 0px 0px 0px 0px;
}

.aboutSurgicalAbortionPage {
  background-color: #B9C0EF !important;
}

.underconstrucionPage {
  background-color: #051D58 !important;
}

.textBox {
  width: 270px;
  height: 150px;
  text-align: center !important;
}

.bottomLinkLeft {
  /* position: absolute; */
  top: 50%;
  left: 50px;
  transform: translate(-50%, -50%);
}

.whiteSpaceNo {
  white-space: nowrap;
}

/* vertical align helps place the images within the flexbox */
.verticalAlign {
  position: absolute;
  top: 69%;
  left: 80%;
  transform: translate(-50%, -50%);
}

.verticalAlignHigher {
  position: absolute;
  top: 74%;
  left: 80%;
  transform: translate(-50%, -50%);
}

.verticalAlign2 {
  position: absolute;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
}

.verticalAlign3 {
  position: absolute;
  top: 55%;
  left: 80%;
  transform: translate(-50%, -50%);
}

.verticalAlign4 {
  position: absolute;
  top: 53%;
  left: 73%;
  transform: translate(-50%, -50%);
}

.verticalAlign5 {
  position: absolute;
  top: 60%;
  left: 80%;
  transform: translate(-50%, -50%);
}

.verticalAlign6 {
  position: absolute;
  top: 63%;
  left: 76%;
  transform: translate(-50%, -50%);
}

/* This is the bs.Prefix for Alert.Heading in TermsOfUseAlert*/
/* .termsofuse-alert {
  background-color: white !important;
  top: 180px;
  bottom: 20px;
  left: 1%;
  right: 15px;
  margin-bottom: 20px;
  max-width: 570px;
  min-height: 800px;
  border-radius: 45px;
  border-bottom-left-radius: 45px !important;
  border-bottom-right-radius: 45px !important;
  opacity: 1;
  z-index: 9999999;
  position: relative;
}

@media screen and (max-width: 767px) {
  .termsofuse-alert {
    top: 100px;
    left: 1%;
    right: 10px;
    max-width: 300px;
    min-height: auto;
  }
}

@media screen and (max-width: 300px) {
  .termsofuse-alert {
    top: 100px;
    left: 1%;
    right: 10px;
    max-width: 200px;
    min-height: auto;
  }
} */
.termsofuse-bottomRoundDiv {
  border-top-left-radius: 3rem;
  border-top-right-radius: 3rem;
  margin-top: -10px;
  padding-bottom: 5px;
  border-bottom-left-radius: 3rem;
  border-bottom-right-radius: 3rem;
  max-width: 380px;
  margin-right: 16px;
  
}

@media screen and (max-width: 300px) {
  .termsofuse-bottomRoundDiv {
    margin-top: -20px;
    scroll-padding-left: -10px;
    padding-right: 5px;
  }
}

@media screen and (min-width: 300px) {
  .termsofuse-bottomRoundDiv {
    margin-top: -20px;
    scroll-padding-left: -10px;
    margin-right: 50px;
    
  }
}

.termsofuse-div-padding {
  padding-left: 50%;
  margin-left: 15%;
}

@media screen and (max-width: 361px) {
  .termsofuse-div-padding {
    padding-left: 1px !important;
    margin-left: -10px !important;
  }
}

@media screen and (min-width: 400px) {
  .termsofuse-div-padding {
    padding-left: 1% !important;
    margin-left: 4% !important;
  }
}

@media screen and (max-width: 8210px) {
  .termsofuse-div-padding {
    padding-left: 1% !important;
    margin-left: 14% !important;
  }
}

@media screen and (min-width: 1000px) {
  .termsofuse-div-padding {
    padding-left: 10% !important;
    margin-left: 10% !important;
  }
}

@media screen and (min-width: 300px) {
  .termsofuse-div-padding {
    padding-left: 5%;
    
  }
}


/* This is the class for headerImage in TermsOfUseAlert*/
.termsofuse-icon {
  position: relative;
  top: -110px;
  left: 35%;
  width: 45px;
  height: 65px;
}

@media screen and (max-width: 767px) {
  .termsofuse-icon {
    top: -80px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
/* This is the bs.Prefix for Alert.Heading in TermsOfUseAlert*/
.termsofuse-alert-heading { 
  color: #333;
  text-align: center;
  font-family: "GT Walsheim Pro";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; /* 125% */
  position: absolute;
  top: -1vh;
  left: 50%;
  width: 200px;
  height: 89px;
  transform: translate(-50%, -50%);
}

/* This is the class for paragraph in TermsOfUseAlert*/
.termsofuse-alert-text {
  /* border: 0; */
  margin-left: 16px; /* Adjust this value for left padding */
  margin-right: 16px;
  margin-bottom: -160px;
  color: #071F56;
  top: 130px;
  min-width: 250px;
  max-width: 300px;
  left: 45%;
  text-align: center;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 1;
}

@media screen and (max-width: 300px) {
  .termsofuse-alert-text {
    top: 320px;
    min-width: 200px;
    max-width: 200px;
    margin-left: 11px;  
  }
}

/* @media screen and ( max-width:350px) {
  .termsofuse-alert-text {
    top: 330px;
    min-width: 50px;
    margin-left: 15px;  
  }
} */

@media screen and (min-width: 300px) {
  .termsofuse-alert-text {
    top: 290px;
    margin-left: 10px; 
    min-width: 200px;
    max-height: 300px;
    font-size: small;
  }
}

@media screen and (min-width: 412px) {
  .termsofuse-alert-text {
    top: 300px;
    min-width: 200px;
    max-width: 200px;
    margin-left: 11px;  
    font-size: 14px;
  }
}

@media screen and (min-width: 540px) {
  .termsofuse-alert-text {
    top: 300px;
    left: 44%;
    min-width: 280px;
    transform: translate(-50%, -50%);
    font-size: medium;
  }
}

@media screen and (min-width: 768px) {
  .termsofuse-alert-text {
    /* top: 180px; */
    left: 44%;
    max-width: 250px;
    transform: translate(-50%, -50%);
    font-size: medium;
  }
}

@media screen and (min-width: 992px) {
  .termsofuse-alert-text {
    /* top: 180px; */
    left: 44%;
    min-width: 300px;
    max-width: 350px;
    transform: translate(-50%, -50%);
  }
}

@media screen and (min-width: 1200px) {
  .termsofuse-alert-text {
    /* top: 180px; */
    left: 46%;
    max-width: 250px;
    transform: translate(-50%, -50%);
  }
}

/* Hide the original checkbox */
.custom-checkbox input[type="checkbox"] {
  display: none;
}

/* Create a custom box */
.custom-checkbox label::before {
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 1px;
  margin-top: 6px;
  padding-top:-8px;
  border: 2px solid #4350E6;
  background-color: #fff;
  vertical-align: middle;
  color: #F85258;
}

/* @media screen and (min-width: 300px) {
  .custom-checkbox label::before {
    width: 16px;
    height: 16px;

  }
} */


/* Style the custom box when checked */
.custom-checkbox input[type="checkbox"]:checked + label::before {
  border-color: #4350E6;
  background-color: #4350E6;
  
}

/* This is the custom styling for the white arrow in the checkbox*/
.custom-checkbox label::after {
  top: 0.65rem;
  left: -1.4rem;
  
}


.custom-checkbox label {
  color: #4350E6;
  margin-top: 25px;
  margin-bottom: -8px;
}

.termsofuse-alert-checkbox-text {
  color: #4350E6;
  margin-top: -6px;
  left: 12%;  
  padding-top: -8px;
  padding-bottom: 10px;
}

.termsofuse-check-box-text-purple {
  color: #4350E6 !important;
}

@media screen and (max-width: 374px) {
  .termsofuse-alert-checkbox-text {
    font-size: small !important;
    max-width: 190px;
  }
}

/* This is the Button with buttonText in TermsOfUseAlert*/
.termsofuse-button {
  position: relative;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #4350E6;
  color: white !important;
  border-radius: 45px;
  width: 230px;
  height: 34px;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 80px;
}

@media screen and (min-width: 250px) {
  .termsofuse-button {
    margin-top: 80px;
    width: 180px;
    height: 25px;
    font-size: 11px;
    font-weight: bold;
    margin-bottom: -10px;
  }
}

@media screen and (min-width: 325px) {
  .termsofuse-button {
    margin-top: 60px;
    width: 210px;
    height: 34px;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: -10px;
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media  screen and (min-width: 576px) {
  .termsofuse-button {
    margin-top: 80px;
    width: 210px;
    height: 34px;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 2px;
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media screen and (min-width: 768px) {
  .termsofuse-button {
    width: 220px;
    height: 36px;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 80px;
  }
}

@media screen and (min-width: 992px) {
  .termsofuse-button {
    margin-top: -30px;
    width: 240px;
    height: 34px;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 80px;
  }
}

@media screen and (min-width: 1200px) {
  .termsofuse-button {
    width: 230px;
    height: 40px;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 1px;
    margin-top: 80px;
  }
}

/* Set lower opacity for the disabled button */
.termsofuse-button-opacity[disabled] {
  opacity: 0.22; /* Adjust this value to your desired opacity */
}

/* Set full opacity for the enabled button */
.termsofuse-button-opacity:not([disabled]) {
  opacity: 1;
}

/* This is the class of the div for the Form.Check, Form.Check.Label
and checkBox Contentful text for TermsOfUseAlert*/
.form-group-container {
  padding-bottom: 20px;
  margin-top: 17rem;
  margin-left: 10px;
  max-width: 250px;
  left: 6%;
}

@media screen and (min-width: 250px){
  .form-group-container{
  top: 6.5rem;
  left: 7px;
  max-width: 210px;
}}

@media screen and (min-width: 300px){
  .form-group-container{
  top: 2rem;
  left: 5%;
  max-width: 220px;
  padding-bottom: 1px;
  margin-bottom: -20px;
}}

@media screen and (min-width: 540px){
  .form-group-container{
  top: 1rem;
  left: 2%;
  padding: 1rem;
  width: 272px;
}}

@media screen and (min-width: 768px){
  .form-group-container{
  top: 1rem;
  left: 10%;
  padding: 1rem;
  width: 300px;
}}

@media screen and (min-width: 992px){
  .form-group-container{
  top: -1rem;
  left: 10%;
  padding: 1rem;
  width: 272px;
}}

@media screen and (min-width: 1200px){
  .form-group-container{
  top: 3rem;
  left: 10%;
  padding: 1rem;
  width: 272px;
  margin-top: 13rem;
}}


.WHOLink {
  text-decoration: underline;
  font-size: 14px;
  font-family: 'GTWalsheimPro-Bold', sans-serif;
  text-align: left !important;
  align-items: flex-start !important;
  margin-right: 3rem !important;
  padding-left: 0 !important;
}

.WHOLink-div {
  padding-top: 30px;
  padding-bottom: -10px;
  margin-bottom: -20px;
  margin-left: -150px;
}

/* @media screen and (min-width: 250px){
  .WHOLink-div{
    margin-left: 140px;
}} */

@media screen and (min-width: 250px){
  .WHOLink-div{
    margin-left: -5px;
    margin-bottom: 20px;
    padding-top: 10px;
    margin-top: 10px;
    margin-right: -50px !important;
}}

@media screen and (min-width: 250px){
  .WHOLink{
    font-size: 12px;
    
}}

@media screen and (min-width: 350px){
  .WHOLink-div{
    margin-left: -33px;
    margin-bottom: 40px;
    padding-top: 10px;
    margin-top: 10px;
    margin-right: -50px !important;
}}


@media screen and (min-width: 375px){
  .WHOLink-div{
    margin-left: -5px;
    margin-bottom: 40px;
    padding-top: 10px;
    margin-top: 10px;
}}

@media screen and (min-width: 400px){
  .WHOLink{
    font-size: 13px;
    
}}

/* @media screen and (min-width: 768px){
  .WHOLink{
  top: 1rem;
  left: 10%;
  padding: 1rem;
  width: 300px;
}}

@media screen and (min-width: 992px){
  .WHOLink{
  top: -1rem;
  left: 10%;
  padding: 1rem;
  width: 272px;
}}

@media screen and (min-width: 1200px){
  .WHOLink{
  top: 3rem;
  left: 10%;
  padding: 1rem;
  width: 272px;
  margin-top: 13rem;
}} */

.carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

/* overall carousel */
.carousel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  width: 100%;
  height: 100%;
}

/* placement of dots/indicators */
.carousel .control-dots {
  position: fixed;
  margin: 10px 0;
  padding: 0;
  text-align: center;
  width: 100%;
  z-index: 1;
  bottom: 0;
  top: 10;
}

/* height or carousel slides */
.carousel .slide {
  min-width: 100%;
  margin: 0;
  position: relative;
  text-align: center;
  height: 1100px;
}

/* image in each carousel */
.carousel .slide img {
  width: 65%;
  vertical-align: top;
  border: 0;
}

/* carousel dots size and color */
.carousel .control-dots .dot.selected {
  background-color: #f74a58; 
  width: 15px; 
  height: 15px; 
  border-radius: 50%;
}

.who-Link {
  font-weight: bold;
}

/* this controls the images on each carousel page when screen size below 273 */
@media screen and (max-width: 273px) {
  .smallScreenPadding {
    padding-top: 50px;
  }
}



/* 1) For the .appWrapper used by the Layout: 
      keeps everything 400px wide and centered. */
      .appWrapper {
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        min-height: 100vh; /* ✅ Keeps full viewport height */
        height: auto; /* ✅ Allows content overflow */
        overflow: visible; /* ✅ Ensures it doesn’t clip scrolling */
      }

      @media (min-width: 768px) {
        .appWrapper {
          max-width: 400px;
        }
      }

      #root, .App {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 100vh;
        height: auto; /* ✅ Allows content to grow */
        width: 100%;
        padding-top: env(safe-area-inset-top);
        background-color: #fff;
      }
      
      /* 2) The top nav bar container is sticky at top. */
      .topNavBarContainer {
        position: sticky;
        top: 0;
        z-index: 999;
        /* The actual background color might be in topNavProps 
           or in the Nav component. This ensures it’s above content. */
      }
      
      /* 3) The main content area is between top and bottom nav. */
      .appContent {
        flex: 1;
      
        /* If your nav is ~60px tall, 
           add top/bottom padding so content doesn’t hide behind them: */
        padding-top: 60px;
        padding-bottom: 60px;
      }
      
      /* 4) The bottom nav container is sticky at bottom. */
      .bottomNavBarContainer {
        position: sticky;
        bottom: 0;
        z-index: 999;
      }