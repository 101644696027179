.topNavbar {
  position: fixed; /* Make it stick to the top */
  top: 0;
  left: 0;
  width: 100%;
  height: 60px; /* Adjust as needed */
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--background-color, #fff);
  z-index: 1001; /* Ensure it stays above other content */
  /* border-bottom: 1px solid #E0E0E0; */
  padding-top: constant(safe-area-inset-top); /* Older Safari syntax */
  padding-top: env(safe-area-inset-top);
}

/* Centered on larger screens like bottom navbar */
@media (min-width: 768px) {
  .topNavbar {
    max-width: 400px;
    left: 50%;
    transform: translateX(-50%);
  }
}

/* Ensure full width on smaller screens */
@media (max-width: 767px) {
  .topNavbar {
    max-width: 100%;
  }
}

.backButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
}

.navbarTitle {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  flex-grow: 1; /* Ensures title stays centered */
}

.breadcrumbs {
  font-size: 12px;
  color: var(--breadcrumb-color, #333);
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  right: 10px;
}
  
.backIcon {
  margin-left: 10px; /* Adjust for proper spacing */
  /* margin-top: 3px; */
}