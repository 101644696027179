.feedbackCardContainer {
    width: 357px;
    height: 124px;
    flex-shrink: 0;
    border-radius: 9px;
    border: 1px solid var(--Border4, #CBD6FD);
    background: #E0E2FF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
  }

  @media (max-width: 374px) {
    /* For small mobile screens */
   .feedbackCardContainer {
     min-width: 280px;
     max-width: 280px;
   }
  }
  
  .cardTitle {
    width: 266px;
    height: 27px;
    flex-shrink: 0;
    color: #484848;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 133.333% */
  }
  
  .iconsContainer {
    display: flex;
    justify-content: space-around;
    width: 100%;
    flex-direction: row;
    align-items: center;
  }
  
  .iconCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
    background: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  
  .iconCard:disabled {
    cursor: default;
    opacity: 0.6; /* To indicate disabled state */
  }
  
  .iconImage {
    width: 40px;
    height: 40px;
  }
  
  .iconLabel {
    color: #484848;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px; /* 125% */
    margin-top: 5px; /* Space between image and label */
    transition: color 0.3s ease-in-out;
  }
  
  .childrenContainer {
    margin-top: 16px; /* Adds space above the nested components */
}