.bannerCardContainer {
  background: linear-gradient(99deg, #4450E5 22.9%, #616CEE 77.43%);
  height: 88px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  /* cursor: pointer; */
  overflow: hidden;
  border-radius: 12px;
  transition: background 0.3s ease;
  margin-bottom: 20px;
}

@media (max-width: 374px) {
  /* For small mobile screens */
 .bannerCardContainer {
   min-width: 280px;
   max-width: 280px;
 }
}

 .bannerCardContainer:hover {
  background: linear-gradient(99deg, #4450E5 22.9%, #616CEE 77.43%)
}

 .bannerImage {
  width: 81px;
  height: 80px;
  flex-shrink: 0;
}

 .bannerTextContainer {
  text-align: left;
  padding-left: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

 .bannerTitle {
  font-size: 16px;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 1.4;
  padding-top: 0px;
  padding-bottom: 4px;
}

 .bannerText {
  padding-top: 0px;
  padding-bottom: 0px;
  color: #FFF;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px; /* 146.154% */
  flex-shrink: 0;
}

 .bannerText a {
  color: #fff;
  text-decoration: underline;
}

 .bannerText a:hover {
  color: #FFFFFF; /* Makes the link white on hover */
}
