/* CSS */

.libraryAbortionMenuContainer {
  width: 100%;
  max-width: 400px; /* ✅ Keeps everything aligned */
  margin: 0 auto;
  padding: 5px;
}

@media (min-width: 360px), (max-width: 360px) {
.libraryAbortionMenuContainer {
  max-width: 360px;
  padding: 0;
  margin: 0 0;

}
}

@media (max-width: 321px) {
  .libraryAbortionMenuContainer {
    max-width: 320px;
    padding: 0;
    margin: 0 0;
  
  }
  }


  @media (min-width: 375px), (max-width: 391px) {
    .libraryAbortionMenuContainer {
      max-width: 375px;
      padding: 0;
      margin: 0 0;
    
    }
    }

    @media (min-width: 392px), (max-width: 415px) {
      .libraryAbortionMenuContainer {
        max-width: 415px;
        padding: 0;
        margin: 0 0;
      
      }
      }

      @media (min-width: 768px) {
        .libraryAbortionMenuContainer {
          max-width: 400px;
          padding: 0;
          margin: 0 0;
        
        }
        }



.contentWrapper {
  width: 100%;
  max-width: 600px; /* ✅ Aligns with the header */
  margin: 0 auto;
  padding: 0 16px;
  box-sizing: border-box;
}

.carouselContainer {
  display: flex;
  gap: 10px;
  overflow-x: auto;
  padding: 10px 0;
  /* scroll-snap-type: x mandatory; */
  max-width: 400px; /* Restrict width to maintain 2.5 cards visibility */
  margin: 0 0;
}

@media (min-width: 768px) {
  .carouselContainer {
    max-width: 400px; /* Keep the carousel constrained to 400px width for desktop too */
  }
}

.carouselContainer::-webkit-scrollbar {
  display: none;
}


  .smallCardsGrid {
    width: auto;
  }

  .carouselCard {
    flex: 0 0 calc((400px / 2.5) - 10px); /* Adjust width to show 2.5 cards */
    scroll-snap-align: start; /* This aligns each card to the start */
  }

  .homePageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 100%; /* ✅ Prevents content from shifting */
    margin: 0 0;
    /* padding: 16px; */
    box-sizing: border-box;
  }
  
.exploraContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  max-width: 390px;
}

.sectionTitleExplora {
  color: #333;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
}

.scrollInfo {
  color: #666;
  font-family: "GT Walsheim Pro";
  font-size: 14px;
  font-weight: 400;
}

.headerContainer {
  margin-bottom: 20px;
  width: 100%;
  max-width: 600px; /* Allow more space */
  display: flex;
  justify-content: center;
  padding-top: 50px;
  box-sizing: border-box;
  left: 0;
}

.bannerCardWrapper {
  display: flex;
  justify-content: center;
  width: 100%; /* Let the card fill the entire width */
  margin-bottom: 20px; /* Optional: add space below the card */
}

.bottomSpace {
  margin-bottom: 12vh;
}

.lowerSubtitle {
  font-size: 12px;  
  color: #333;
  padding-top: 5px;
  
}