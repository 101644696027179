.emotionalSupportContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  min-height: 100vh;
  margin-left: 5vh;
  margin-right: 5vh;
  text-decoration: none !important;
  color: inherit !important;  
}



.homeContainerSingleResources {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-top: 20px; */
  width: 100%;
  height: 100vh;
}


.cardsContainer {
  display: flex;
  flex-direction: column;
  align-items: center; 
  margin-top: 10px;
  margin-left: 2.5vh;
  margin-right: 2.5vh;
}

.emotional-support-title {
  font-size: 21px;
  color: #051D58;
  font-family: 'GTWalsheimPro-Bold', sans-serif;
  margin-bottom: 8px;
  padding-top: 20px;
  text-align: center;
}

.emotional-support-page-description {
  font-family: 'GTWalsheimPro-Regular', sans-serif;
  font-size: 14px;
  color: #524F4F;
  background-color: #F0E9F9;
  border-radius: 12px;
  padding: 20px 10px 20px 10px;
  margin-top: 20px;
}

@media (max-width: 400px) {
  .cardsContainer {
    gap: 16px; /* Increase spacing on smaller screens */
  }
}

.help-line-container {
  font-family: 'GTWalsheimPro-Regular', sans-serif;
  font-size: 14px;
  color: #524F4F;
  background-color: #E5F1FA;
  border-radius: 12px;
  padding: 20px 10px 20px 10px;
  margin-top: 15px;
  margin-bottom: 10px;
}

.carouselContainer {
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 12px;
  margin-top: 40px;  /* Reduced from 60px */
  margin-bottom: 0px;
  background-color: #fff;
  justify-content: space-between;
  max-width: 400px;
  height: 40px;  /* Increased from 30px */
  min-height: 40px; /* Ensure it does not shrink */
  padding-top: 5px; /* Add padding to push content down */
  overflow: visible; /* Ensure nothing is hidden */
}

@media (min-width: 768px) {
  .carouselContainer {
      max-width: 400px;
  }
}

.dotsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
  margin-bottom: 0px;
}

.customDot {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 1px;
  padding: 10px 10px;
  border: 3px solid #4350E6;
  transition: background-color 0.3s;
}

.slick-list {
  overflow: hidden;  /* Ensures smooth transition */
  padding-bottom: 3px;  /* Pushes the content slightly downward */
}

.slick-dots {
  margin-top: 5px; /* Adjust if necessary */
  padding-bottom: 5px; /* Ensures space below */
}

slick-dots {
  display: flex; /* Ensure the dots are displayed in a row */
  justify-content: center; /* Center the dots horizontally */
  padding: 5px;
}

.slick-dots li {
  margin: 5 5; /* Adjust the margin value as needed */
  list-style-type: none; /* Remove default list styling */
}

.active {
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.2);
    margin-bottom: 0px;
}

a.linkButton {
  display: inline-flex !important; /* Override any inline styles */
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
 /*  height: 36px !important; */ /* Ensures proper visibility */
  line-height: 36px; /* Keeps text centered */
  border-radius: 20px;
  padding: 10px !important;
  text-decoration: none !important;
  margin-right: 5px;
  white-space: nowrap;
}

.customDot {
display: flex !important; /* Ensures height works */
align-items: center;
justify-content: center;
height: auto !important;
/* min-height: 30px; */ /* Adjust if needed */
}

.slideItem {
display: block !important;
width: auto;
/* height: auto; */
}

.custom-dot-container {
display: flex !important;
align-items: center;
justify-content: center;
gap: 8px; /* Adjust spacing */
}

.slick-slide a.linkButton {
display: flex !important;
}

a.linkButton {
  height: 24px !important; /* Ensures proper visibility */
  line-height: 36px; /* Keeps text centered */
}

.link-button:last-child {
  margin-right: 0; 
}

.link-button.active {
  background-color: #051D58;
  color: #fff; 
  height: 38px; 
}


/* Media query for smaller screens */
@media (max-width: 768px) {
  .link-button {
    padding: 10px 10px; 
    font-size: 10px; 
  }
}

.variable-width {
  width: 200px;
}

.slideItem {
  margin-right: 10px; 
}

.end-of-page {
  margin-top: 70px;
}

.libraryAbortionMenuContainer {
  width: 100%;
  max-width: 400px; /* ✅ Keeps everything aligned */
  margin: 0 auto;
  padding: 5px;
}

@media (min-width: 360px), (max-width: 360px) {
.libraryAbortionMenuContainer {
  max-width: 360px;
  padding: 0;
  margin: 0 0;

}
}

@media (max-width: 321px) {
  .libraryAbortionMenuContainer {
    max-width: 320px;
    padding: 0;
    margin: 0 0;
  
  }
  }


  @media (min-width: 375px), (max-width: 391px) {
    .libraryAbortionMenuContainer {
      max-width: 375px;
      padding: 0;
      margin: 0 0;
    
    }
    }

    @media (min-width: 392px), (max-width: 415px) {
      .libraryAbortionMenuContainer {
        max-width: 415px;
        padding: 0;
        margin: 0 0;
      
      }
      }

      @media (min-width: 768px) {
        .libraryAbortionMenuContainer {
          max-width: 350px;
          padding: 0;
          margin: 0 0;
        
        }
        }


.homePageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100%; /* ✅ Prevents content from shifting */
  margin: 0 0;
  /* padding: 16px; */
  box-sizing: border-box;
}

@media (min-width: 360px), (max-width: 360px) {
  .homePageContainer {
    max-width: 360px;
    padding: 0;
    margin: 0 0;
  
  }
  }
  
  @media (max-width: 321px) {
    .homePageContainer {
      max-width: 320px;
      padding: 0;
      margin: 0 0;
    
    }
    }
  
  
    @media (min-width: 375px), (max-width: 391px) {
      .homePageContainer {
        max-width: 375px;
        padding: 0;
        margin: 0 0;
      
      }
      }
  
      @media (min-width: 392px), (max-width: 415px) {
        .homePageContainer {
          max-width: 415px;
          padding: 0;
          margin: 0 0;
        
        }
        }
  
        @media (min-width: 768px) {
          .homePageContainer {
            max-width: 350px;
            padding: 0;
            margin: 0 0;
          
          }
          }

.headerContainer {
  margin-bottom: 20px;
  width: 100vw; /* Ensure full viewport width */
  min-width: 100%; 
  display: flex;
  justify-content: center;
  padding-top: 50px;
  box-sizing: border-box;
}

@media (min-width: 768px) {
.headerContainer {
  max-width: 400px;
}
}


.moreMargin {
  margin-bottom: 20px;
  margin-top: 20px;
}

.bottomSpace {
  margin-bottom: 8vh;
}

.extraSpace {
  margin-top: 20px;
}

/* Smooth transition and prevent border artifacts */
.slick-track {
transition: transform 0.6s ease-in-out !important;
}

.slick-slide {
transition: all 0.4s ease-in-out !important;
}

/* Ensures smoother snapping */
.slick-slider {
overflow: hidden;
will-change: transform;
}

.extraSpace2 {
margin-top: 55px;
}

.bottomSpace {
margin-bottom: 12vh;
}