.pageContainer {
  width: 100%;
  min-height: 100vh; /* Ensures the page fills the screen but allows it to grow */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align content to the top */
  margin: 0;
  position: relative;
  padding-top: 10vh; /* Small padding to prevent overlap */
}

.icon {
  width: 62px;
  height: 56px;
  margin-bottom: 10px;
}

.title {
  width: 218px;
  color: #303030;
  text-align: center;
  font-family: "GT Walsheim Pro";
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  margin-bottom: 10px;
}

.title2 {
  width: 340px;
  color: #303030;
  text-align: center;
  font-family: "GT Walsheim Pro";
  font-size: 30px;
  font-weight: 400;
  line-height: 35px;
  margin-bottom: 20px;
}

.choicesBox {
  width: 350px;
  background: #f8f8f8;
  border: 1px solid #dbdbdb;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
}

.extraText {
  width: 100%;
  color: #505050;
  text-align: center;
  font-family: "Inter";
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 10px;
}

.choicesList {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.choiceCard {
  width: 318px;
  height: 66px;
  background: #f8f8f8;
  border: 1px solid #e12541;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.choiceText {
  color: #5f5f5f;
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  text-align: center;
  width: 254.451px;
  max-height: 63px;
  text-align: left;
}

.illustration {
  width: 138px;
  height: 136px;
  margin-bottom: 20px;
}

.extraText2 {
  width: 268px;
  color: #303030;
  text-align: center;
  font-family: "Inter";
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
  margin-bottom: 20px;
}

.button1 {
  width: 350px;
  min-height: 40px;
  background: #4350e6;
  color: #fff;
  border-radius: 20px;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  border: none;
  margin-bottom: 10px;
  cursor: pointer;
}

.button2 {
  width: 350px;
  min-height: 40px;
  background: transparent;
  color: #4350e6;
  border: 2px solid #4350e6;
  border-radius: 20px;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  margin-bottom: 60px;
}

.bottomSpace {
  margin-bottom: 12vh;
}

.extraSpaceTop {
  margin-top: 12vh;
}